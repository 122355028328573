export class BuisnessUnit {
  public branchPlant?: string;
  public companyCode?: string;
  public branchPlantDescription?: string;
  constructor(
    branchPlant?: string,
    companyCode?: string,
    branchPlantDescription?: string
  ) {
    this.branchPlant = branchPlant;
    this.companyCode = companyCode;
    this.branchPlantDescription = branchPlantDescription;
  }
}
