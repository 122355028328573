import { default as data } from '../../package.json';

export const environment = {
  appVersion: data.version,
  production: false,
  apiUrl: 'https://usermanagementapi-dev.cascadedesigns.com/api',
  authDomain: 'rex-login-dev01.cascadedesigns.com',
  authAudience: 'https://usermanagementapi-dev.cascadedesigns.com',
  authClientId: 'XNwi6Og17udLz5Vnm6jUIJLYKvcxth7B',
  authScope: 'openid profile',
  appInsights: {
    instrumentationKey:
      'InstrumentationKey=51ad964a-2f0f-432c-8115-6870c1781472;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=3d352976-d061-4b13-8de9-5890c441171c',
  },
};
