<!-- Add DataType Dialog -->
<ejs-dialog
  id="cdi-add-data-type-ejDialog"
  #ejDialogAddDataType
  [showCloseIcon]="true"
  header="Add Data Type"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddDataTypeDialogCloselButtonClick()"
>
  <form [formGroup]="addDataTypeForm">
    <div>
      <label for="typeData">Data Type: </label>
      <input
        id="typeData"
        class="e-input"
        type="text"
        placeholder="Enter Data Type"
        formControlName="typeData"
      />
      <div
        *ngIf="typeData?.invalid && (typeData?.dirty || typeData?.touched)"
        class="form-error"
      >
        <div *ngIf="typeData?.errors?.['required']">
          * Data Type is required.
        </div>
        <div *ngIf="typeData?.errors?.['minlength']">
          * Data Type must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />

    <div style="order: 1; flex: 10; display: flex; align-items: center">
      <label for="isActiveValue" style="margin-right: 10px">Is Active:</label>

      <div style="flex: 1">
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="isActive"
          (change)="onUserStatusSwitchIsActiveToggle($event)"
        ></ejs-switch>
      </div>
    </div>

    <br /><br />
    <button
      id="cdi-add-data-type-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      [disabled]="!addDataTypeForm.valid"
      (click)="onSaveAddDataTypeButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-add-data-type-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddDataTypeDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form> </ejs-dialog
><!-- Edit DataType Dialog -->
<ejs-dialog
  id="cdi-edit-data-type-ejDialog"
  #ejDialogEditDataType
  [showCloseIcon]="true"
  header="Edit Data Type"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddDataTypeDialogCloselButtonClick()"
>
  <form [formGroup]="addDataTypeForm">
    <div>
      <label for="typeData">Data Type: </label>
      <input
        id="typeData"
        class="e-input"
        type="text"
        placeholder="Enter Data Type"
        formControlName="typeData"
        [value]="editDataTypeData?.typeData"
      />
      <div
        *ngIf="typeData?.invalid && (typeData?.dirty || typeData?.touched)"
        class="form-error"
      >
        <div *ngIf="typeData?.errors?.['required']">
          * Data Type is required.
        </div>
        <div *ngIf="typeData?.errors?.['minlength']">
          * Data Type must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />

    <div style="order: 1; flex: 10; display: flex; align-items: center">
      <label for="isActiveValue" style="margin-right: 10px">Is Active:</label>

      <div style="flex: 1">
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="editDataTypeData?.isActive"
          (change)="onUserStatusSwitchIsActiveToggle($event)"
        ></ejs-switch>
      </div>
    </div>

    <br /><br />
    <button
      id="cdi-edit-data-type-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveEditDataTypeButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-edit-data-type-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddDataTypeDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>

<!-- Add Format Dialog -->
<ejs-dialog
  id="cdi-add-format-ejDialog"
  #ejDialogAddFormat
  [showCloseIcon]="true"
  header="Add Format"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddFormatDialogCloselButtonClick()"
>
  <form [formGroup]="addFormatForm">
    <div>
      <label for="format">Format: </label>
      <input
        id="format"
        class="e-input"
        type="text"
        placeholder="Enter Format"
        formControlName="format"
      />
      <div
        *ngIf="format?.invalid && (format?.dirty || format?.touched)"
        class="form-error"
      >
        <div *ngIf="format?.errors?.['required']">* Format is required.</div>
        <div *ngIf="format?.errors?.['minlength']">
          * Format must be at least 2 characters long.
        </div>
      </div>
    </div>
    <br /><br />

    <div style="order: 1; flex: 10; display: flex; align-items: center">
      <label for="isActiveValue" style="margin-right: 10px">Is Active:</label>

      <div style="flex: 1">
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="isActive"
          (change)="onUserStatusSwitchIsActiveToggle($event)"
        ></ejs-switch>
      </div>
    </div>

    <br /><br />
    <button
      id="cdi-add-format-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      [disabled]="!addFormatForm.valid"
      (click)="onSaveAddFormatButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-add-format-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddFormatDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form> </ejs-dialog
><!-- Edit Format Dialog -->
<ejs-dialog
  id="cdi-edit-format-ejDialog"
  #ejDialogEditFormat
  [showCloseIcon]="true"
  header="Edit Format"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddFormatDialogCloselButtonClick()"
>
  <form [formGroup]="addFormatForm">
    <div>
      <label for="format">Format: </label>
      <input
        id="format"
        class="e-input"
        type="text"
        placeholder="Enter Format"
        formControlName="format"
        [value]="editFormatData?.format"
      />
      <div
        *ngIf="format?.invalid && (format?.dirty || format?.touched)"
        class="form-error"
      >
        <div *ngIf="format?.errors?.['required']">* Format is required.</div>
        <div *ngIf="format?.errors?.['minlength']">
          * Format must be at least 2 characters long.
        </div>
      </div>
    </div>
    <br /><br />

    <div style="order: 1; flex: 10; display: flex; align-items: center">
      <label for="isActiveValue" style="margin-right: 10px">Is Active:</label>

      <div style="flex: 1">
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="editFormatData?.isActive"
          (change)="onUserStatusSwitchIsActiveToggle($event)"
        ></ejs-switch>
      </div>
    </div>

    <br /><br />
    <button
      id="cdi-edit-format-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveEditFormatButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-edit-format-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddFormatDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>

<!-- Add TextAlign Dialog -->
<ejs-dialog
  id="cdi-add-text-align-ejDialog"
  #ejDialogAddTextAlign
  [showCloseIcon]="true"
  header="Add Text Align"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddTextAlignDialogCloselButtonClick()"
>
  <form [formGroup]="addTextAlignForm">
    <div>
      <label for="textAlign">Text Align: </label>
      <input
        id="textAlign"
        class="e-input"
        type="text"
        placeholder="Enter Text Align"
        formControlName="textAlign"
      />
      <div
        *ngIf="textAlign?.invalid && (textAlign?.dirty || textAlign?.touched)"
        class="form-error"
      >
        <div *ngIf="textAlign?.errors?.['required']">
          * Text Align is required.
        </div>
        <div *ngIf="textAlign?.errors?.['minlength']">
          * Text Align must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />

    <div style="order: 1; flex: 10; display: flex; align-items: center">
      <label for="isActiveValue" style="margin-right: 10px">Is Active:</label>

      <div style="flex: 1">
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="isActive"
          (change)="onUserStatusSwitchIsActiveToggle($event)"
        ></ejs-switch>
      </div>
    </div>

    <br /><br />
    <button
      id="cdi-add-text-align-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      [disabled]="!addTextAlignForm.valid"
      (click)="onSaveAddTextAlignButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-add-text-align-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddTextAlignDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form> </ejs-dialog
><!-- Edit TextAlign Dialog -->
<ejs-dialog
  id="cdi-edit-text-align-ejDialog"
  #ejDialogEditTextAlign
  [showCloseIcon]="true"
  header="Edit Text Align"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddTextAlignDialogCloselButtonClick()"
>
  <form [formGroup]="addTextAlignForm">
    <div>
      <label for="textAlign">Text Align: </label>
      <input
        id="textAlign"
        class="e-input"
        type="text"
        placeholder="Enter Text Align"
        formControlName="textAlign"
        [value]="editTextAlignData?.textAlign"
      />
      <div
        *ngIf="textAlign?.invalid && (textAlign?.dirty || textAlign?.touched)"
        class="form-error"
      >
        <div *ngIf="textAlign?.errors?.['required']">
          * Text Align is required.
        </div>
        <div *ngIf="textAlign?.errors?.['minlength']">
          * Text Align must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />

    <div style="order: 1; flex: 10; display: flex; align-items: center">
      <label for="isActiveValue" style="margin-right: 10px">Is Active:</label>

      <div style="flex: 1">
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="editTextAlignData?.isActive"
          (change)="onUserStatusSwitchIsActiveToggle($event)"
        ></ejs-switch>
      </div>
    </div>

    <br /><br />
    <button
      id="cdi-edit-text-align-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveEditTextAlignButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-edit-text-align-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddTextAlignDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>

<div class="e-card" *ngIf="this.showDataTypeGrid">
  <div
    class="e-card-title"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <div>Data Type</div>
    <div style="display: flex; gap: 10px">
      <button
        id="cdi-add-data-type-button"
        ejs-button
        style="background-color: #00308f"
        (click)="onClickAddDataTypeButton()"
      >
        Add
      </button>
    </div>
  </div>
  <div class="e-card-separator"></div>
  <div class="e-card-content">
    <!--DataType Grid-->
    <ejs-grid
      id="cdi-data-type-settings-grid"
      #dataTypeSettingsrid
      [dataSource]="dataTypeSettingsList"
      width="100%"
      [allowResizing]="true"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowPaging]="false"
      allowTextWrap="true"
      [filterSettings]="filterOption"
      [loadingIndicator]="loadingIndicator"
      [toolbar]="toolbarOptions"
      [editSettings]="editSettings"
      [showColumnChooser]="true"
      [enableHover]="enableRowHover"
      (rowSelected)="onDataTypeGridRowSelected($event.data)"
    >
      <e-columns>
        <e-column
          field="dateCreated"
          headerText="Date Created"
          width="130"
          clipMode="EllipsisWithTooltip"
          [format]="dateFormatOption"
          type="date"
          [visible]="false"
        ></e-column>
        <e-column
          id="typeData"
          field="typeData"
          headerText="Data Type"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          id="isaActive"
          field="isActive"
          headerText="Is Active"
          textAlign="Center"
          width="100"
          [allowFiltering]="false"
          ><ng-template #template let-data>
            <ejs-switch
              id="change-userstatus-switch"
              #userStatusSwitch
              (change)="onChangeUserStatusSwitchClick($event, userStatusSwitch)"
              [checked]="data.isActive"
            ></ejs-switch> </ng-template></e-column
        ><e-column
          field="dateModified"
          headerText="Date Modified"
          textAlign="Center"
          width="130"
          type="date"
          [format]="dateFormatOption"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="false"
          [visible]="false"
        ></e-column>
        <e-column
          field="createdOrModifiedBy"
          headerText="Created Or Modified By"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
        <e-column headerText="Action" maxWidth="100">
          <ng-template #template let-data
            ><ejs-menu
              target="#target"
              [items]="menuItems"
              showItemOnClick="true"
              (select)="onDataTypeContextMenuSelect($event)"
            ></ejs-menu> </ng-template
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
<!--TextAlign Grid-->
<div class="e-card" *ngIf="this.showTextAlignGrid">
  <div
    class="e-card-title"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <div>Text Align</div>
    <div style="display: flex; gap: 10px">
      <button
        id="cdi-add-text-align-button"
        ejs-button
        style="background-color: #00308f"
        (click)="onClickAddTextAlignButton()"
      >
        Add
      </button>
    </div>
  </div>
  <div class="e-card-separator"></div>
  <div class="e-card-content">
    <ejs-grid
      id="cdi-text-align-settings-grid"
      #textAlignSettingsGrid
      [dataSource]="textAlignSettingsList"
      width="100%"
      enableStickyHeader="true"
      [allowResizing]="true"
      gridLines="Default"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowPaging]="false"
      allowTextWrap="true"
      [filterSettings]="filterOption"
      [loadingIndicator]="loadingIndicator"
      [toolbar]="toolbarOptions"
      [editSettings]="editSettings"
      [showColumnChooser]="true"
      [enableHover]="enableRowHover"
      (rowSelected)="onTextAlignGridRowSelected($event.data)"
    >
      <e-columns>
        <e-column
          field="dateCreated"
          headerText="Date Created"
          width="130"
          clipMode="EllipsisWithTooltip"
          [format]="dateFormatOption"
          type="date"
          [visible]="false"
        ></e-column>
        <e-column
          field="textAlign"
          headerText="Text Align"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="isActive"
          headerText="Is Active"
          textAlign="Center"
          width="100"
          [allowFiltering]="false"
          ><ng-template #template let-data>
            <ejs-switch
              id="change-userstatus-switch"
              #userStatusSwitch
              (change)="onChangeUserStatusSwitchClick($event, userStatusSwitch)"
              [checked]="data.isActive"
            ></ejs-switch> </ng-template></e-column
        ><e-column
          field="dateModified"
          headerText="Date Modified"
          textAlign="Center"
          width="130"
          type="date"
          [visible]="false"
          [format]="dateFormatOption"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="false"
        ></e-column>
        <e-column
          field="createdOrModifiedBy"
          headerText="Created Or Modified By"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
        <e-column headerText="Action" maxWidth="100">
          <ng-template #template let-data
            ><ejs-menu
              target="#target"
              [items]="menuItemsTextAlign"
              showItemOnClick="true"
              (select)="onTextAlignContextMenuSelect($event)"
            ></ejs-menu> </ng-template
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
<!--Format Grid-->
<div class="e-card" *ngIf="this.showFormatGrid">
  <div
    class="e-card-title"
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <div>Format</div>
    <div style="display: flex; gap: 10px">
      <button
        id="cdi-add-format-button"
        ejs-button
        style="background-color: #00308f"
        (click)="onClickAddFormatButton()"
      >
        Add
      </button>
    </div>
  </div>
  <div class="e-card-separator"></div>
  <div class="e-card-content">
    <ejs-grid
      id="cdi-format-settings-grid"
      #formatSettingsGrid
      [dataSource]="formatSettingsList"
      width="100%"
      enableStickyHeader="true"
      [allowResizing]="true"
      gridLines="Default"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowPaging]="false"
      allowTextWrap="true"
      [filterSettings]="filterOption"
      [loadingIndicator]="loadingIndicator"
      [toolbar]="toolbarOptions"
      [editSettings]="editSettings"
      [showColumnChooser]="true"
      [enableHover]="enableRowHover"
      (rowSelected)="onFormatGridRowSelected($event.data)"
    >
      <e-columns>
        <e-column
          field="dateCreated"
          headerText="Date Created"
          width="130"
          clipMode="EllipsisWithTooltip"
          [format]="dateFormatOption"
          type="date"
          [visible]="false"
        ></e-column>
        <e-column
          field="format"
          headerText="Format"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="isActive"
          headerText="Is Active"
          textAlign="Center"
          width="100"
          [allowFiltering]="false"
          ><ng-template #template let-data>
            <ejs-switch
              id="change-userstatus-switch"
              #userStatusSwitch
              (change)="onChangeUserStatusSwitchClick($event, userStatusSwitch)"
              [checked]="data.isActive"
            ></ejs-switch> </ng-template
        ></e-column>
        <e-column
          field="dateModified"
          headerText="Date Modified"
          width="130"
          clipMode="EllipsisWithTooltip"
          [format]="dateFormatOption"
          type="date"
          [visible]="false"
        ></e-column>
        <e-column
          field="createdOrModifiedBy"
          headerText="Created Or Modified By"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
        <e-column headerText="Action" maxWidth="100">
          <ng-template #template let-data
            ><ejs-menu
              target="#target"
              [items]="menuItemsFormat"
              showItemOnClick="true"
              (select)="onFormatContextMenuSelect($event)"
            ></ejs-menu> </ng-template
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
