export class ReportConfigurationDetails {
  public id?: number;
  public dateCreated?: Date;
  public dateModified?: Date;
  public reportConfigurationId?: number;
  public field?: string;
  public headerText?: string;
  public textAlign?: string;
  public width?: string;
  public format?: string;
  public allowSorting?: boolean;
  public allowFiltering?: boolean;
  public allowGrouping?: boolean;
  public isActive?: boolean;
  public isPrimaryKey?: boolean;
  public dataType?: string;
  public autofit?: boolean;
  public rowver?: Date;

  constructor(
    id?: number,
    dateCreated?: Date,
    dateModified?: Date,
    reportConfigurationId?: number,
    field?: string,
    headerText?: string,
    textAlign?: string,
    width?: string,
    format?: string,
    allowSorting?: boolean,
    allowFiltering?: boolean,
    allowGrouping?: boolean,
    isActive?: boolean,
    isPrimaryKey?: boolean,
    dataType?: string,
    autofit?: boolean,
    rowver?: Date
  ) {
    this.id = id;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.reportConfigurationId = reportConfigurationId;
    this.field = field;
    this.headerText = headerText;
    this.textAlign = textAlign;
    this.width = width;
    this.format = format;
    this.isActive = isActive;
    this.allowSorting = allowSorting;
    this.allowFiltering = allowFiltering;
    this.allowGrouping = allowGrouping;
    this.isPrimaryKey = isPrimaryKey;
    this.dataType = dataType;
    this.autofit = autofit;
    this.rowver = rowver;
  }
}
