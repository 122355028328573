import { Component } from '@angular/core';
import { world_map } from './world-map';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { CacheService } from 'src/app/services/cache.service';
import { ServerMetrics } from './model/server-metrics.model';
import { GeoLocation } from './model/geo-location.model';

@Component({
  selector: 'app-portal-management',
  templateUrl: './portal-management.component.html',
  styleUrls: ['./portal-management.component.scss'],
})
export class PortalManagementComponent {
  public failedRequestChart: { x: number; y: number }[] = [];
  public serverResponseChart: { x: number; y: number }[] = [];
  public availabilityChart: { x: number; y: number }[] = [];
  public title1?: string;
  public marker1?: Object;
  public tooltipSettings?: object;
  public title2: string = '';
  public marker2?: Object;
  public title3: string = '';
  public marker3?: Object;
  public timeRanges: string[] = [];
  public shapeData?: object;
  public markerSettings?: any;
  public hour: number = 0;
  public activity: string[] = [];
  public showServerMetrics: boolean = false;
  public showEventLog: boolean = false;
  public showOrderMinimum: boolean = false;
  public showOrderModificationSettings: boolean = false;
  public showReportConfiguration: boolean = false;
  public showEmailDelivery: boolean = false;
  public shapeSettings = {
    autofill: true,
  };

  constructor(
    private dataService: DataserviceService,
    private _cache: CacheService
  ) {}
  ngOnInit(): void {
    this.getGeoLocation();

    this.activity = [
      'Server Metrics',
      'Event Log',
      'Order Minimum',
      'Order Modification Settings',
      'Report Configuration',
      'Email Delivery',
      'Geo Location',
    ];
    this.shapeData = world_map;

    // line chart
    this.timeRanges = ['Last Hour', '6 Hours', '12 Hours', '1 Day', '7 Days'];

    this.marker1 = { visible: true, width: 10, height: 10 };
    this.title1 = 'Failed Requests';

    this.marker2 = { visible: true, width: 10, height: 10 };
    this.title2 = 'Server Response Time';

    this.marker3 = { visible: true, width: 10, height: 10 };
    this.title3 = 'Availability';
  }
  public onSelectFailedRequestTimeRange(args: any) {
    if (args.itemData.text == 'Last Hour') {
      this.hour = 1;
    } else if (args.itemData.text == '6 Hours') {
      this.hour = 6;
    } else if (args.itemData.text == '12 Hours') {
      this.hour = 12;
    } else if (args.itemData.text == '1 Day') {
      this.hour = 24;
    } else if (args.itemData.text == '7 Days') {
      this.hour = 168;
    }
    this.getFailedRequests();
  }
  public onSelectServerResponseTimeRange(args: any) {
    if (args.itemData.text == 'Last Hour') {
      this.hour = 1;
    } else if (args.itemData.text == '6 Hours') {
      this.hour = 6;
    } else if (args.itemData.text == '12 Hours') {
      this.hour = 12;
    } else if (args.itemData.text == '1 Day') {
      this.hour = 24;
    } else if (args.itemData.text == '7 Days') {
      this.hour = 168;
    }
    this.getServerResponseTime();
  }
  public onSelectAvailabilityTimeRange(args: any) {
    if (args.itemData.text == 'Last Hour') {
      this.hour = 1;
    } else if (args.itemData.text == '6 Hours') {
      this.hour = 6;
    } else if (args.itemData.text == '12 Hours') {
      this.hour = 12;
    } else if (args.itemData.text == '1 Day') {
      this.hour = 24;
    } else if (args.itemData.text == '7 Days') {
      this.hour = 168;
    }
    this.getAvailability();
  }
  public onSelectActivity(event: any): void {
    this.showServerMetrics = false;
    this.showEventLog = false;
    this.showOrderMinimum = false;
    this.showOrderModificationSettings = false;
    this.showReportConfiguration = false;
    this.showEmailDelivery = false;
    if (event.itemData.text === 'Server Metrics') {
      this.showServerMetrics = true;
    } else if (event.itemData.text === 'Event Log') {
      this.showEventLog = true;
    } else if (event.itemData.text === 'Order Minimum') {
      this.showOrderMinimum = true;
    } else if (event.itemData.text === 'Order Modification Settings') {
      this.showOrderModificationSettings = true;
    } else if (event.itemData.text === 'Report Configuration') {
      this.showReportConfiguration = true;
    } else if (event.itemData.text === 'Email Delivery') {
      this.showEmailDelivery = true;
    }
  }
  public getGeoLocation() {
    this.dataService
      .getAll<GeoLocation>(`GeoLocation/GetGeoLocation`)
      .subscribe((data) => {
        const markers = data.map((item) => ({
          latitude: item.latitude!,
          longitude: item.longitude!,
          userName: `${item.userName}, ${item.cityName}`,
        }));
        console.log('Markers', markers);
        this.markerSettings = [
          {
            visible: true,
            height: 20,
            width: 20,
            dataSource: markers,
            animationDuration: 0,
            tooltipSettings: {
              visible: true,
              valuePath: 'userName',
            },
          },
        ];
      });
  }
  public getFailedRequests() {
    this.dataService
      .getAll<ServerMetrics>(`ServerMetrics/GetFailedRequests/${this.hour}`)
      .subscribe((data) => {
        this.failedRequestChart = data.map((item) => ({
          x: item.time as number,
          y: item.count as number,
        }));
      });
  }

  public getServerResponseTime() {
    this.dataService
      .getAll<ServerMetrics>(`ServerMetrics/GetServerResponseTime/${this.hour}`)
      .subscribe((data) => {
        this.serverResponseChart = data.map((item) => ({
          x: item.time as number,
          y: item.count as number,
        }));
      });
  }

  public getAvailability() {
    this.dataService
      .getAll<ServerMetrics>(`ServerMetrics/GetAvailability/${this.hour}`)
      .subscribe((data) => {
        this.availabilityChart = data.map((item) => ({
          x: item.time as number,
          y: item.count as number,
        }));
      });
  }
}
