export class DtoRexOrderForm {
  public name?: string;
  public description?: string;
  public orderFormTypeId?: number;
  public orderFormType?: string;
  public effectiveDate?: Date;
  public expirationDate?: Date;
  public branchPlant?: string;
  public companyCode?: string;
  public orderFormId?: number;
  public isActive?: boolean;
  constructor(
    name?: string,
    description?: string,
    orderFormTypeId?: number,
    orderFormType?: string,
    effectiveDate?: Date,
    expirationDate?: Date,
    branchPlant?: string,
    companyCode?: string,
    orderFormId?: number,
    isActive?: boolean
  ) {
    this.name = name;
    this.description = description;
    this.orderFormTypeId = orderFormTypeId;
    this.orderFormType = orderFormType;
    this.effectiveDate = effectiveDate;
    this.expirationDate = expirationDate;
    this.branchPlant = branchPlant;
    this.companyCode = companyCode;
    this.orderFormId = orderFormId;
    this.isActive = isActive;
  }
}
