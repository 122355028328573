export class ReportConfiguration {
  public id?: number;
  public dateCreated?: Date;
  public dateUpdated?: Date;
  public name?: string;
  public description?: string;
  public reportAccessibleById?: number;
  public reportAccessibleByDescription?: string;
  public isActive?: boolean;
  public showPerformaCheckBox?: boolean;
  public showBeginDate?: boolean;
  public showEndDate?: boolean;
  public branchPlantRequired?: boolean;
  public apiEndPoint?: string;
  public showYearDropDown?: boolean;
  public showProductLineDropDown?: boolean;
  public isAccountNumberRequired?: boolean;
  public isCommissionCodeRequired?: boolean;
  public rowver?: Date;

  constructor(
    id?: number,
    dateCreated?: Date,
    dateUpdated?: Date,
    name?: string,
    description?: string,
    reportAccessibleById?: number,
    reportAccessibleByDescription?: string,
    isActive?: boolean,
    showPerformaCheckBox?: boolean,
    showBeginDate?: boolean,
    showEndDate?: boolean,
    branchPlantRequired?: boolean,
    apiEndPoint?: string,
    showYearDropDown?: boolean,
    showProductLineDropDown?: boolean,
    isAccountNumberRequired?: boolean,
    isCommissionCodeRequired?: boolean,
    rowver?: Date
  ) {
    this.id = id;
    this.dateCreated = dateCreated;
    this.dateUpdated = dateUpdated;
    this.name = name;
    this.description = description;
    this.reportAccessibleById = reportAccessibleById;
    this.reportAccessibleByDescription = reportAccessibleByDescription;
    this.isActive = isActive;
    this.showPerformaCheckBox = showPerformaCheckBox;
    this.showBeginDate = showBeginDate;
    this.showEndDate = showEndDate;
    this.branchPlantRequired = branchPlantRequired;
    this.apiEndPoint = apiEndPoint;
    this.showYearDropDown = showYearDropDown;
    this.showProductLineDropDown = showProductLineDropDown;
    this.isAccountNumberRequired = isAccountNumberRequired;
    this.isCommissionCodeRequired = isCommissionCodeRequired;
    this.rowver = rowver;
  }
}
