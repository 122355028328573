import { Injectable, Inject } from '@angular/core';
import { UserProfile } from './model/user-profile.model';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userProfile: UserProfile;
  public isAuthenticated: boolean;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public authService: AuthService
  ) {
    this.isAuthenticated = false;
    this.userProfile = new UserProfile();
    this.authService.isAuthenticated$
      .pipe(distinctUntilChanged())
      .subscribe((data) => (this.isAuthenticated = data));

    this.authService.user$.pipe(distinctUntilChanged()).subscribe((user) => {
      const namespace = 'https://rex.cascadedesigns.com/';
      let email = this.getValueByKey(user, `${namespace}email`);

      this.userProfile.family_name = user?.family_name;
      this.userProfile.given_name = user?.given_name;
      this.userProfile.name = user?.name;
      this.userProfile.nickname = user?.nickname;
      this.userProfile.picture = user?.picture;
      this.userProfile.initials = this.getInitials();
      this.userProfile.companyCode = this.getCompanyCodeFromEmail(email);
    });
  }

  private getCompanyCodeFromEmail(email: string): string {
    //Gets the last couple characters from users email to determine
    //if user is from EU (ie) or US (com)
    let domain = email.split('@')[1].split('.')[1];

    if (domain == 'com') {
      return '00015';
    } else if (domain == 'ie') {
      return '00030';
    } else {
      return 'Invalid company email';
    }
  }

  private getValueByKey(user: any, key: string): any {
    let value: any = null;
    Object.keys(user).forEach((propertyName) => {
      if (propertyName == key) {
        value = user[propertyName];
      }
    });
    return value;
  }

  public logout(): void {
    this.authService.logout({
      logoutParams: { returnTo: document.location.origin },
    });
  }

  private getInitials(): string {
    let initials: string = '';
    try {
      initials = `${this.userProfile.given_name?.substring(
        0,
        1
      )}${this.userProfile.family_name?.substring(0, 1)}`;
    } catch (error) {
      initials = 'UU';
    }
    return initials;
  }
}
