<footer class="dark-primary">
  <div class="footer">
    <p>
      <a
        href="https://www.cascadedesigns.com/s/Sites-cdi-us-Site/dw/shared_session_redirect?url=https%3A%2F%2Fwww.msrgear.com%2F"
        target="_blank"
      >
        <img
          src="assets/img/header_msr_logo.svg"
          class="msr"
          height="25px"
          alt="CRD_logo"
        />
      </a>
      <a
        href="https://www.cascadedesigns.com/s/Sites-cdi-us-Site/dw/shared_session_redirect?url=https%3A%2F%2Fwww.platy.com%2F"
        target="_blank"
      >
        <img
          src="assets/img/header_platy_logo.svg"
          class="msr"
          height="20px"
          alt="CRD_logo"
        />
      </a>
      <a
        href="https://www.cascadedesigns.com/s/Sites-cdi-us-Site/dw/shared_session_redirect?url=https%3A%2F%2Fwww.packtowl.com%2F"
        target="_blank"
        ><img
          src="assets/img/header_packtowl_logo.svg"
          class="msr"
          height="20px"
          alt="CRD_logo"
      /></a>
      <a
        href="https://www.cascadedesigns.com/s/Sites-cdi-us-Site/dw/shared_session_redirect?url=https%3A%2F%2Fwww.seallinegear.com%2F"
        target="_blank"
        ><img
          src="assets/img/header_sealline_logo.svg"
          class="msr"
          height="20px"
          alt="CRD_logo"
      /></a>
      <a
        href="https://www.cascadedesigns.com/s/Sites-cdi-us-Site/dw/shared_session_redirect?url=https%3A%2F%2Fwww.thermarest.com%2F"
        target="_blank"
        ><img
          src="assets/img/header_tar_logo.svg"
          class="msr"
          height="20px"
          alt="CRD_logo"
      /></a>
    </p>
  </div>
  <div class="copyright">
    <img
      src="assets/img/CDI_LOGO.svg"
      class="mr-2"
      height="45px"
      alt="CRD_logo"
    />
    &copy; {{ year }}
  </div>
</footer>
