import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationMessage } from '../shared/notification/model/notification-message.model';
import { DialogType } from '../shared/notification/model/dialog-type.enum';
import { ReportConfiguration } from '../content/report-configuration/model/report-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public maxCount: number = 1;

  public showDialog: BehaviorSubject<NotificationMessage> =
    new BehaviorSubject<NotificationMessage>(
      new NotificationMessage(DialogType.notSet, '', '')
    );

  public selectedReportConfigurationId: BehaviorSubject<ReportConfiguration> =
    new BehaviorSubject<ReportConfiguration>(new ReportConfiguration(0));

  constructor() {}
}
