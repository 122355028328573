import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { UserService } from 'src/app/services/user.service';
import { Currency } from './model/currency.model';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { BuisnessUnit } from './model/buisness-unit.model';
import {
  CommandModel,
  EditSettingsModel,
  FilterSettingsModel,
  GridComponent,
  SelectionSettingsModel,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { CheckBoxSelectionService } from '@syncfusion/ej2-angular-dropdowns';
import { RexProductBrand } from './model/rex-product-brand.model';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-filemanager';
import { TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { CacheService } from 'src/app/services/cache.service';
import { DtoOrderForm } from './model/dto-order-form.model';
import { OrderFormType } from './model/order-form-type.model';
import { DtoRexOrderForm } from './model/dto-rex-order-form.model';
import { DtoChildObject } from './model/dto-child-object.model';
import { RexProductLine } from './model/rex-product-line.model';
import { RexProductFamily } from './model/rex-product-family.model';
import { RexItem } from './model/rex-item.model';
import { QuickAddOption } from './model/quick-add-option.enum';
import { OrderFormMode } from './model/order-form-mode.enum';
import { ChildObject } from './model/child-object.model';

@Component({
  selector: 'app-order-form-layout',
  templateUrl: './order-form-layout.component.html',
  styleUrls: ['./order-form-layout.component.scss'],
  providers: [CheckBoxSelectionService],
})
export class OrderFormLayoutComponent {
  @ViewChild('ejDialogAddOrderForm')
  ejDialogAddOrderForm!: DialogComponent;
  @ViewChild('ejDialogEditOrderForm')
  ejDialogEditOrderForm!: DialogComponent;
  @ViewChild('ejDialogSaveOrderLayout')
  ejDialogSaveOrderLayout!: DialogComponent;
  @ViewChild('ejDialogQuickAddItem')
  ejDialogQuickAddItem!: DialogComponent;
  @ViewChild('ejDialogConfirmationPublishOrderLayout')
  ejDialogConfirmationPublishOrderLayout!: DialogComponent;
  @ViewChild('ejDialogPublishOrderLayout')
  ejDialogPublishOrderLayout!: DialogComponent;
  @ViewChild('ejDialogStartupOrderLayout')
  ejDialogStartupOrderLayout!: DialogComponent;
  @ViewChild('ejDialogCloneOrderFormLayout')
  ejDialogCloneOrderFormLayout!: DialogComponent;
  @ViewChild('datePickerEffectiveDate')
  public datePickerEffectiveDate!: DatePickerComponent;
  @ViewChild('datePickerExpirationDate')
  public datePickerExpirationDate!: DatePickerComponent;
  @ViewChild('orderFormLayoutGrid') orderFormLayoutGrid!: GridComponent;
  @ViewChild('orderFormLayoutTreeGrid')
  orderFormLayoutTreeGrid!: TreeGridComponent;

  public branchPlants: BuisnessUnit[] = [];
  public currencies: Currency[] = [];
  public quickAddBrand: RexProductBrand[] = [];
  public rexProducts: ChildObject[] = [];
  public rexProductLines: RexProductLine[] = [];
  public rexProductFamilies: RexProductFamily[] = [];
  public rexItems: RexItem[] = [];
  public selectedProductLine: RexProductLine[] = [];
  public selectedProductBrand: RexProductBrand[] = [];
  public selectedProductFamily: RexProductFamily[] = [];
  public selectedQuickAddItem?: RexItem = new RexItem(
    '00565',
    'Aquatabs 30 Tablets',
    undefined,
    'MPS',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'EA',
    undefined,
    undefined,
    'MSR',
    'MSR',
    'MWT',
    'Aquatabs 30 Tablets',
    'MSR Purifier Systems'
  );
  public editOrderForm?: DtoRexOrderForm[] = [];
  public publishOrderForm?: DtoRexOrderForm[] = [];
  public selectedOrderForm?: DtoRexOrderForm;
  public selectedPublishOrderForm?: DtoRexOrderForm;
  public selectedBranchPlant?: BuisnessUnit;
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };
  public toolbarOrderFormLayout?: ToolbarItems[] | object;
  public toolbarEditOrderFormLayout?: ToolbarItems[] | object;
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public enableRowHover: boolean = false;
  public allowDragAndDrop: boolean = true;
  public selectionOptions?: SelectionSettingsModel;
  public showProductBrands: boolean = false;
  public showProductLines: boolean = false;
  public showProductFamilies: boolean = false;
  public showItems: boolean = false;
  public hideCards: boolean = false;
  public selectedCompanyCode: string = '';
  public companycode: { id: string; name: string }[] = [
    { id: '00015', name: 'CDI' },
    { id: '00030', name: 'CDL' },
  ];
  public mode: string = 'CheckBox';
  public editSettings?: EditSettingsModel;
  public orderFormTypes?: OrderFormType[] = [];
  public selectedOrderFormType?: OrderFormType;
  public itemDetails?: any[] = [];
  public itemNumber?: string = '';
  public editRowSelected?: boolean = false;
  public publishRowSelected?: boolean = false;
  public orderFormId: number = 0;
  public quickAddOption: QuickAddOption = QuickAddOption.unknown;
  public orderFormMode: OrderFormMode = OrderFormMode.unknown;
  public rexOrderForm: DtoRexOrderForm = new DtoRexOrderForm(
    '',
    '',
    undefined,
    '',
    undefined,
    undefined,
    '',
    '',
    undefined,
    false
  );
  public dropdownFieldsCompanycode: Object = { text: 'name', value: 'id' };
  public dropdownlistItemDetailsSearchFields: Object = {
    text: 'itemDescription',
    value: 'itemNumber',
  };
  public dropdownlistBranchPlantSearchFields: Object = {
    text: 'branchPlant',
    value: 'branchPlant',
  };
  public dropdownlistOrderFormTypeSearchFields: Object = {
    text: 'name',
    value: 'name',
  };
  public dropdownlistProductBrandSearchFields: Object = {
    text: 'productBrandDescription',
    value: 'productBrandCode',
  };
  public dropdownlistProductLineSearchFields: Object = {
    text: 'productLineDescription',
    value: 'productLineCode',
  };
  public dropdownlistProductFamilySearchFields: Object = {
    text: 'productFamilyDescription',
    value: 'productFamilyCode',
  };
  public dropdownFieldsOrderForm: Object = {
    text: 'name',
    value: 'orderFormId',
  };
  public commands: CommandModel[] = [
    {
      type: 'Delete',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' },
    },
  ];
  constructor(
    private dataService: DataserviceService,
    public userService: UserService,
    private _cache: CacheService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.ejDialogStartupOrderLayout.show();
    }, 100);
    this.getOrderFormType();
    this.getAllBranchPlants();
    this.toolbarOrderFormLayout = ['Quick Add', 'ColumnChooser', 'Search'];
    this.toolbarEditOrderFormLayout = ['ColumnChooser', 'Search'];
    this.editSettings = { allowDeleting: true };
    this.selectionOptions = { checkboxOnly: true, type: 'Single' };
  }
  public onToolbarButtonClick(args: ToolbarClickEventArgs) {
    if (args.item.text == 'Quick Add') {
      this.ejDialogQuickAddItem.show();
    }
  }
  get branchPlant() {
    return this.addOrderLayoutForm.get('branchPlant');
  }
  get companyCode() {
    return this.addOrderLayoutForm.get('companyCode');
  }
  get orderFormType() {
    return this.addOrderLayoutForm.get('orderFormType');
  }
  get name() {
    return this.saveOrderForm.get('name');
  }
  get description() {
    return this.saveOrderForm.get('description');
  }
  get saveEffectiveDate() {
    return this.cloneOrderForm.get('effectiveDate');
  }
  get saveExpirationDate() {
    return this.cloneOrderForm.get('expirationDate');
  }
  get saveFormNameAs() {
    return this.cloneOrderForm.get('saveFormNameAs');
  }
  get newDescription() {
    return this.cloneOrderForm.get('newDescription');
  }
  get newBranchPlant() {
    return this.cloneOrderForm.get('newBranchPlant');
  }
  get newCompanyCode() {
    return this.cloneOrderForm.get('newCompanyCode');
  }
  get effectiveDate() {
    return this.cloneOrderForm.get('effectiveDate');
  }
  get expirationDate() {
    return this.cloneOrderForm.get('expirationDate');
  }
  get orderForm() {
    return this.cloneOrderForm.get('orderForm');
  }
  public dateRangeValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const effectiveDate = control.get('effectiveDate')?.value;
    const expirationDate = control.get('expirationDate')?.value;

    const saveEffectiveDate = control.get('saveEffectiveDate')?.value;
    const saveExpirationDate = control.get('saveExpirationDate')?.value;
    if (
      (effectiveDate &&
        expirationDate &&
        new Date(effectiveDate) >= new Date(expirationDate)) ||
      (saveEffectiveDate &&
        saveExpirationDate &&
        new Date(saveEffectiveDate) >= new Date(saveExpirationDate))
    ) {
      return { dateRange: true };
    }

    return null;
  };
  public addOrderLayoutForm = new FormGroup({
    branchPlant: new FormControl('', [Validators.required]),
    companyCode: new FormControl('', [Validators.required]),
    orderFormType: new FormControl('', [Validators.required]),
  });
  public saveOrderForm = new FormGroup(
    {
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      saveEffectiveDate: new FormControl('', [Validators.required]),
      saveExpirationDate: new FormControl('', [Validators.required]),
    },

    { validators: this.dateRangeValidator }
  );
  public cloneOrderForm = new FormGroup(
    {
      orderForm: new FormControl('', [Validators.required]),
      saveFormNameAs: new FormControl('', [Validators.required]),
      newDescription: new FormControl('', [Validators.required]),
      newBranchPlant: new FormControl('', [Validators.required]),
      newCompanyCode: new FormControl('', [Validators.required]),
      effectiveDate: new FormControl('', [Validators.required]),
      expirationDate: new FormControl('', [Validators.required]),
    },

    { validators: this.dateRangeValidator }
  );
  public onItemDetailsDropDownSelect(event: any): void {
    this.itemNumber = event.itemData.itemNumber;
  }
  public onBranchPlantDropDownSelect(event: any): void {
    this.selectedBranchPlant = event.itemData;
  }
  public onCompanyCodeDropDownSelect(args: any): void {
    this.selectedCompanyCode = args.itemData.id;
  }
  public onOrderFormTypeDropDownSelect(event: any): void {
    this.selectedOrderFormType = event.itemData;
  }
  public onOrderFormDropDownSelect(args: any): void {
    this.rexOrderForm.orderFormId = args.itemData.orderFormId;
    this.rexOrderForm.name = args.itemData.name;
    this.rexOrderForm.orderFormTypeId = args.itemData.orderFormTypeId;
  }
  public onProductBrandDropDownSelect(event: any): void {
    this.selectedProductBrand = event.itemData.productBrandCode;
    this.getProductLines();
  }
  public onProductLineDropDownSelect(event: any): void {
    this.selectedProductLine = event.itemData.productLineCode;
    this.getProductFamily();
  }
  public onProductFamilyDropDownSelect(event: any): void {
    this.selectedProductFamily = event.itemData.productFamilyCode;
    this.getItems();
  }
  public onClickEditRowSelected(args: any) {
    this.editRowSelected = true;
    this.selectedOrderForm = args.data;
  }
  public onClickPublishRowSelected(args: any) {
    this.publishRowSelected = true;
    this.selectedPublishOrderForm = args.data;
  }
  public getAllBranchPlants(): void {
    const companyCode = this.userService.userProfile.companyCode;
    this.dataService
      .getAll<BuisnessUnit>(
        `OrderForm/GetBranchPlantsByCompanyCode/${companyCode}`
      )
      .subscribe((data) => {
        data.forEach((element) => {
          this.branchPlants.push(new BuisnessUnit(element.branchPlant));
        });
      });
  }
  public getOrderFormType(): void {
    this.dataService
      .getAll<OrderFormType>(`OrderForm/GetOrderFormType`)
      .subscribe((data) => {
        this.orderFormTypes = data;
      });
  }
  public onAddOrderFormButtonClick() {
    this.editRowSelected = false;
    this.dataService
      .get<DtoOrderForm>(
        `OrderForm/GetItemsByBranchPlant/${this.selectedBranchPlant?.branchPlant}`
      )
      .subscribe((dtoOrderForm) => {
        this.rexProducts = dtoOrderForm.childObjects;
      });
    this.ejDialogStartupOrderLayout.hide();
    this.ejDialogAddOrderForm.hide();
  }

  public getAllBrands(): void {
    this.selectedProductBrand = [];
    this.dataService
      .getAll<RexProductBrand>(`OrderForm/GetAllBrands/${'REN1'}`)
      .subscribe((rexProductBrands) => {
        this.quickAddBrand = rexProductBrands;
      });
  }
  public getProductLines(): void {
    this.dataService
      .getAll<RexProductLine>(
        `OrderForm/GetProductLines/${'REN1'}/${this.selectedProductBrand}`
      )
      .subscribe((rexProductLines) => {
        this.rexProductLines = rexProductLines;
      });
  }
  public getProductFamily(): void {
    this.dataService
      .getAll<RexProductLine>(
        `OrderForm/GetProductFamily/${'REN1'}/${this.selectedProductBrand}/${
          this.selectedProductLine
        }`
      )
      .subscribe((rexProductFamilies) => {
        this.rexProductFamilies = rexProductFamilies;
      });
  }
  public getItems(): void {
    const selectedItemNumber = '223233';
    this.dataService
      .getAll<RexItem>(`OrderForm/GetItems/${'REN1'}/${selectedItemNumber}`)
      .subscribe((rexItems) => {
        this.itemDetails = rexItems;
      });
  }
  public onCloseDialogSaveOrderLayout(): void {
    this.ejDialogSaveOrderLayout.hide();
    this.editRowSelected = false;
  }
  public onAddOrderFormDialogCloseButtonClick() {
    this.ejDialogAddOrderForm.hide();
  }
  public onClickNoButtonOfSaveOrderLayoutDialog(): void {
    this.ejDialogSaveOrderLayout.hide();
  }
  public onClickSaveOrderLayout(): void {
    if (this.editRowSelected) {
      this.onClickYesButtonOfSaveOrderLayoutDialog();
    } else {
      this.ejDialogSaveOrderLayout.show();
    }
  }
  public rowDrop(args: any) {
    const fromIndex = args.fromIndex;
    const dropIndex = args.dropIndex;
    const fromRowIndex = this.orderFormLayoutTreeGrid.getRowByIndex(fromIndex);
    const toRowIndex = this.orderFormLayoutTreeGrid.getRowByIndex(dropIndex);

    const fromRow = this.orderFormLayoutTreeGrid.getRowInfo(fromRowIndex);
    const toRow = this.orderFormLayoutTreeGrid.getRowInfo(toRowIndex);
    if (
      (fromRow.rowData as any).childType != (toRow.rowData as any).childType
    ) {
      args.cancel = true;
    }
    if (
      (fromRow.rowData as any).parentCode != (toRow.rowData as any).parentCode
    ) {
      args.cancel = true;
    }
    this._cache.showDialog.next(
      new NotificationMessage(
        DialogType.error,
        'Invalid Action',
        `Unable to move object ${(fromRow.rowData as any).description} to ${
          (toRow.rowData as any).description
        }`
      )
    );
  }
  public onEditOrderFormDialogCloseButtonClick(): void {
    this.ejDialogEditOrderForm.hide();
  }
  public onClickEditPriceGroupLayoutButton(): void {
    this.ejDialogEditOrderForm.show();
  }
  public onCloseDialogConfirmationPublishOrderLayout(): void {
    this.ejDialogConfirmationPublishOrderLayout.hide();
  }
  public onClickYesButtonOfConfirmationPublishOrderLayoutDialog(): void {
    this.publishRowSelected = true;
    if (this.publishRowSelected) {
      const isActive = true;
      this.dataService
        .update<DtoRexOrderForm>(
          `OrderForm/PublishOrderForm/${this.selectedPublishOrderForm?.orderFormId}`
        )
        .subscribe((publishOrderForm) => {
          this._cache.showDialog.next(
            new NotificationMessage(
              DialogType.error,
              'Publish Success!',
              `Order Form Publish Successfully`
            )
          );
        });
    }
    this.ejDialogConfirmationPublishOrderLayout.hide();
    this.ejDialogPublishOrderLayout.hide();
  }
  public onClickConfirmationPublishButton(): void {
    this.ejDialogConfirmationPublishOrderLayout.show();
  }
  public onCloseDialogPublishOrderLayout(): void {
    this.ejDialogPublishOrderLayout.hide();
  }
  public onCloseDialogStartupOrderLayout(): void {
    this.ejDialogStartupOrderLayout.hide();
  }
  public onClickYesButtonOfPublishOrderLayoutDialog(): void {
    this.ejDialogConfirmationPublishOrderLayout.show();
  }
  public onClickPublishButton(): void {
    this.ejDialogPublishOrderLayout.show();
    this.dataService
      .getAll<DtoRexOrderForm>(`OrderForm/DisplayUnpublishedOrderForm`)
      .subscribe((data) => {
        this.publishOrderForm = data;
      });
  }
  public onCloneOrderFormLayoutDialogCloseButtonClick(): void {
    this.ejDialogCloneOrderFormLayout.hide();
  }
  public onClickOkButtonOfEditOrderFormDialog(): void {
    this.dataService
      .get<DtoOrderForm>(
        `OrderForm/GetOrderForm/${this.selectedOrderForm?.orderFormId}`
      )
      .subscribe((orderForm) => {
        this.rexProducts = orderForm.childObjects;
      });
    this.ejDialogEditOrderForm.hide();
  }
  public onClickEditOrderLayoutButton(): void {
    this.orderFormMode = OrderFormMode.edit;
    this.dataService
      .getAll<DtoRexOrderForm>(`OrderForm/DisplayExistingOrderForm`)
      .subscribe((data) => {
        this.editOrderForm = data;
        this.ejDialogEditOrderForm.show();
      });
    this.ejDialogStartupOrderLayout.hide();
  }
  public onClickCloneOrderLayoutButton(): void {
    this.ejDialogCloneOrderFormLayout.show();
    this.dataService
      .getAll<DtoRexOrderForm>(`OrderForm/DisplayExistingOrderForm`)
      .subscribe((data) => {
        this.editOrderForm = data;
      });
  }
  public onSaveCloneOrderFormLayoutButtonClick(): void {
    const newFormName = this.cloneOrderForm.get('saveFormNameAs')?.value;
    const newDescription = this.cloneOrderForm.get('newDescription')?.value;
    const newEffectiveDate = this.dataService.getISO8601Date(
      this.datePickerEffectiveDate.value
    );
    const newExpirationDate = this.dataService.getISO8601Date(
      this.datePickerExpirationDate.value
    );
    const newBranchPlant = this.cloneOrderForm.get('newBranchPlant')?.value;
    const newCompanyCode = this.cloneOrderForm.get('newCompanyCode')?.value;
    const createdOrModifiedBy = this.userService.userProfile.name;
    this.dataService
      .getAll<DtoRexOrderForm>(
        `OrderForm/CloneOrderForm/${this.rexOrderForm.orderFormId}/${newFormName}/${newDescription}/
        ${this.rexOrderForm.orderFormTypeId}/${newEffectiveDate}/${newExpirationDate}/${newBranchPlant}/${newCompanyCode}/${createdOrModifiedBy}`
      )
      .subscribe((data) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.error,
            'Clone Success!',
            `Clone Created Successfully`
          )
        );
      });
    this.ejDialogCloneOrderFormLayout.hide();
  }

  public onClickQuickAddButton(): void {
    switch (this.quickAddOption) {
      case QuickAddOption.productBrand:
        break;
      case QuickAddOption.productLine:
        break;
      case QuickAddOption.productFamily:
        break;
      case QuickAddOption.item:
        // this.addSelectedItem();
        break;

      default:
        break;
    }
    this.ejDialogQuickAddItem.hide();
  }
  // private addSelectedItem(): void {
  //   const brandIndex = this.rexProductBrands.findIndex(
  //     (f) => f.code == this.selectedQuickAddItem?.productBrandCode
  //   );
  //   if (brandIndex != -1) {
  //     const productLineIndex = this.rexProductBrands[
  //       brandIndex
  //     ].childObject?.findIndex(
  //       (f) => f.code == this.selectedQuickAddItem?.productLineCode
  //     );
  //     if (productLineIndex != undefined && productLineIndex != -1) {
  //       const productFamilyIndex = this.rexProductBrands[
  //         brandIndex
  //       ].childObject![productLineIndex].childObject?.findIndex(
  //         (f) => f.code == this.selectedQuickAddItem?.productFamilyCode
  //       );
  //       if (
  //         brandIndex != undefined &&
  //         productLineIndex != undefined &&
  //         productFamilyIndex != undefined &&
  //         productFamilyIndex != -1
  //       ) {
  //         const itemIndex = this.rexProductBrands[brandIndex].childObject![
  //           productLineIndex
  //         ].childObject![productFamilyIndex].childObject?.findIndex(
  //           (f) => f.code == this.selectedQuickAddItem?.itemNumber
  //         );
  //         if (itemIndex == -1) {
  //           let childObjectProductLines: DtoChildObject[] = [];
  //           let childObjectProductFamilies: DtoChildObject[] = [];
  //           let childObjectItems: DtoChildObject[] = [];

  //           childObjectItems.push(
  //             new DtoChildObject(
  //               this.selectedQuickAddItem?.itemNumber!,
  //               undefined!,
  //               this.selectedQuickAddItem?.itemDescription!,
  //               'Item',
  //               this.selectedQuickAddItem?.productFamilyCode,
  //               false,
  //               this.selectedQuickAddItem?.unitOfMeasure
  //             )
  //           );

  //           childObjectProductFamilies.push(
  //             new DtoChildObject(
  //               this.selectedQuickAddItem?.productFamilyCode!,
  //               undefined!,
  //               this.selectedQuickAddItem?.productFamilyDescription!,
  //               'ProductFamily',
  //               this.selectedQuickAddItem?.productLineCode,
  //               false,
  //               this.selectedQuickAddItem?.unitOfMeasure,
  //               childObjectItems
  //             )
  //           );

  //           childObjectProductLines.push(
  //             new DtoChildObject(
  //               this.selectedQuickAddItem?.productLineCode!,
  //               undefined!,
  //               this.selectedQuickAddItem?.productLineDescription!,
  //               'ProductLine',
  //               this.selectedQuickAddItem?.productBrandCode,
  //               false,
  //               this.selectedQuickAddItem?.unitOfMeasure,
  //               childObjectProductFamilies
  //             )
  //           );

  //           let newItem = new DtoChildObject(
  //             this.selectedQuickAddItem?.productBrandCode!,
  //             undefined!,
  //             this.selectedQuickAddItem?.productBrandDescription!,
  //             'Item',
  //             '',
  //             true,
  //             undefined,
  //             childObjectProductLines
  //           );
  //           this.rexProductBrands.push(newItem);
  //         } else {
  //           this._cache.showDialog.next(
  //             new NotificationMessage(
  //               DialogType.error,
  //               'Not Found',
  //               `Item ${this.selectedQuickAddItem?.itemNumber} Not Found`
  //             )
  //           );
  //         }
  //       } else {
  //         this._cache.showDialog.next(
  //           new NotificationMessage(
  //             DialogType.error,
  //             'Not Found',
  //             `Product Family ${this.selectedQuickAddItem?.productFamilyDescription} Not Found`
  //           )
  //         );
  //       }
  //     } else {
  //       this._cache.showDialog.next(
  //         new NotificationMessage(
  //           DialogType.error,
  //           'Not Found',
  //           `Product Line ${this.selectedQuickAddItem?.productLineDescription} Not Found`
  //         )
  //       );
  //     }
  //   } else {
  //     this._cache.showDialog.next(
  //       new NotificationMessage(
  //         DialogType.error,
  //         'Not Found',
  //         `Product Brand ${this.selectedQuickAddItem?.productBrandDescription} Not Found`
  //       )
  //     );
  //   }
  //   this.rexProductBrands.push();
  //   this.orderFormLayoutTreeGrid.refresh();
  // }
  public onClickNewOrderLayoutButton(): void {
    this.orderFormMode = OrderFormMode.new;
    this.ejDialogAddOrderForm.show();
  }
  public onClickYesButtonOfSaveOrderLayoutDialog(): void {
    let childObjects: DtoChildObject[] = [];
    let name = this.saveOrderForm.get('name')?.value?.toString();
    let description = this.saveOrderForm.get('description')?.value?.toString();
    if (this.orderFormMode == OrderFormMode.new) {
      let effectiveDate = this.dataService.getISO8601Date(
        this.datePickerEffectiveDate.value
      );
      let expirationDate = this.dataService.getISO8601Date(
        this.datePickerExpirationDate.value
      );
      this.rexOrderForm.effectiveDate = new Date(effectiveDate);
      this.rexOrderForm.expirationDate = new Date(expirationDate);
    }
    this.rexOrderForm.name = name;
    this.rexOrderForm.description = description;
    this.rexOrderForm.orderFormTypeId = this.selectedOrderFormType?.id;
    this.rexOrderForm.branchPlant = this.selectedBranchPlant?.branchPlant;
    this.rexOrderForm.companyCode = this.userService.userProfile.companyCode;
    let dtoOrderForm: DtoOrderForm = new DtoOrderForm(
      childObjects,
      this.rexOrderForm
    );
    const rows = this.orderFormLayoutTreeGrid.getRows();
    let brandIndex: number = -1;
    rows.forEach((row) => {
      const brand = this.orderFormLayoutTreeGrid.getRowInfo(row) as any;

      if (brand.rowData.isBrand) {
        if (childObjects.findIndex((f) => f.code == brand.rowData.code) == -1) {
          brandIndex++;
          childObjects.push(
            new DtoChildObject(
              brand.rowData.code,
              brandIndex,
              brand.rowData.description,
              'ProductBrand',
              undefined,
              true,
              undefined,
              []
            )
          );
        }

        const productLines = brand.rowData.childObject;
        productLines.forEach((productLine: any, lineIndex: number) => {
          if (
            childObjects[brandIndex].childObject!.findIndex(
              (f) => f.code == productLine.code
            ) == -1
          ) {
            childObjects[brandIndex].childObject!.push(
              new DtoChildObject(
                productLine.code,
                lineIndex,
                productLine.description,
                'ProductLine',
                childObjects[brandIndex].code,
                false,
                undefined,
                []
              )
            );
          }

          const productFamilies = productLine.childObject;

          productFamilies.forEach((productFamily: any, familyIndex: number) => {
            if (
              childObjects[brandIndex].childObject![
                lineIndex
              ].childObject!.findIndex((f) => f.code == productFamily.code) ==
              -1
            ) {
              childObjects[brandIndex].childObject![
                lineIndex
              ].childObject!.push(
                new DtoChildObject(
                  productFamily.code,
                  familyIndex,
                  productFamily.description,
                  'ProductFamily',
                  productFamily.parentCode,
                  false,
                  undefined,
                  []
                )
              );
            }

            const items = productFamily.childObject;

            items.forEach((item: any, itemIndex: number) => {
              if (
                childObjects[brandIndex].childObject![lineIndex].childObject![
                  familyIndex
                ].childObject!.findIndex((f) => f.code == item.code) == -1
              ) {
                childObjects[brandIndex].childObject![lineIndex].childObject![
                  familyIndex
                ].childObject!.push(
                  new DtoChildObject(
                    item.code,
                    itemIndex,
                    item.description,
                    item.parentCode,
                    item.unitOfMeasure,
                    undefined
                  )
                );
              }
            });
          });
        });
      }
    });
    let orderFormId = this.rexOrderForm.orderFormId ?? 0;
    let isUpdate: boolean = false;
    if (this.orderFormMode == OrderFormMode.edit) {
      isUpdate = true;
      orderFormId = this.selectedOrderForm?.orderFormId!;
      dtoOrderForm.rexOrderForm = this.selectedOrderForm;
    }
    this.dataService
      .add<any>(
        `orderForm/CreateOrUpdateOrderForm/${isUpdate}/${orderFormId}`,
        dtoOrderForm
      )
      .subscribe((data) => {
        this.rexOrderForm.orderFormId = data;
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.error,
            'Success Message',
            'Data Saved Successfully!'
          )
        );
      });
    this.ejDialogSaveOrderLayout.hide();
  }
  public onQuickAddItemDialogCloseButtonClick(): void {
    this.showProductBrands = false;
    this.showProductFamilies = false;
    this.showProductLines = false;
    this.showItems = false;
    this.hideCards = false;
    this.selectedProductBrand = [];
    this.selectedProductLine = [];
    this.selectedProductFamily = [];
    this.quickAddOption = QuickAddOption.unknown;
    this.ejDialogQuickAddItem.hide();
  }
  public onClickAddProductBrandButton(): void {
    this.quickAddOption = QuickAddOption.productBrand;
    this.getAllBrands();
    this.hideCards = true;
    this.showProductBrands = true;
    this.showProductLines = false;
    this.showProductFamilies = false;
    this.showItems = false;
  }
  public onClickAddProductLineButton(): void {
    this.quickAddOption = QuickAddOption.productLine;
    this.getAllBrands();
    this.hideCards = true;
    this.showProductLines = true;
    this.showProductBrands = true;
    this.showProductFamilies = false;
    this.showItems = false;
  }
  public onClickAddProductFamilyButton(): void {
    this.quickAddOption = QuickAddOption.productFamily;
    this.getAllBrands();
    this.hideCards = true;
    this.showProductFamilies = true;
    this.showProductLines = true;
    this.showProductBrands = true;
    this.showItems = false;
  }
  public onClickAddItemButton(): void {
    this.quickAddOption = QuickAddOption.item;
    this.getAllBrands();
    this.hideCards = true;
    this.showItems = true;
    this.showProductLines = true;
    this.showProductBrands = true;
    this.showProductFamilies = true;
  }
  public onQuickAddItemDialogBackButtonClick(): void {
    this.hideCards = false;
    this.showProductBrands = false;
    this.showProductFamilies = false;
    this.showProductLines = false;
    this.showItems = false;
    this.selectedProductBrand = [];
    this.selectedProductLine = [];
    this.selectedProductFamily = [];
    this.quickAddOption = QuickAddOption.unknown;
  }
}
