<div>
  <h1>Salesforce to JDE</h1>
  <p>
    Used for taking paid or unpaid, completed orders from Salesforce to JDE.
  </p>
</div>

<div class="e-card" style="order: 2; flex: 33; min-width: 500px">
  <div class="e-card-content">
    <div
      style="
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;
        justify-content: space-between;
      "
    >
      <div
        style="
          order: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        "
      >
        <h2 style="order: 1">Failed Orders</h2>
      </div>

      <div
        style="
          order: 2;
          display: inline-block;
          display: flex;
          flex-direction: row;
        "
      >
        <p style="order: 1">Count</p>
        <span
          class="e-badge e-badge-info"
          style="order: 2; height: 17px; font-size: 12px"
          >{{ repairOrderRequests.length }}</span
        >
      </div>
    </div>
    <ejs-grid
      id="RepairOrderGrid"
      #RepairOrderGrid
      [dataSource]="repairOrderRequests"
      height="500px"
      width="100%"
      enableStickyHeader="true"
      [allowResizing]="true"
      gridLines="Default"
      [allowSorting]="true"
      [loadingIndicator]="loadingIndicator"
      allowResizing="false"
      searchSettings=""
      allowSearch="true"
      [searchSettings]="searchOptions"
      [allowTextWrap]="true"
      [toolbar]="toolbarUserActivityGrid"
    >
      <e-columns>
        <e-column
          field="sfLineId"
          headerText="WRI Id"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="sentProduct"
          headerText="Sent Product"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="caseNumber"
          headerText="Case Number"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="errorMessage"
          headerText="Error Message"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field=""
          headerText="Retry"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
        >
          <ng-template #template let-data>
            <button
              ejs-button
              style="padding-left: 10px; padding-right: 10px"
              cssClass="e-success"
              (click)="onClickRetryButton(data)"
            >
              Retry
            </button>
          </ng-template></e-column
        >
      </e-columns>
    </ejs-grid>
  </div>
</div>
