import { AppMetadata } from './app-metadata.model';

export class User {
  public user_id?: string;
  public name?: string; // CompanyName
  public username?: string;
  public given_name?: string; //First Name
  public family_name?: string; // Last Name
  public email?: string;
  public app_metadata?: AppMetadata;
  public blocked?: boolean;
  public email_verified?: boolean;
  public picture?: string;
  public last_login?: Date;
  public last_ip?: string;
  public logins_count?: number;
  public readonly connection: string = 'Username-Password-Authentication';

  constructor(
    user_id?: string,
    name?: string,
    username?: string,
    given_name?: string,
    family_name?: string,
    email?: string,
    app_metadata?: AppMetadata,
    blocked?: boolean,
    email_verified?: boolean,
    picture?: string,
    last_login?: Date,
    last_ip?: string,
    logins_count?: number
  ) {
    this.user_id = user_id;
    this.name = name;
    this.username = username;
    this.given_name = given_name;
    this.family_name = family_name;
    this.email = email;
    this.app_metadata = app_metadata;
    this.blocked = blocked;
    this.email_verified = email_verified;
    this.picture = picture;
    this.last_login = last_login;
    this.last_ip = last_ip;
    this.logins_count = logins_count;
  }
}
