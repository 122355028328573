import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function FieldMatchValidatior(
  field1: string,
  field2: string
): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const control1 = form.get(field1);
    const control2 = form.get(field2);

    if (!control1 || !control2) {
      return null;
    }

    if (control2.errors && !control2.errors['passwordMismatch']) {
      return null;
    }

    if (control1.value !== control2.value) {
      control2.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      control2.setErrors(null);
      return null;
    }
  };
}
