// sales-force.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-sales-force',
  templateUrl: './sales-force.component.html',
  styleUrls: ['./sales-force.component.scss'],
})
export class SalesForceComponent {
  public selectedItemValue: number = 1;

  public onSalesforceItemSelected(selectedItemId: number): void {
    this.selectedItemValue = selectedItemId;
  }
}
