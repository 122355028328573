<div style="display: flex; min-height: 25vh; margin-top: 5%">
  <div
    class="e-card"
    routerLink="/user-management"
    [ngClass]="{ 'is-preload': onloadBoolean1 }"
  >
    <div class="e-card-content">
      <a routerLink="/user-management">User Management</a>
    </div>
  </div>
  <div
    class="e-card"
    routerLink="/portal-management"
    [ngClass]="{ 'is-preload': onloadBoolean2 }"
  >
    <div class="e-card-content">
      <a routerLink="/portal-management">Portal Management</a
      ><span class="e-badge e-badge-warning" style="margin-left: 10px"
        >Beta</span
      >
    </div>
  </div>
  <div
    class="e-card"
    routerLink="/sales-force"
    [ngClass]="{ 'is-preload': onloadBoolean3 }"
  >
    <div class="e-card-content">
      <a routerLink="/sales-force">Shopify</a
      ><span class="e-badge e-badge-warning" style="margin-left: 10px"
        >Beta</span
      >
    </div>
  </div>
</div>
<div style="display: flex; min-height: 25vh; margin-top: 5%">
  <div
    class="e-card"
    routerLink="/iplanner"
    [ngClass]="{ 'is-preload': onloadBoolean4 }"
  >
    <div class="e-card-content">
      <a routerLink="/iplanner">IPlanner</a
      ><span class="e-badge e-badge-warning" style="margin-left: 10px"
        >Beta</span
      >
    </div>
  </div>
  <div
    class="e-card"
    routerLink="/eagle-creek"
    [ngClass]="{ 'is-preload': onloadBoolean5 }"
  >
    <div class="e-card-content">
      <a routerLink="/sales-force">Eagle Creek</a
      ><span class="e-badge e-badge-warning" style="margin-left: 10px"
        >Beta</span
      >
    </div>
  </div>
  <div
    class="e-card"
    routerLink="/order-form-layout"
    [ngClass]="{ 'is-preload': onloadBoolean6 }"
  >
    <div class="e-card-content">
      <a routerLink="/order-form-layout">Order Form Layout</a
      ><span class="e-badge e-badge-warning" style="margin-left: 10px"
        >Beta</span
      >
    </div>
  </div>
</div>
