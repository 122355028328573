export class OrderModificationSettings {
  public id?: number;
  public dateCreated?: Date;
  public companyCode?: string;
  public numberOfDaysPriorToPickDate?: number;
  public dateModified?: Date;
  public createdOrModifiedBy?: string;
  public rowver?: Date;

  constructor(
    id?: number,
    dateCreated?: Date,
    companyCode?: string,
    numberOfDaysPriorToPickDate?: number,
    dateModified?: Date,
    createdOrModifiedBy?: string,
    rowver?: Date
  ) {
    this.id = id;
    this.dateCreated = dateCreated;
    this.companyCode = companyCode;
    this.numberOfDaysPriorToPickDate = numberOfDaysPriorToPickDate;
    this.dateModified = dateModified;
    this.createdOrModifiedBy = createdOrModifiedBy;
    this.rowver = rowver;
  }
}
