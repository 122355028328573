import { DtoChildObject } from './dto-child-object.model';
import { DtoRexOrderForm } from './dto-rex-order-form.model';

export class DtoOrderForm {
  public rexOrderForm?: DtoRexOrderForm;
  public childObjects: DtoChildObject[];
  constructor(childObjects: DtoChildObject[], rexOrderForm?: DtoRexOrderForm) {
    this.rexOrderForm = rexOrderForm;
    this.childObjects = childObjects;
  }
}
