import { Component, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {
  SidebarComponent,
  TreeViewComponent,
} from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent {
  constructor(private viewportScroller: ViewportScroller) {}
  // public scrollToAnchor(elementId: string): void {
  //   this.viewportScroller.scrollToAnchor(elementId);
  // }

  @ViewChild('sidebarTreeviewInstance')
  public sidebarTreeviewInstance?: SidebarComponent;
  @ViewChild('treeviewInstance')
  public treeviewInstance?: TreeViewComponent;
  public width: string = '290px';
  public enableDock: boolean = true;
  public dockSize: string = '44px';
  public mediaQuery: string = '(min-width: 600px)';
  public target: string = '.main-content';
  public selectedItem: any = null;

  // Handle sidebar item click
  public onSidebarItemClick(item: any): void {
    this.selectedItem = item.node.innerText;
    debugger;
  }
  public data: Object[] = [
    {
      nodeId: '01',
      nodeText: 'User Management',
      // iconCss: 'icon-people icon',
      iconCss: 'icon-circle-thin icon',

      // iconCss: 'e-icons e-people',
      nodeChild: [
        {
          nodeId: '01-01',
          nodeText: 'User Search',
        },
        {
          nodeId: '01-02',
          nodeText: 'Failed Logins',
        },
        {
          nodeId: '01-03',
          nodeText: 'Blocked Users',
        },
        {
          nodeId: '01-04',
          nodeText: 'Active Users',
        },
      ],
    },
    {
      nodeId: '02',
      nodeText: 'Portal Management',
      iconCss: 'icon-th icon',
      nodeChild: [
        {
          nodeId: '02-01',
          nodeText: 'Geo Location',
        },
        {
          nodeId: '02-02',
          nodeText: 'Server Metrics',
        },
        {
          nodeId: '02-03',
          nodeText: 'Event Log',
        },
        {
          nodeId: '02-04',
          nodeText: 'Order Minimum',
        },
        {
          nodeId: '02-05',
          nodeText: 'Order Modification Settings',
        },
        {
          nodeId: '02-06',
          nodeText: 'Report Configuration',
        },
        {
          nodeId: '02-07',
          nodeText: 'Email Delivery',
        },
      ],
    },
    {
      nodeId: '03',
      nodeText: 'Order Form Layout',
      iconCss: 'icon-doc-text icon',
      nodeChild: [
        {
          nodeId: '03-01',
          nodeText: 'New Order Form',
        },
        {
          nodeId: '03-02',
          nodeText: 'Edit Order Form',
        },
        {
          nodeId: '03-03',
          nodeText: 'Save Order Form',
        },
        {
          nodeId: '03-04',
          nodeText: 'Publish Order Form',
        },
        {
          nodeId: '03-05',
          nodeText: 'Clone Order Form',
        },
        {
          nodeId: '03-06',
          nodeText: 'Quick Add',
        },
      ],
    },
    {
      nodeId: '04',
      nodeText: 'Shopify',
      iconCss: 'icon-microchip icon',
      nodeChild: [
        {
          nodeId: '04-01',
          nodeText: 'SalesForce to JDE',
        },
        {
          nodeId: '04-02',
          nodeText: 'Shopify 2',
        },
      ],
    },
    {
      nodeId: '05',
      nodeText: 'IPlanner',
      iconCss: 'icon-docs icon',
      nodeChild: [
        {
          nodeId: '05-01',
          nodeText: 'New',
        },
        {
          nodeId: '05-02',
          nodeText: 'Edit',
        },
      ],
    },
    {
      nodeId: '06',
      nodeText: 'Eagle Creek',
      iconCss: 'icon-code icon',
      nodeChild: [
        {
          nodeId: '06-01',
          nodeText: 'New ',
        },
        {
          nodeId: '06-02',
          nodeText: 'Edit',
        },
      ],
    },
  ];
  public field: Object = {
    dataSource: this.data,
    id: 'nodeId',
    text: 'nodeText',
    child: 'nodeChild',
    iconCss: 'iconCss',
  };

  public onCreated(args: any) {
    (
      this.sidebarTreeviewInstance as SidebarComponent
    ).element.style.visibility = '';
  }
  public onClose(args: any) {
    (this.treeviewInstance as TreeViewComponent).collapseAll();
  }
  openClick() {
    if ((this.sidebarTreeviewInstance as SidebarComponent).isOpen) {
      (this.sidebarTreeviewInstance as SidebarComponent).hide();
      (this.treeviewInstance as TreeViewComponent).collapseAll();
    } else {
      (this.sidebarTreeviewInstance as SidebarComponent).show();
      (this.treeviewInstance as TreeViewComponent).expandAll();
    }
  }
}
