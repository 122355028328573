import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CdiAppMonitorService {
  private appInsights: ApplicationInsights;
  private angularPlugin = new AngularPlugin();

  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights.instrumentationKey,
        extensions: [this.angularPlugin],
        extensionConfig: {
          [this.angularPlugin.identifier]: {
            router: this.router,
          },
        },
        enableAutoRouteTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
  }

  public logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  public logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  public logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  public logException(exception: Error, severityLevel?: number) {
    try {
      this.appInsights.trackException({
        exception: exception,
        severityLevel: severityLevel,
      });
    } catch (error) {
      console.error('logException', error);
    }
  }

  public logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
