import { Component, ViewChild } from '@angular/core';
import {
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import {
  ContextMenuComponent,
  MenuEventArgs,
  MenuItemModel,
  SidebarComponent,
} from '@syncfusion/ej2-angular-navigations';
import { User } from 'src/app/shared/model/user.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { ReportConfiguration } from './model/report-configuration.model';
import { ReportConfigurationDetails } from './model/report-configuration-details.model';
import { ReportColumnConfigurationDataType } from './model/report-column-configuration-data-type.model';
import { ReportColumnConfigurationFormat } from './model/report-column-configuration-format.model';
import { ReportColumnConfigurationTextAlign } from './model/report-column-configuration-text-align.model';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { CacheService } from 'src/app/services/cache.service';
import { ReportAccessibleBy } from './model/report-accessible-by.model';

@Component({
  selector: 'app-report-configuration',
  templateUrl: './report-configuration.component.html',
  styleUrls: ['./report-configuration.component.scss'],
})
export class ReportConfigurationComponent {
  @ViewChild('reportConfigurationGrid')
  public reportConfigurationGrid!: GridComponent;
  @ViewChild('ejDialogAddReportConfiguration')
  public ejDialogAddReportConfiguration!: DialogComponent;
  @ViewChild('ejDialogAddReportColumnConfiguration')
  public ejDialogAddReportColumnConfiguration!: DialogComponent;
  @ViewChild('ejDialogEditReportConfiguration')
  public ejDialogEditReportConfiguration!: DialogComponent;
  @ViewChild('ejDialogEditReportColumnConfiguration')
  public ejDialogEditReportColumnConfiguration!: DialogComponent;
  @ViewChild('ejDialogSettings')
  public ejDialogSettings!: DialogComponent;
  @ViewChild('ejDialogAddOrder') ejDialogAddOrder!: DialogComponent;
  @ViewChild('ejDialogCloneOrder') ejDialogCloneOrder!: DialogComponent;
  @ViewChild('ejDialogEditUser') ejDialogEditUser!: DialogComponent;
  @ViewChild('contextMenu') public contextMenu!: ContextMenuComponent;
  @ViewChild('dialogReportConfigurationDetails')
  dialogReportConfigurationDetails!: DialogComponent;
  @ViewChild('rightSidebar') rightSidebar!: SidebarComponent;
  @ViewChild('rightSidebarDetails') rightSidebarDetails!: SidebarComponent;
  @ViewChild('togglebtn') togglebtn!: ButtonComponent;
  public reportConfigurationList: ReportConfiguration[] = [];
  public editReportConfigurationData: any;
  public editReportColumnConfigurationData: any;
  public reportAccessibleByList: ReportAccessibleBy[] = [];
  public reportConfigurationDetailsList: ReportConfigurationDetails[] = [];
  public reportConfigDataType: ReportColumnConfigurationDataType[] = [];
  public reportConfigFormat: ReportColumnConfigurationFormat[] = [];
  public reportConfigTextAlign: ReportColumnConfigurationTextAlign[] = [];
  public selectedRowData: ReportConfiguration[] = [];
  public selectedId: any;

  public width: string = '250px';
  public position: string = 'Right';
  public type: string = 'Over';
  public closeOnDocumentClick: any;

  public isActive: boolean = true;
  public showPerformaCheckBox: boolean = true;
  public showBeginDate: boolean = true;
  public showEndDate: boolean = true;
  public branchPlantRequired: boolean = true;
  public showYearDropDown: boolean = true;
  public showProductLineDropDown: boolean = true;
  public isAccountNumberRequired: boolean = true;
  public isCommissionCodeRequired: boolean = true;

  public allowSorting: boolean = true;
  public allowFiltering: boolean = true;
  public allowGrouping: boolean = true;
  public isPrimaryKey: boolean = true;
  public autofit: boolean = true;

  public userSwitch: any;
  public userStatusSwitchValue: boolean = true;
  public settings: string[] = [];
  public showFormatGrid: boolean = false;
  public showTextAlignGrid: boolean = false;
  public showDataTypeGrid: boolean = false;

  // Declared  Variables
  public dialogTitle: string;
  public user: User;
  public dateCreatedValue = new Date();
  public selectedFormat: string = '';
  public selectedDataType: string = '';
  public selectedTextAlign: string = '';
  public selectedReportAccessibleBy: number = 0;
  public dialogUserStatusWarning: string = '';
  public isUserStatusDialogNoButtonClicked: boolean = false;
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };
  public toolbarReportConfigurationGrid?: ToolbarItems[] | object;
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public enableRowHover: boolean = false;
  public dropdownlistOrderYearSearchFields: Object = {
    text: 'orderYear',
    value: 'orderYear',
  };

  public dropdownlistDataTypeFields: Object = {
    text: 'typeData',
    value: 'typeData',
  };
  public dropdownlistFormatFields: Object = {
    text: 'format',
    value: 'format',
  };
  public dropdownlistTextAlignFields: Object = {
    text: 'textAlign',
    value: 'textAlign',
  };
  public dropdownlistreportAccessibleByListFields: Object = {
    text: 'description',
    value: 'description',
  };
  public rightToggle(data: any) {
    this.isActive = data.isActive;
    this.showPerformaCheckBox = data.showPerformaCheckBox;
    this.showBeginDate = data.showBeginDate;
    this.showEndDate = data.showEndDate;
    this.branchPlantRequired = data.branchPlantRequired;
    this.showYearDropDown = data.showYearDropDown;
    this.showProductLineDropDown = data.showProductLineDropDown;
    this.isAccountNumberRequired = data.isAccountNumberRequired;
    this.isCommissionCodeRequired = data.isCommissionCodeRequired;
    this.rightSidebar?.toggle();
  }

  public rightToggleDetails(data: any) {
    this.allowSorting = data.allowSorting;
    this.allowFiltering = data.allowFiltering;
    this.allowGrouping = data.allowGrouping;
    this.isPrimaryKey = data.isPrimaryKey;
    this.autofit = data.autofit;
    this.isActive = data.isActive;
    this.rightSidebarDetails?.toggle();
  }
  public showClearSearchButton: boolean;

  constructor(
    private dataService: DataserviceService,
    private _cache: CacheService
  ) {
    this.showClearSearchButton = false;
    this.dialogTitle = '';
    this.user = new User();
  }

  ngOnInit(): void {
    this.toolbarReportConfigurationGrid = ['ColumnChooser', 'Search'];
    this.getReportConfiguration();
    this.getReportAccessibleBy();
    this.getReportConfigurationGetReportColumnConfigurationDataType();
    this.getReportConfigurationGetReportColumnConfigurationFormat();
    this.getReportConfigurationGetReportColumnConfigurationTextAlign();

    this.settings = ['Format', 'Text Align', 'Data Type'];
  }
  public onSelectSettings(event: any): void {
    this.showFormatGrid = false;
    this.showTextAlignGrid = false;
    this.showDataTypeGrid = false;
    if (event.itemData.text === 'Format') {
      this.showFormatGrid = true;
    } else if (event.itemData.text === 'Text Align') {
      this.showTextAlignGrid = true;
    } else if (event.itemData.text === 'Data Type') {
      this.showDataTypeGrid = true;
    }
    this.ejDialogSettings.show();
  }
  public onReportConfigurationGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }
  public menuItems: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'Column Config',
          id: 'columnConfig',
          iconCss: 'e-icons e-clock',
        },
        {
          text: 'Edit',
          id: 'edit',
          iconCss: 'e-icons e-edit',
        },
        {
          text: 'Settings',
          id: 'settings',
          iconCss: 'e-icons e-settings',
        },
      ],
    },
  ];
  public onReportConfigurationContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'columnConfig':
          this.showDetails(this.selectedRowData);
          break;
        case 'settings':
          this.rightToggle(this.selectedRowData);
          break;
        case 'edit':
          this.editReportConfiguration(this.selectedRowData);
          break;
      }
    }
  }
  public menuItemsColumnConfig: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'Edit',
          id: 'edit',
          iconCss: 'e-icons e-edit',
        },
        {
          text: 'Settings',
          id: 'settings',
          iconCss: 'e-icons e-settings',
        },
      ],
    },
  ];
  public onReportColumnConfigurationContextMenuSelect(
    event: MenuEventArgs
  ): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'settings':
          this.rightToggleDetails(this.selectedRowData);
          break;
        case 'edit':
          this.editReportColumnConfiguration(this.selectedRowData);
          break;
      }
    }
  }

  public onClickAddButton(): void {
    this.ejDialogAddReportConfiguration.show();
  }
  public onClickAddReportColumnConfigButton(): void {
    this.ejDialogAddReportColumnConfiguration.show();
  }
  public onUserStatusSwitchIsActiveToggle(event: any) {
    this.isActive = event.checked;
  }
  public onUserStatusSwitchShowPerformaCheckBoxToggle(event: any) {
    this.showPerformaCheckBox = event.checked;
  }
  public onUserStatusSwitchShowBeginDateToggle(event: any) {
    this.showBeginDate = event.checked;
  }
  public onUserStatusSwitchShowEndDateToggle(event: any) {
    this.showEndDate = event.checked;
  }
  public onUserStatusSwitchBranchPlantRequiredToggle(event: any) {
    this.branchPlantRequired = event.checked;
  }
  public onUserStatusSwitchShowYearDropDownToggle(event: any) {
    this.showYearDropDown = event.checked;
  }
  public onUserStatusSwitchShowProductLineDropDownToggle(event: any) {
    this.showProductLineDropDown = event.checked;
  }
  public onUserStatusSwitchIsAccountNumberRequiredToggle(event: any) {
    this.isAccountNumberRequired = event.checked;
  }
  public onUserStatusSwitchIsCommissionCodeRequiredToggle(event: any) {
    this.isActive = event.checked;
  }
  public onUserStatusSwitchAllowSortingToggle(event: any) {
    this.allowSorting = event.checked;
  }
  public onUserStatusSwitchAllowFilteringToggle(event: any) {
    this.allowFiltering = event.checked;
  }
  public onUserStatusSwitchAllowGroupingToggle(event: any) {
    this.allowGrouping = event.checked;
  }
  public onUserStatusSwitchIsPrimaryKeyToggle(event: any) {
    this.isPrimaryKey = event.checked;
  }
  public onUserStatusSwitchAutofitToggle(event: any) {
    this.autofit = event.checked;
  }
  // Gets for Report Config forms
  get name() {
    return this.addReportConfigurationForm.get('name');
  }
  get description() {
    return this.addReportConfigurationForm.get('description');
  }
  get apiEndPoint() {
    return this.addReportConfigurationForm.get('apiEndPoint');
  }
  get reportAccessibleBy() {
    return this.addReportConfigurationForm.get('reportAccessibleBy');
  }
  get field() {
    return this.addReportColumnConfigurationForm.get('field');
  }
  get headerText() {
    return this.addReportColumnConfigurationForm.get('headerText');
  }
  get textAlign() {
    return this.addReportColumnConfigurationForm.get('textAlign');
  }
  get format() {
    return this.addReportColumnConfigurationForm.get('format');
  }
  get dataType() {
    return this.addReportColumnConfigurationForm.get('typeData');
  }
  get widthValue() {
    return this.addReportColumnConfigurationForm.get('width');
  }
  public addReportConfigurationForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    apiEndPoint: new FormControl('', [Validators.required]),
    reportAccessibleBy: new FormControl('', [Validators.required]),
    isActive: new FormControl(''),
    showPerformaCheckBox: new FormControl(''),
    showBeginDate: new FormControl(''),
    showEndDate: new FormControl(''),
    branchPlantRequired: new FormControl(''),
    showYearDropDown: new FormControl(''),
    showProductLineDropDown: new FormControl(''),
    isAccountNumberRequired: new FormControl(''),
    isCommissionCodeRequired: new FormControl(''),
  });
  public addReportColumnConfigurationForm = new FormGroup({
    field: new FormControl('', [Validators.required, Validators.minLength(3)]),
    headerText: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    textAlign: new FormControl('', [Validators.required]),
    format: new FormControl('', [Validators.required]),
    dataType: new FormControl(''),
    width: new FormControl(''),
    isActive: new FormControl(''),
    allowSorting: new FormControl(''),
    allowFiltering: new FormControl(''),
    allowGrouping: new FormControl(''),
    isPrimaryKey: new FormControl(''),
    autofit: new FormControl(''),
  });
  public editReportConfiguration(data: any) {
    this.editReportConfigurationData = data;
    this.ejDialogEditReportConfiguration.show();
  }
  public editReportColumnConfiguration(data: any) {
    this.editReportColumnConfigurationData = data;
    this.ejDialogEditReportColumnConfiguration.show();
  }
  public onSaveAddReportConfigurationButtonClick() {
    let name = this.addReportConfigurationForm.get('name')?.value?.toString();
    let description = this.addReportConfigurationForm
      .get('description')
      ?.value?.toString();
    let apiEndPoint = this.addReportConfigurationForm
      .get('apiEndPoint')
      ?.value?.toString();
    let reportAccessibleByDescription = this.addReportConfigurationForm.get(
      'reportAccessibleByDescription'
    )?.value;

    const addReportConfig = new ReportConfiguration(
      0,
      new Date(),
      new Date(),
      name,
      description,
      this.selectedReportAccessibleBy,
      reportAccessibleByDescription,
      this.isActive,
      this.showPerformaCheckBox,
      this.showBeginDate,
      this.showEndDate,
      this.branchPlantRequired,
      apiEndPoint,
      this.showYearDropDown,
      this.showProductLineDropDown,
      this.isAccountNumberRequired,
      this.isCommissionCodeRequired
    );

    this.dataService
      .add<any>(
        `ReportConfiguration/AddReportConfiguration/${name}/${description}/${apiEndPoint}/${this.selectedReportAccessibleBy}/${this.isActive}/${this.showPerformaCheckBox}/${this.showBeginDate}/${this.showEndDate}/${this.branchPlantRequired}/${this.showYearDropDown}/${this.showProductLineDropDown}/${this.isAccountNumberRequired}/${this.isCommissionCodeRequired}`,
        addReportConfig
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Create Report Configuration',
            `Report Configuration created successfully`
          )
        );
        this.ejDialogAddReportConfiguration.hide();
      });
  }
  public onSaveEditReportConfigurationButtonClick() {
    let name = this.addReportConfigurationForm.get('name')?.value?.toString();
    if (name == '') {
      name = this.editReportConfigurationData.name;
    }
    let description = this.addReportConfigurationForm
      .get('description')
      ?.value?.toString();
    if (description == '') {
      description = this.editReportConfigurationData.description;
    }
    let apiEndPoint = this.addReportConfigurationForm
      .get('apiEndPoint')
      ?.value?.toString();
    if (apiEndPoint == '') {
      apiEndPoint = this.editReportConfigurationData.apiEndPoint;
    }

    let reportAccessibleByDescription = this.addReportConfigurationForm.get(
      'reportAccessibleByDescription'
    )?.value;
    if (this.selectedReportAccessibleBy == 0) {
      this.selectedReportAccessibleBy =
        this.editReportConfigurationData.reportAccessibleById;
    }
    let isActive = Boolean(this.editReportConfigurationData.isActive);
    if (isActive !== this.isActive) {
      isActive = this.isActive;
    }
    let showPerformaCheckBox = Boolean(
      this.editReportConfigurationData.showPerformaCheckBox
    );
    if (showPerformaCheckBox !== this.showPerformaCheckBox) {
      showPerformaCheckBox = this.showPerformaCheckBox;
    }
    let showBeginDate = Boolean(this.editReportConfigurationData.showBeginDate);
    if (showBeginDate !== this.showBeginDate) {
      showBeginDate = this.showBeginDate;
    }
    let showEndDate = Boolean(this.editReportConfigurationData.showEndDate);
    if (showEndDate !== this.showEndDate) {
      showEndDate = this.showEndDate;
    }
    let branchPlantRequired = Boolean(
      this.editReportConfigurationData.branchPlantRequired
    );
    if (branchPlantRequired !== this.branchPlantRequired) {
      branchPlantRequired = this.branchPlantRequired;
    }
    let showProductLineDropDown = Boolean(
      this.editReportConfigurationData.showProductLineDropDown
    );
    if (showProductLineDropDown !== this.showProductLineDropDown) {
      showProductLineDropDown = this.showProductLineDropDown;
    }
    let showYearDropDown = Boolean(
      this.editReportConfigurationData.showYearDropDown
    );
    if (showYearDropDown !== this.showYearDropDown) {
      showYearDropDown = this.showYearDropDown;
    }
    let isAccountNumberRequired = Boolean(
      this.editReportConfigurationData.isAccountNumberRequired
    );
    if (isAccountNumberRequired !== this.isAccountNumberRequired) {
      isAccountNumberRequired = this.isAccountNumberRequired;
    }
    let isCommissionCodeRequired = Boolean(
      this.editReportConfigurationData.isCommissionCodeRequired
    );
    if (isCommissionCodeRequired !== this.isCommissionCodeRequired) {
      isCommissionCodeRequired = this.isCommissionCodeRequired;
    }
    // let showPerformaCheckBox = Boolean(
    //   this.addReportConfigurationForm.get('showPerformaCheckBox')?.value
    // );
    // let showBeginDate = Boolean(
    //   this.addReportConfigurationForm.get('showBeginDate')?.value
    // );
    // let showEndDate = Boolean(
    //   this.addReportConfigurationForm.get('showEndDate')?.value
    // );
    // let branchPlantRequired = Boolean(
    //   this.addReportConfigurationForm.get('branchPlantRequired')?.value
    // );
    // let showProductLineDropDown = Boolean(
    //   this.addReportConfigurationForm.get('showProductLineDropDown')?.value
    // );
    // let showYearDropDown = Boolean(
    //   this.addReportConfigurationForm.get('showYearDropDown')?.value
    // );
    // let isAccountNumberRequired = Boolean(
    //   this.addReportConfigurationForm.get('isAccountNumberRequired')?.value
    // );
    // let isCommissionCodeRequired = Boolean(
    //   this.addReportConfigurationForm.get('isCommissionCodeRequired')?.value
    // );
    const updateReportConfig = new ReportConfiguration(
      0,
      new Date(),
      new Date(),
      name,
      description,
      this.selectedReportAccessibleBy,
      reportAccessibleByDescription,
      isActive,
      showPerformaCheckBox,
      showBeginDate,
      showEndDate,
      branchPlantRequired,
      apiEndPoint,
      showYearDropDown,
      this.showProductLineDropDown,
      this.isAccountNumberRequired,
      this.isCommissionCodeRequired
    );
    this.dataService
      .update<any>(
        `ReportConfiguration/UpdateReportConfiguration/${this.editReportConfigurationData.id}/${name}/${description}/${apiEndPoint}/${this.selectedReportAccessibleBy}/${isActive}/${showPerformaCheckBox}/${showBeginDate}/${showEndDate}/${branchPlantRequired}/${showYearDropDown}/${showProductLineDropDown}/${isAccountNumberRequired}/${isCommissionCodeRequired}`,
        updateReportConfig
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Edit Report Configuration',
            `Report Configuration Edited successfully`
          )
        );
        this.ejDialogEditReportConfiguration.hide();
        this.reportConfigurationGrid.refresh();
      });
  }
  public onSaveAddReportColumnConfigurationButtonClick() {
    let field = this.addReportColumnConfigurationForm
      .get('field')
      ?.value?.toString();
    let headerText = this.addReportColumnConfigurationForm
      .get('headerText')
      ?.value?.toString();
    let width = this.addReportColumnConfigurationForm
      .get('width')
      ?.value?.toString();

    this._cache.selectedReportConfigurationId.subscribe((id) => {
      this.selectedId = id;
    });
    const addReportColumnConfig = new ReportConfigurationDetails(
      0,
      new Date(),
      new Date(),
      this.selectedId,
      field,
      headerText,
      this.selectedTextAlign,
      width,
      this.selectedFormat,
      this.allowSorting,
      this.allowFiltering,
      this.allowGrouping,
      this.isActive,
      this.isPrimaryKey,
      this.selectedDataType,
      this.autofit
    );
    this.dataService
      .add<any>(
        `ReportConfiguration/AddReportColumnConfiguration/${this.selectedId}/${field}/${headerText}/${this.selectedTextAlign}/${width}/${this.selectedFormat}/${this.allowSorting}/${this.allowFiltering}/${this.allowGrouping}/${this.isActive}/${this.isPrimaryKey}/${this.selectedDataType}/${this.autofit}`,
        addReportColumnConfig
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Create Report Configuration',
            `Report Configuration created successfully`
          )
        );
        this.ejDialogAddReportColumnConfiguration.hide();
      });
  }
  public onSaveEditReportColumnConfigurationButtonClick() {
    let field = this.addReportColumnConfigurationForm
      .get('field')
      ?.value?.toString();
    if (field == '') {
      field = this.editReportColumnConfigurationData.field;
    }
    let headerText = this.addReportColumnConfigurationForm
      .get('headerText')
      ?.value?.toString();
    if (headerText == '') {
      headerText = this.editReportColumnConfigurationData.headerText;
    }
    let width = this.addReportColumnConfigurationForm
      .get('width')
      ?.value?.toString();
    if (width == '') {
      width = this.editReportColumnConfigurationData.width;
    }
    if (this.selectedTextAlign == '') {
      this.selectedTextAlign = this.editReportColumnConfigurationData.textAlign;
    }
    if (this.selectedFormat == '') {
      this.selectedFormat = this.editReportColumnConfigurationData.format;
    }
    if (this.selectedDataType == '') {
      this.selectedDataType = this.editReportColumnConfigurationData.dataType;
    }
    this._cache.selectedReportConfigurationId.subscribe((id) => {
      this.selectedId = id;
    });
    const editReportColumnConfig = new ReportConfigurationDetails(
      0,
      new Date(),
      new Date(),
      this.selectedId,
      field,
      headerText,
      this.selectedTextAlign,
      width,
      this.selectedFormat,
      this.allowSorting,
      this.allowFiltering,
      this.allowGrouping,
      this.isActive,
      this.isPrimaryKey,
      this.selectedDataType,
      this.autofit
    );
    this.dataService
      .update<any>(
        `ReportConfiguration/UpdateReportColumnConfiguration/${this.editReportColumnConfigurationData.id}/${this.selectedId}/${field}/${headerText}/${this.selectedTextAlign}/${width}/${this.selectedFormat}/${this.allowSorting}/${this.allowFiltering}/${this.allowGrouping}/${this.isActive}/${this.isPrimaryKey}/${this.selectedDataType}/${this.autofit}`,
        editReportColumnConfig
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Edit Report Column Configuration',
            `Report Column Configuration Updated successfully`
          )
        );
        this.ejDialogEditReportColumnConfiguration.hide();
      });
  }
  closeClick() {
    this.rightSidebar.hide();
    this.rightSidebarDetails.hide();
  }
  public getReportConfiguration() {
    this.dataService
      .getAll<ReportConfiguration>(`ReportConfiguration/GetReportConfiguration`)
      .subscribe((data) => {
        this.reportConfigurationList = data;
      });
  }
  public getReportAccessibleBy() {
    this.dataService
      .getAll<ReportAccessibleBy>(`ReportConfiguration/GetReportAccessibleBy`)
      .subscribe((data) => {
        this.reportAccessibleByList = data;
      });
  }
  public showDetails(data: any): void {
    let id = data.id;
    this._cache.selectedReportConfigurationId.next(id);
    this.dataService
      .getAll<ReportConfigurationDetails>(
        `ReportConfiguration/GetReportColumnConfigurationDetails/${id}`
      )
      .subscribe((data) => {
        this.reportConfigurationDetailsList = data;
      });
    this.dialogReportConfigurationDetails.show();
  }
  public hide(): void {
    this.dialogReportConfigurationDetails.hide();
    this.ejDialogSettings.hide();
  }
  public onAddReportConfigurationDialogCloselButtonClick(): void {
    this.ejDialogAddReportConfiguration.hide();
  }
  public onAddReportColumnConfigurationDialogCloselButtonClick(): void {
    this.ejDialogAddReportColumnConfiguration.hide();
  }
  public onEditReportConfigurationDialogCloselButtonClick(): void {
    this.ejDialogEditReportConfiguration.hide();
  }
  public onEditReportColumnConfigurationDialogCloselButtonClick(): void {
    this.ejDialogEditReportColumnConfiguration.hide();
  }
  public getReportConfigurationGetReportColumnConfigurationDataType() {
    this.dataService
      .getAll<ReportColumnConfigurationDataType>(
        `ReportConfiguration/GetReportColumnConfigurationDataType`
      )
      .subscribe((data) => {
        this.reportConfigDataType = data;
      });
  }
  public getReportConfigurationGetReportColumnConfigurationFormat() {
    this.dataService
      .getAll<ReportColumnConfigurationFormat>(
        `ReportConfiguration/GetReportColumnConfigurationFormat`
      )
      .subscribe((data) => {
        this.reportConfigFormat = data;
      });
  }
  public getReportConfigurationGetReportColumnConfigurationTextAlign() {
    this.dataService
      .getAll<ReportColumnConfigurationTextAlign>(
        `ReportConfiguration/GetReportColumnConfigurationTextAlign`
      )
      .subscribe((data) => {
        this.reportConfigTextAlign = data;
      });
  }
  public onReportAccessibleByDropDownSelect(args: any): void {
    this.selectedReportAccessibleBy = args.itemData.id;
  }
  public onDataTypeDropDownSelect(args: any): void {
    this.selectedDataType = args.itemData.typeData;
  }
  public onFormatDropDownSelect(args: any): void {
    this.selectedFormat = args.itemData.format;
  }
  public onTextAlignDropDownSelect(args: any): void {
    this.selectedTextAlign = args.itemData.textAlign;
  }
  // *********************Switch Dialog Stuff *******************
  public onChangeUserStatusSwitchClick(
    event: any,
    userSwitch: any,
    data: User
  ) {
    const checked: boolean = event.checked;
    this.user = data;

    this.userSwitch = userSwitch;
    if (checked) {
      this.dialogUserStatusWarning = 'Enable';
      this.isUserStatusDialogNoButtonClicked = false;
    } else {
      this.dialogUserStatusWarning = 'Disable';
      this.isUserStatusDialogNoButtonClicked = true;
    }
  }
}
