export class EditUser {
  public given_name?: string; //First Name
  public family_name?: string; // Last Name
  public email?: string;

  constructor(given_name?: string, family_name?: string, email?: string) {
    this.given_name = given_name;
    this.family_name = family_name;
    this.email = email;
  }
}
