<div class="e-card">
  <div
    class="e-card-title"
    style="
      height: 30px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    "
  >
    Event Log
  </div>
  <div class="e-card-separator"></div>
  <div class="e-card-content">
    <div style="height: 400px; width: 100%">
      <ejs-grid
        id="cdi-api-log-grid"
        #apiLogGrid
        [dataSource]="apiLogList"
        height="100%"
        width="100%"
        enableStickyHeader="true"
        [allowResizing]="true"
        gridLines="Default"
        [allowSorting]="true"
        [allowFiltering]="true"
        [allowPaging]="false"
        allowTextWrap="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        [allowPdfExport]="true"
        [allowExcelExport]="true"
        [allowResizing]="true"
        [toolbar]="toolbarApiLogGrid"
        [showColumnChooser]="true"
        [enableHover]="enableRowHover"
      >
        <e-columns>
          <e-column
            field="id"
            headerText="Id"
            textAlign="Center"
            width="100"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
          <e-column
            field="eventTypeId"
            headerText="Event Type Id"
            textAlign="Center"
            width="100"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
          <e-column
            field="eventId"
            headerText="Event Id"
            textAlign="Center"
            width="200"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="datestamp"
            headerText="Datestamp"
            textAlign="Center"
            width="120"
            type="date"
            [allowFiltering]="false"
            [format]="dateFormatOption"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="username"
            headerText="Username"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="message"
            headerText="Message"
            textAlign="Center"
            width="200"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="url"
            headerText="URL"
            textAlign="Center"
            width="120"
            [visible]="false"
          ></e-column>
          <e-column
            field="orderNumber"
            headerText="Order Number"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
          <e-column
            field="accountNumber"
            headerText="Account Number"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="commissionCode"
            headerText="Commission Code"
            textAlign="Center"
            width="120"
            [visible]="false"
          ></e-column>
          <e-column
            field="companyCode"
            headerText="Company Code"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
          <e-column
            field="orderTypeId"
            headerText="OrderTypeId"
            textAlign="Center"
            width="100"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
          <e-column
            field="orderChangeTypeId"
            headerText="Order Change Type Id"
            textAlign="Center"
            width="100"
            [visible]="false"
          ></e-column>
          <e-column
            field="orderDataTypeId"
            headerText="Order DataType Id"
            textAlign="Center"
            width="100"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
          <e-column
            field="orderData"
            headerText="Order Data"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
          <e-column
            field="objectName"
            headerText="Object Name"
            textAlign="Center"
            width="120"
          ></e-column>
          <e-column
            field="method"
            headerText="Method"
            textAlign="Center"
            width="120"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>
