<div class="overlay" *ngIf="cache.showSpinner.value">
  <div class="center">
    <img
      src="../../../assets/img/Wave-1s-200px.svg"
      alt="loading..."
      height="200"
      width="200"
    />
  </div>
</div>
