export class ReportColumnConfigurationDataType {
  public dateCreated?: Date;
  public typeData?: string;
  public isActive?: boolean;
  public dateModified?: Date;
  public createdOrModifiedBy?: string;
  public rowver?: Date;

  constructor(
    dateCreated?: Date,
    typeData?: string,
    isActive?: boolean,
    dateModified?: Date,
    createdOrModifiedBy?: string,
    rowver?: Date
  ) {
    this.dateCreated = dateCreated;
    this.typeData = typeData;
    this.isActive = isActive;
    this.dateModified = dateModified;
    this.createdOrModifiedBy = createdOrModifiedBy;
    this.rowver = rowver;
  }
}
