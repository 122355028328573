import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataserviceService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.apiUrl;
  }
  public getAll<T>(endpoint: string): Observable<T[]> {
    return this.httpClient.get<T[]>(`${this._url}/${endpoint}`);
  }

  public getAllWithQueryParameters<T>(
    endpoint: string,
    queryParameters: string
  ): Observable<T[]> {
    const options = { params: new HttpParams({ fromString: queryParameters }) };
    return this.httpClient.get<T[]>(`${this._url}/${endpoint}`, options);
  }

  public getById<T>(endpoint: string, id: string): Observable<T> {
    return this.httpClient.get<T>(`${this._url}/${endpoint}/${id}`);
  }
  public get<T>(endpoint: string): Observable<T> {
    return this.httpClient.get<T>(`${this._url}/${endpoint}`);
  }
  public add<T>(endpoint: string, item: T): Observable<T> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post<T>(`${this._url}/${endpoint}`, item, {
      headers: headers,
    });
  }

  public update<T>(endpoint: string, item?: T): Observable<T> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put<T>(`${this._url}/${endpoint}`, item, {
      headers: headers,
    });
  }
  public deleteById(endpoint: string, id: string): Observable<unknown> {
    return this.httpClient.delete(`${this._url}/${endpoint}/${id}`);
  }
  public getCurrentDateInISO8601Format(): string {
    // yyyy-mm-dd
    const dt = new Date();
    const year = dt.getFullYear();
    const month = (dt.getMonth() + 1).toString().padStart(2, '0');
    const day = dt.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  public getISO8601Date(date: Date): string {
    // yyyy-mm-dd
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
