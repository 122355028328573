<!-- Add Order form Dialog -->
<ejs-dialog
  id="cdi-add-order-form-ejDialog"
  #ejDialogAddOrderForm
  [showCloseIcon]="true"
  header="Order Form"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="30vw"
  (close)="onAddOrderFormDialogCloseButtonClick()"
>
  <form [formGroup]="addOrderLayoutForm">
    <div>
      <label for="companyCode">Company code: </label
      ><ejs-dropdownlist
        id="companyCode"
        #dropdownCompanyCode
        [dataSource]="companycode"
        [fields]="dropdownFieldsCompanycode"
        placeholder="Select a company code"
        (select)="onCompanyCodeDropDownSelect($event)"
      ></ejs-dropdownlist>
      <div
        *ngIf="
          companyCode?.invalid && (companyCode?.dirty || companyCode?.touched)
        "
        class="form-error"
      >
        <div *ngIf="companyCode?.errors?.['required']">
          * Company code is required.
        </div>
      </div>
      <br /><br />
    </div>
    <div>
      <label for="orderFormType">Order Form Type: </label>
      <ejs-dropdownlist
        #ddlOrderFormType
        id="orderFormType"
        placeholder="Select Order Form Type"
        [dataSource]="orderFormTypes"
        [fields]="dropdownlistOrderFormTypeSearchFields"
        (select)="onOrderFormTypeDropDownSelect($event)"
        allowFiltering="true"
        showClearButton="true"
        [ignoreAccent]="true"
        [ignoreCase]="true"
        filterType="Contains"
        formControlName="orderFormType"
      ></ejs-dropdownlist>
      <div
        *ngIf="
          orderFormType?.invalid &&
          (orderFormType?.dirty || orderFormType?.touched)
        "
        class="form-error"
      >
        <div *ngIf="orderFormType?.errors?.['required']">
          * Order Form Type is required.
        </div>
      </div>
      <br /><br />
    </div>
    <div>
      <label for="branchPlant">Branch Plant: </label>
      <ejs-dropdownlist
        id="branchPlant"
        placeholder="Select Branch Plant"
        [dataSource]="branchPlants"
        [fields]="dropdownlistBranchPlantSearchFields"
        (select)="onBranchPlantDropDownSelect($event)"
        formControlName="branchPlant"
        allowFiltering="true"
        showClearButton="true"
        [ignoreAccent]="true"
        [ignoreCase]="true"
        filterType="Contains"
      ></ejs-dropdownlist>
      <div
        *ngIf="
          branchPlant?.invalid && (branchPlant?.dirty || branchPlant?.touched)
        "
        class="form-error"
      >
        <div *ngIf="branchPlant?.errors?.['required']">
          * Branch Plant is required.
        </div>
      </div>
      <br /><br />
    </div>
  </form>
  <button
    id="cdi-add-order-form-button"
    class="e-control e-btn e-success sendButton"
    data-ripple="true"
    (click)="onAddOrderFormButtonClick()"
  >
    Add
  </button>
  <!-- [disabled]="!addOrderLayoutForm.valid" -->
  <button
    id="cdi-add-order-form-cancel-button"
    class="e-control e-btn e-danger"
    data-ripple="true"
    (click)="onAddOrderFormDialogCloseButtonClick()"
  >
    Cancel
  </button>
</ejs-dialog>
<!-- Edit Order form priceGroup Dialog -->
<ejs-dialog
  id="cdi-edit-order-form-ejDialog"
  #ejDialogEditOrderForm
  [showCloseIcon]="true"
  header="Edit Order Form"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="60vw"
  height="90vh"
  (close)="onEditOrderFormDialogCloseButtonClick()"
  ><div style="height: 400px; width: 100%">
    <ejs-grid
      id="cdi-edit-order-form-grid"
      [dataSource]="editOrderForm"
      width="100%"
      height="100%"
      enableStickyHeader="true"
      [allowResizing]="true"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowPaging]="false"
      [allowTextWrap]="true"
      [allowPdfExport]="true"
      [allowExcelExport]="true"
      [allowResizing]="true"
      [showColumnChooser]="true"
      [filterSettings]="filterOption"
      [loadingIndicator]="loadingIndicator"
      [toolbar]="toolbarEditOrderFormLayout"
      [enableHover]="enableRowHover"
      [selectionSettings]="selectionOptions"
      (rowSelected)="onClickEditRowSelected($event)"
      [allowSelection]="true"
    >
      <e-columns
        ><e-column type="checkbox" width="100"></e-column>
        <e-column
          field="id"
          headerText="Id"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
          width="120"
          [visible]="false"
        ></e-column>
        <e-column
          field="name"
          headerText="Name"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
          width="120"
        ></e-column>
        <e-column
          field="description"
          headerText="Description"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
          width="120"
        ></e-column>
        <e-column
          field="orderFormType"
          headerText="Type Name"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
          width="120"
        ></e-column>
        <e-column
          field="branchPlant"
          headerText="Branch Plant"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
          width="120"
        ></e-column>
        <e-column
          field="effectiveDate"
          headerText="Effective Date"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
          width="120"
          type="date"
          [format]="dateFormatOption"
        ></e-column>
        <e-column
          field="expirationDate"
          headerText="Expiration Date"
          textAlign="Center"
          clipMode="EllipsisWithTooltip"
          width="120"
          type="date"
          [format]="dateFormatOption"
        ></e-column>
        <e-column
          id="isActive"
          field="isActive"
          headerText="Is Active"
          textAlign="Center"
          width="100"
          [allowFiltering]="false"
          ><ng-template #template let-data>
            <ejs-switch
              id="change-userstatus-switch"
              #userStatusSwitch
              [checked]="data.isActive"
            ></ejs-switch> </ng-template></e-column
        ><e-column
          field="dateCreated"
          headerText="Date Created"
          textAlign="Center"
          width="130"
          type="date"
          [format]="dateFormatOption"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="false"
          [visible]="false"
        ></e-column
        ><e-column
          field="dateModified"
          headerText="Date Modified"
          textAlign="Center"
          width="130"
          type="date"
          [format]="dateFormatOption"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="false"
          [visible]="false"
        ></e-column>
        <e-column
          field="createdOrModifiedBy"
          headerText="Created Or Modified By"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
      </e-columns> </ejs-grid
    ><ng-template #footerTemplate>
      <button
        id="cdi-edit-order-form-ok-button"
        [disabled]="!editRowSelected"
        ejs-button
        class="e-control e-btn e-success sendButton"
        (click)="onClickOkButtonOfEditOrderFormDialog()"
      >
        Ok
      </button>
      <button
        id="cdi-edit-order-form-cancel-button"
        ejs-button
        class="e-control e-btn e-danger"
        (click)="onEditOrderFormDialogCloseButtonClick()"
      >
        Cancel
      </button>
    </ng-template>
  </div>
</ejs-dialog>
<!-- Save Order Layout Dialog-->
<ejs-dialog
  id="cdi-save-order-layout-ejDialog"
  #ejDialogSaveOrderLayout
  [showCloseIcon]="true"
  [isModal]="true"
  [visible]="false"
  width="50vw"
  [enableResize]="true"
  (close)="onCloseDialogSaveOrderLayout()"
  header="Save Order Layout"
>
  <ng-template #content>
    <div>
      <label for="companycode">Company code: </label>
      <ejs-dropdownlist
        id="ddlCompanyCode"
        #dropdownCompanyCode
        [dataSource]="companycode"
        [value]="selectedCompanyCode"
        [fields]="dropdownFieldsCompanycode"
        placeholder="Select a company code"
        (select)="onCompanyCodeDropDownSelect($event)"
        [readonly]="true"
      ></ejs-dropdownlist>
      <br /><br />
    </div>
    <div>
      <label for="orderFormType">Order Form Type: </label>
      <ejs-dropdownlist
        #ddlOrderFormType
        id="ddlOrderFormType"
        placeholder="Select Order Form Type"
        [dataSource]="orderFormTypes"
        [value]="selectedOrderFormType?.name"
        [fields]="dropdownlistOrderFormTypeSearchFields"
        (select)="onOrderFormTypeDropDownSelect($event)"
        allowFiltering="true"
        showClearButton="true"
        [ignoreAccent]="true"
        [ignoreCase]="true"
        filterType="Contains"
        [readonly]="true"
      ></ejs-dropdownlist>
      <br /><br />
    </div>
    <div>
      <label for="branchPlant">Branch Plant: </label>
      <ejs-dropdownlist
        id="ddlBranchPlant"
        placeholder="Select Branch Plant"
        [dataSource]="branchPlants"
        [value]="selectedBranchPlant?.branchPlant"
        [fields]="dropdownlistBranchPlantSearchFields"
        allowFiltering="true"
        showClearButton="true"
        [ignoreAccent]="true"
        [ignoreCase]="true"
        filterType="Contains"
        [readonly]="true"
      ></ejs-dropdownlist>
      <br /><br />
    </div>
    <form [formGroup]="saveOrderForm">
      <div>
        <label for="name">Name </label>
        <input
          id="name"
          class="e-input"
          type="text"
          placeholder="Enter Name"
          formControlName="name"
        />
        <div
          *ngIf="name?.invalid && (name?.dirty || name?.touched)"
          class="form-error"
        >
          <div *ngIf="name?.errors?.['required']">* Name is required.</div>
        </div>
      </div>
      <br /><br />
      <div>
        <label for="description">Description: </label>
        <input
          id="description"
          class="e-input"
          type="text"
          placeholder="Description"
          formControlName="description"
        />
        <div
          *ngIf="
            description?.invalid && (description?.dirty || description?.touched)
          "
          class="form-error"
        >
          <div *ngIf="description?.errors?.['required']">
            * Description is required.
          </div>
        </div>
      </div>
      <br /><br />
      <div>
        <label for="effectiveDate">Effective Date: </label>
        <ejs-datepicker
          id="effectiveDate"
          #datePickerEffectiveDate
          format="yyyy-MMMM-dd"
          placeholder="Enter Effective Date"
          formControlName="saveEffectiveDate"
        ></ejs-datepicker>
        <div
          *ngIf="
            saveEffectiveDate?.invalid &&
            (saveEffectiveDate?.dirty || saveEffectiveDate?.touched)
          "
          class="form-error"
        >
          <div *ngIf="saveEffectiveDate?.errors?.['required']">
            * Effective Date Date is required.
          </div>
        </div>
        <div *ngIf="saveOrderForm.hasError('dateRange')" class="form-error">
          * Effective Date should be smaller than Expiration Date
        </div>
      </div>
      <br /><br />
      <div>
        <label for="expirationDate">Expiration Date: </label>
        <ejs-datepicker
          id="expirationDate"
          #datePickerExpirationDate
          format="yyyy-MMMM-dd"
          placeholder="Enter Expiration Date"
          formControlName="saveExpirationDate"
        ></ejs-datepicker>
        <div
          *ngIf="
            saveExpirationDate?.invalid &&
            (saveExpirationDate?.dirty || saveExpirationDate?.touched)
          "
          class="form-error"
        >
          <div *ngIf="saveExpirationDate?.errors?.['required']">
            * Expiration Date is required.
          </div>
        </div>
        <div *ngIf="saveOrderForm.hasError('dateRange')" class="form-error">
          * Expiration Date should be greater than Effective Date
        </div>
      </div>
      <br /><br />
    </form>
  </ng-template>
  <ng-template #footerTemplate>
    <button
      id="cdi-save-order-layout-save-button"
      [disabled]="!saveOrderForm.valid"
      ejs-button
      class="e-control e-btn e-success sendButton"
      (click)="onClickYesButtonOfSaveOrderLayoutDialog()"
    >
      Save
    </button>
    <button
      id="cdi-save-order-layout-cancel-button"
      ejs-button
      cssClass="e-danger"
      (click)="onClickNoButtonOfSaveOrderLayoutDialog()"
    >
      Cancel
    </button>
  </ng-template>
</ejs-dialog>
<!-- Clone Order form Layout Dialog -->
<ejs-dialog
  id="cdi-clone-order-form-layout-ejDialog"
  #ejDialogCloneOrderFormLayout
  [showCloseIcon]="true"
  header="Clone Order Form "
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="30vw"
  (close)="onCloneOrderFormLayoutDialogCloseButtonClick()"
>
  <ng-template #content>
    <form [formGroup]="cloneOrderForm">
      <div>
        <label for="orderForm">Order Form: </label>
        <ejs-dropdownlist
          id="ddlOrderForm"
          placeholder="Select Order Form"
          [dataSource]="editOrderForm"
          [fields]="dropdownFieldsOrderForm"
          (select)="onOrderFormDropDownSelect($event)"
          formControlName="orderForm"
        ></ejs-dropdownlist>
        <div
          *ngIf="orderForm?.invalid && (orderForm?.dirty || orderForm?.touched)"
          class="form-error"
        >
          <div *ngIf="orderForm?.errors?.['required']">
            * Order Form is required.
          </div>
        </div>
      </div>
      <br /><br />
      <div>
        <label for="saveFormNameAs">Save Form Name As: </label>
        <input
          id="saveFormNameAs"
          class="e-input"
          type="text"
          placeholder="Enter Form Name"
          formControlName="saveFormNameAs"
        />
        <div
          *ngIf="
            saveFormNameAs?.invalid &&
            (saveFormNameAs?.dirty || saveFormNameAs?.touched)
          "
          class="form-error"
        >
          <div *ngIf="saveFormNameAs?.errors?.['required']">
            * New Name is required.
          </div>
        </div>
      </div>
      <br /><br />
      <div>
        <label for="newDescription">Description: </label>
        <input
          id="newDescription"
          class="e-input"
          type="text"
          placeholder="Description"
          formControlName="newDescription"
        />
        <div
          *ngIf="
            newDescription?.invalid &&
            (newDescription?.dirty || newDescription?.touched)
          "
          class="form-error"
        >
          <div *ngIf="newDescription?.errors?.['required']">
            * Description is required.
          </div>
        </div>
      </div>
      <br /><br />
      <div>
        <label for="branchPlant">Branch Plant: </label>
        <ejs-dropdownlist
          id="ddlBranchPlant"
          placeholder="Select Branch Plant"
          [dataSource]="branchPlants"
          [fields]="dropdownlistBranchPlantSearchFields"
          (select)="onBranchPlantDropDownSelect($event)"
          formControlName="newBranchPlant"
          allowFiltering="true"
          showClearButton="true"
          [ignoreAccent]="true"
          [ignoreCase]="true"
          filterType="Contains"
        ></ejs-dropdownlist>
        <div
          *ngIf="
            newBranchPlant?.invalid &&
            (newBranchPlant?.dirty || newBranchPlant?.touched)
          "
          class="form-error"
        >
          <div *ngIf="newBranchPlant?.errors?.['required']">
            * Branch Plant is required.
          </div>
        </div>
        <br /><br />
      </div>
      <div>
        <label for="companycode">Company code: </label
        ><ejs-dropdownlist
          id="ddlCompanyCode"
          #dropdownCompanyCode
          [dataSource]="companycode"
          [fields]="dropdownFieldsCompanycode"
          placeholder="Select a company code"
          (select)="onCompanyCodeDropDownSelect($event)"
          formControlName="newCompanyCode"
        ></ejs-dropdownlist>
      </div>
      <div
        *ngIf="
          newCompanyCode?.invalid &&
          (newCompanyCode?.dirty || newCompanyCode?.touched)
        "
        class="form-error"
      >
        <div *ngIf="newCompanyCode?.errors?.['required']">
          * Company code is required.
        </div>
      </div>
      <br /><br />
      <div>
        <label for="effectiveDate">Effecive Date: </label>
        <ejs-datepicker
          id="effectiveDate"
          #datePickerEffectiveDate
          format="yyyy-MMMM-dd"
          placeholder="Enter Effective Date"
          formControlName="effectiveDate"
        ></ejs-datepicker>
        <div
          *ngIf="
            effectiveDate?.invalid &&
            (effectiveDate?.dirty || effectiveDate?.touched)
          "
          class="form-error"
        >
          <div *ngIf="effectiveDate?.errors?.['required']">
            * Effective Date Date is required.
          </div>
        </div>
        <div *ngIf="cloneOrderForm.hasError('dateRange')" class="form-error">
          * Effective Date should be smaller than Expiration Date
        </div>
      </div>
      <br /><br />
      <div>
        <label for="expirationDate">Expiration Date: </label>
        <ejs-datepicker
          id="expirationDate"
          #datePickerExpirationDate
          format="yyyy-MMMM-dd"
          placeholder="Enter Expiration Date"
          formControlName="expirationDate"
        ></ejs-datepicker>
        <div
          *ngIf="
            expirationDate?.invalid &&
            (expirationDate?.dirty || expirationDate?.touched)
          "
          class="form-error"
        >
          <div *ngIf="expirationDate?.errors?.['required']">
            * Expiration Date is required.
          </div>
        </div>
        <div *ngIf="cloneOrderForm.hasError('dateRange')" class="form-error">
          * Expiration Date should be greater than Effective Date
        </div>
      </div>
    </form>
    <br /><br
  /></ng-template>
  <ng-template #footerTemplate>
    <button
      [disabled]="!cloneOrderForm.valid"
      id="cdi-clone-order-form-layout-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveCloneOrderFormLayoutButtonClick()"
    >
      Clone
    </button>
    <button
      id="cdi-clone-order-form-layout-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onCloneOrderFormLayoutDialogCloseButtonClick()"
    >
      Cancel
    </button>
  </ng-template></ejs-dialog
><!--  Publish Order Layout Dialog-->
<ejs-dialog
  id="cdi-publish-order-layout-ejDialog"
  #ejDialogPublishOrderLayout
  [showCloseIcon]="true"
  [isModal]="true"
  [visible]="false"
  width="60vw"
  height="80vh"
  [enableResize]="true"
  (close)="onCloseDialogPublishOrderLayout()"
  header="Publish Order Form"
>
  <ng-template #content>
    <div style="height: 300px; width: 100%">
      <ejs-grid
        id="cdi-publish-order-grid"
        [dataSource]="publishOrderForm"
        width="100%"
        height="100%"
        enableStickyHeader="true"
        [allowResizing]="true"
        [allowSorting]="true"
        [allowFiltering]="true"
        [allowPaging]="false"
        [allowTextWrap]="true"
        [allowPdfExport]="true"
        [allowExcelExport]="true"
        [allowResizing]="true"
        [showColumnChooser]="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        [toolbar]="toolbarEditOrderFormLayout"
        [enableHover]="enableRowHover"
        [selectionSettings]="selectionOptions"
        (rowSelected)="onClickPublishRowSelected($event)"
        [allowSelection]="true"
      >
        <e-columns
          ><e-column type="checkbox" width="70"></e-column>
          <e-column
            field="id"
            headerText="Id"
            textAlign="Center"
            clipMode="EllipsisWithTooltip"
            width="120"
            [visible]="false"
          ></e-column>
          <e-column
            field="name"
            headerText="Name"
            textAlign="Center"
            clipMode="EllipsisWithTooltip"
            width="120"
          ></e-column>
          <e-column
            field="description"
            headerText="Description"
            textAlign="Center"
            clipMode="EllipsisWithTooltip"
            width="120"
          ></e-column>
          <e-column
            field="orderFormType"
            headerText="Type Name"
            textAlign="Center"
            clipMode="EllipsisWithTooltip"
            width="120"
          ></e-column>
          <e-column
            field="branchPlant"
            headerText="Branch Plant"
            textAlign="Center"
            clipMode="EllipsisWithTooltip"
            width="120"
            [visible]="false"
          ></e-column>
          <e-column
            field="effectiveDate"
            headerText="Effective Date"
            textAlign="Center"
            clipMode="EllipsisWithTooltip"
            width="120"
            type="date"
            [format]="dateFormatOption"
          ></e-column>
          <e-column
            field="expirationDate"
            headerText="Expiration Date"
            textAlign="Center"
            clipMode="EllipsisWithTooltip"
            width="120"
            type="date"
            [format]="dateFormatOption"
          ></e-column>
          <e-column
            id="isActive"
            field="isActive"
            headerText="Is Active"
            textAlign="Center"
            width="100"
            [allowFiltering]="false"
            [visible]="false"
            ><ng-template #template let-data>
              <ejs-switch
                id="change-userstatus-switch"
                #userStatusSwitch
                [checked]="data.isActive"
              ></ejs-switch> </ng-template></e-column
          ><e-column
            field="dateCreated"
            headerText="Date Created"
            textAlign="Center"
            width="130"
            type="date"
            [format]="dateFormatOption"
            clipMode="EllipsisWithTooltip"
            [allowFiltering]="false"
            [visible]="false"
          ></e-column
          ><e-column
            field="dateModified"
            headerText="Date Modified"
            textAlign="Center"
            width="130"
            type="date"
            [format]="dateFormatOption"
            clipMode="EllipsisWithTooltip"
            [allowFiltering]="false"
            [visible]="false"
          ></e-column>
          <e-column
            field="createdOrModifiedBy"
            headerText="Created Or Modified By"
            textAlign="Center"
            width="130"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <button
      [disabled]="!publishRowSelected"
      id="cdi-publish-order-layout-ok-button"
      ejs-button
      class="e-control e-btn e-success sendButton"
      (click)="onClickYesButtonOfPublishOrderLayoutDialog()"
    >
      Ok
    </button>
    <button
      id="cdi-publish-order-layout-cancel-button"
      ejs-button
      cssClass="e-danger"
      (click)="onCloseDialogPublishOrderLayout()"
    >
      Cancel
    </button>
  </ng-template> </ejs-dialog
><!-- Confirmation Publish Order Layout Dialog-->
<ejs-dialog
  id="cdi-confirmation-publish-order-layout-ejDialog"
  #ejDialogConfirmationPublishOrderLayout
  [showCloseIcon]="true"
  [isModal]="true"
  [visible]="false"
  width="30vw"
  [enableResize]="true"
  (close)="onCloseDialogConfirmationPublishOrderLayout()"
  header="Confirmation Order Form"
>
  <ng-template #content>
    <p>Are you sure you want to Publish order Layout?</p>
    <b
      ><p>
        Please Note! Order form will be immediately active in the portal
      </p></b
    >
  </ng-template>
  <ng-template #footerTemplate>
    <button
      id="cdi-confirmation-publish-order-layout-ok-button"
      ejs-button
      class="e-control e-btn e-success sendButton"
      (click)="onClickYesButtonOfConfirmationPublishOrderLayoutDialog()"
    >
      Ok
    </button>
    <button
      id="cdi-confirmation-publish-order-layout-cancel-button"
      ejs-button
      cssClass="e-danger"
      (click)="onCloseDialogConfirmationPublishOrderLayout()"
    >
      Cancel
    </button>
  </ng-template>
</ejs-dialog>
<!-- Quick Add item Dialog -->
<ejs-dialog
  id="cdi-quick-add-item-ejDialog"
  #ejDialogQuickAddItem
  [showCloseIcon]="true"
  header="Quick Add"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="60vw"
  (close)="onQuickAddItemDialogCloseButtonClick()"
  ><ng-template #content>
    <div *ngIf="!hideCards">
      <h3>What would you like to do?</h3>
      <div style="display: flex; min-height: 25vh; margin-top: 5%; gap: 20">
        <div class="e-card">
          <div class="e-card-content">
            <a (click)="onClickAddProductBrandButton()">
              <span class="e-icons e-plus-small"></span> Add Product Brand
            </a>
          </div>
        </div>
        <div class="e-card">
          <div class="e-card-content">
            <a (click)="onClickAddProductLineButton()">
              <span class="e-icons e-plus-small"></span> Add Product Line
            </a>
          </div>
        </div>
        <div class="e-card">
          <div class="e-card-content">
            <a (click)="onClickAddProductFamilyButton()">
              <span class="e-icons e-plus-small"></span> Add Product Family
            </a>
          </div>
        </div>
        <div class="e-card">
          <div class="e-card-content">
            <a (click)="onClickAddItemButton()">
              <span class="e-icons e-plus-small"></span> Add Item
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showProductBrands">
      <label for="productBrand">Product Brand: </label
      ><ejs-dropdownlist
        #ddlProductBrand
        id="ddlProductBrand"
        placeholder="Select Product Brand"
        [dataSource]="rexProducts"
        [fields]="dropdownlistProductBrandSearchFields"
        (select)="onProductBrandDropDownSelect($event)"
        allowFiltering="true"
        showClearButton="true"
        [ignoreAccent]="true"
        [ignoreCase]="true"
        filterType="Contains"
      ></ejs-dropdownlist
      ><br /><br />
    </div>
    <div *ngIf="selectedProductBrand.length > 0">
      <div *ngIf="showProductLines">
        <label for="productLine">Product Line: </label
        ><ejs-dropdownlist
          #ddlProductLine
          id="ddlProductLine"
          placeholder="Select Product Line"
          [dataSource]="rexProductLines"
          [fields]="dropdownlistProductLineSearchFields"
          (select)="onProductLineDropDownSelect($event)"
          allowFiltering="true"
          showClearButton="true"
          [ignoreAccent]="true"
          [ignoreCase]="true"
          filterType="Contains"
        ></ejs-dropdownlist
        ><br /><br />
      </div>
    </div>
    <div *ngIf="selectedProductLine.length > 0">
      <div *ngIf="showProductFamilies">
        <label for="productFamily">Product Family: </label
        ><ejs-dropdownlist
          #ddlProductFamily
          id="ddlProductFamily"
          placeholder="Select Product Family"
          [dataSource]="rexProductFamilies"
          [fields]="dropdownlistProductFamilySearchFields"
          (select)="onProductFamilyDropDownSelect($event)"
          allowFiltering="true"
          showClearButton="true"
          [ignoreAccent]="true"
          [ignoreCase]="true"
          filterType="Contains"
        ></ejs-dropdownlist>
      </div>
      <br /><br />
    </div>
    <div *ngIf="selectedProductFamily.length > 0">
      <div *ngIf="showItems">
        <label for="itemNumber">Item Number: </label>
        <ejs-dropdownlist
          #ddlItemDetails
          id="ddlItemDetails"
          placeholder="Select Item Number"
          [dataSource]="itemDetails"
          [fields]="dropdownlistItemDetailsSearchFields"
          (select)="onItemDetailsDropDownSelect($event)"
          allowFiltering="true"
          showClearButton="true"
          [ignoreAccent]="true"
          [ignoreCase]="true"
          filterType="Contains"
        ></ejs-dropdownlist>
      </div></div
  ></ng-template>
  <br /><br />
  <ng-template #footerTemplate>
    <button
      id="cdi-quick-add-item-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onClickQuickAddButton()"
    >
      Add
    </button>
    <button
      id="cdi-quick-add-item-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onQuickAddItemDialogCloseButtonClick()"
    >
      Cancel
    </button>
    <button
      *ngIf="hideCards"
      id="backButton"
      class="e-control e-btn e-primary sendButton"
      data-ripple="true"
      (click)="onQuickAddItemDialogBackButtonClick()"
    >
      Back
    </button>
  </ng-template>
</ejs-dialog>
<!--  Startup Order Layout Dialog-->
<ejs-dialog
  id="cdi-startup-order-layout-ejDialog"
  #ejDialogStartupOrderLayout
  [showCloseIcon]="true"
  [isModal]="true"
  [visible]="false"
  width="60vw"
  height="60vh"
  [enableResize]="true"
  (close)="onCloseDialogStartupOrderLayout()"
  header="Order Form Wizard"
>
  <ng-template #content>
    <h3>What would you like to do?</h3>
    <div style="display: flex; min-height: 25vh; margin-top: 5%; gap: 20">
      <div class="e-card">
        <div class="e-card-content">
          <a (click)="onClickNewOrderLayoutButton()">
            <span class="e-icons e-plus-small"></span> New Order Form
          </a>
        </div>
      </div>
      <div class="e-card">
        <div class="e-card-content">
          <a (click)="onClickEditOrderLayoutButton()">
            <span class="e-icons e-edit"></span> Edit Order Form
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
<div class="e-card">
  <div
    class="e-card-title"
    style="
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <div style="display: flex; align-items: center">
      <div>Order Form Layout</div>
    </div>
    <div style="display: flex; align-items: center">
      <div class="e-section-control">
        <button
          id="cdi-save-order-layout-button"
          ejs-button
          cssClass="e-outline"
          (click)="onClickSaveOrderLayout()"
        >
          <span class="e-icons e-save"></span> Save
        </button>
      </div>
      <div class="e-section-control" style="margin-left: 10px">
        <div class="e-btn-group e-outline">
          <button
            ejs-button
            id="cdi-publish-order-layout-button"
            cssClass="e-outline"
            (click)="onClickPublishButton()"
          >
            <span class="e-icons e-web-layout"></span> Publish
          </button>
        </div>
      </div>
      <button
        ejs-button
        id="cdi-clone-order-layout-button"
        style="margin-left: 10px; background-color: #00308f"
        (click)="onClickCloneOrderLayoutButton()"
      >
        <span class="e-icons e-copy"></span> Clone
      </button>
    </div>
  </div>
</div>
<div style="height: 400px">
  <ejs-treegrid
    id="cdi-order-form-layout-treeGrid"
    #orderFormLayoutTreeGrid
    width="100%"
    height="100%"
    gridLines="Default"
    enableStickyHeader="true"
    [allowResizing]="true"
    gridLines="Default"
    [allowSorting]="true"
    [allowFiltering]="true"
    [allowPaging]="false"
    allowTextWrap="true"
    [allowResizing]="true"
    [showColumnChooser]="true"
    [filterSettings]="filterOption"
    [loadingIndicator]="loadingIndicator"
    [toolbar]="toolbarOrderFormLayout"
    [enableHover]="enableRowHover"
    [allowRowDragAndDrop]="true"
    [editSettings]="editSettings"
    [dataSource]="rexProducts"
    childMapping="childObject"
    [enableCollapseAll]="true"
    (rowDrop)="rowDrop($event)"
    (toolbarClick)="onToolbarButtonClick($event)"
  >
    <e-columns>
      <e-column
        field="productBrandDescription"
        headerText="Brand"
        textAlign="Left"
        width="70"
      ></e-column>
      <e-column
        field="childType"
        headerText="Child Type"
        textAlign="Left"
        width="70"
      ></e-column>
      <e-column
        field="code"
        headerText="Code"
        textAlign="Left"
        width="70"
        [isPrimaryKey]="true"
      ></e-column>
      <e-column
        field="description"
        headerText="Description"
        textAlign="Left"
        width="100"
      ></e-column>
      <e-column
        field="unitOfMeasure"
        headerText="UOM"
        textAlign="Left"
        width="70"
      ></e-column>
      <e-column
        headerText="Commands"
        textAlign="Left"
        width="70"
        [commands]="commands"
      ></e-column>
    </e-columns>
  </ejs-treegrid>
</div>
