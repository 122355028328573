export class RexItem {
  public itemNumber?: string;
  public itemDescription?: string;
  public unitPrice?: number;
  public productFamilyCode?: string;
  public priceGroupId?: number;
  public priceGroupName?: string;
  public branchPlant?: string;
  public location?: string;
  public currencyCode?: string;
  public unitOfMeasure?: string;
  public effectiveDate?: Date;
  public dateExpired?: Date;
  public productBrandCode?: string;
  public productBrandDescription?: string;
  public productLineCode?: string;
  public productLineDescription?: string;
  public productFamilyDescription?: string;
  constructor(
    itemNumber?: string,
    itemDescription?: string,
    unitPrice?: number,
    productFamilyCode?: string,
    priceGroupId?: number,
    priceGroupName?: string,
    branchPlant?: string,
    location?: string,
    currencyCode?: string,
    unitOfMeasure?: string,
    effectiveDate?: Date,
    dateExpired?: Date,
    productBrandCode?: string,
    productBrandDescription?: string,
    productLineCode?: string,
    productLineDescription?: string,
    productFamilyDescription?: string
    // order?: number
  ) {
    // this.id = id;
    // this.orderFormId = orderFormId;
    this.itemNumber = itemNumber;
    this.itemDescription = itemDescription;
    this.unitPrice = unitPrice;
    this.productFamilyCode = productFamilyCode;
    this.priceGroupId = priceGroupId;
    this.priceGroupName = priceGroupName;
    this.branchPlant = branchPlant;
    this.location = location;
    this.currencyCode = currencyCode;
    this.unitOfMeasure = unitOfMeasure;
    this.effectiveDate = effectiveDate;
    this.dateExpired = dateExpired;
    this.productBrandCode = productBrandCode;
    this.productBrandDescription = productBrandDescription;
    this.productLineCode = productLineCode;
    this.productLineDescription = productLineDescription;
    this.productFamilyDescription = productFamilyDescription;
    // this.order = order;
  }
}
