export class UserProfile {
  public given_name?: string;
  public family_name?: string;
  public nickname?: string;
  public name?: string;
  public picture?: string;
  public initials?: string;
  public companyCode?: string;

  constructor(
    given_name?: string,
    family_name?: string,
    nickname?: string,
    name?: string,
    picture?: string,
    initials?: string,
    companyCode?: string
  ) {
    this.given_name = given_name;
    this.family_name = family_name;
    this.nickname = nickname;
    this.name = name;
    this.picture = picture;
    this.initials = initials;
    this.companyCode = companyCode;
  }
}
