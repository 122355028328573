<div>
  <h1>Eagle Creek</h1>
</div>

<div
  style="display: flex; flex-direction: column; flex-wrap: nowrap; gap: 20px"
>
  <div class="e-card" style="order: 1; flex: 50; padding: 20px">
    <ejs-grid
      id="orderGrid"
      #orderGrid
      [dataSource]="queuedOrders"
      [allowPaging]="false"
      [allowSorting]="true"
      allowTextWrap="true"
      [allowFiltering]="true"
      [filterSettings]="filterOption"
      width="100%"
      height="100%"
      [allowPdfExport]="true"
      [allowExcelExport]="true"
      [allowResizing]="true"
      [showColumnChooser]="true"
      [enableHover]="true"
      (rowSelected)="onOrderMinimumGridRowSelected($event.data)"
    >
      <e-columns>
        <e-column
          field="orderNumber"
          headerText="Order Number"
          textAlign="Center"
          width="130"
          type="Number"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="true"
        ></e-column>
        <e-column
          field="jdeOrderNumber"
          headerText="JDE Order Number"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="true"
        ></e-column>
        <e-column
          field="firstName"
          headerText="First Name"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="lastName"
          headerText="Last Name"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="shippingAddress"
          headerText="Shipping Address"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column headerText="Action" maxWidth="120" textAlign="Center">
          <ng-template #template let-data
            ><button
              *ngIf="data.orderStatus == 4"
              ejs-button
              style="padding-left: 10px; padding-right: 10px"
              cssClass="e-success"
              (click)="onClickRetryButton(data)"
            >
              Retry
            </button></ng-template
          ></e-column
        >
      </e-columns>
    </ejs-grid>
  </div>

  <div class="e-card" style="order: 2; flex: 50; padding: 20px">
    <h2>Populate Dev Environment</h2>
    <form [formGroup]="populateDevForm">
      <div
        style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
      >
        <div style="order: 1">
          <ejs-daterangepicker
            placeholder="Select a range"
            formControlName="dateRangeProp"
            class="e-input"
            type="date"
            [(value)]="dateRange"
          ></ejs-daterangepicker>
        </div>
        <div style="order: 2">
          <button
            class="e-control e-btn e-success sendButton"
            data-ripple="true"
            [disabled]="!populateDevForm.valid"
            (click)="onClickPopulateDev()"
          >
            Populate Dev Environment
          </button>
        </div>
      </div>
    </form>
    <div
      style="display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px"
    >
      <p id="status">Status: {{ status }}</p>
    </div>
  </div>
</div>
