<!-- Settings Dialog -->
<ejs-dialog
  id="cdi-settings-ejDialog"
  #ejDialogSettings
  [showCloseIcon]="true"
  header="Settings"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="hide()"
>
  <app-settings
    [showFormatGrid]="showFormatGrid"
    [showTextAlignGrid]="showTextAlignGrid"
    [showDataTypeGrid]="showDataTypeGrid"
  ></app-settings>
</ejs-dialog>
<!-- Add Report Configuration Dialog -->
<ejs-dialog
  id="cdi-add-report-configuration-ejDialog"
  #ejDialogAddReportConfiguration
  [showCloseIcon]="true"
  header="Add Report Configuration"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddReportConfigurationDialogCloselButtonClick()"
>
  <form [formGroup]="addReportConfigurationForm">
    <div>
      <label for="name">Name: </label>
      <input
        id="name"
        class="e-input"
        type="text"
        placeholder="Enter Name"
        formControlName="name"
      />
      <div
        *ngIf="name?.invalid && (name?.dirty || name?.touched)"
        class="form-error"
      >
        <div *ngIf="name?.errors?.['required']">* Name is required.</div>
        <div *ngIf="name?.errors?.['minlength']">
          * Name must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="description">Description: </label>
      <div
        style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
      >
        <div class="e-input-group" style="order: 1">
          <input
            id="description"
            class="e-input"
            type="text"
            placeholder="Enter Description"
            formControlName="description"
          />
        </div>
      </div>

      <div
        *ngIf="
          description?.invalid && (description?.dirty || description?.touched)
        "
        class="form-error"
      >
        <div *ngIf="description?.errors?.['required']">
          * description is required.
        </div>
        <div *ngIf="description?.errors?.['minlength']">
          * description must be at least 5 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="api-end-point">Api End Point: </label>
      <input
        id="api-end-point"
        class="e-input"
        type="text"
        placeholder="Enter Api End Point"
        formControlName="apiEndPoint"
      />
      <div
        *ngIf="
          apiEndPoint?.invalid && (apiEndPoint?.dirty || apiEndPoint?.touched)
        "
        class="form-error"
      >
        <div *ngIf="apiEndPoint?.errors?.['required']">
          * Api End Point is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="report-accessible-by-id">Report AccessibleBy: </label>
      <ejs-dropdownlist
        id="report-accessible-by-id"
        #dropdownlistReportAccessibleBy
        [dataSource]="reportAccessibleByList"
        [fields]="dropdownlistreportAccessibleByListFields"
        placeholder="Select Report AccessibleBy"
        (select)="onReportAccessibleByDropDownSelect($event)"
        formControlName="reportAccessibleBy"
      ></ejs-dropdownlist>
      <div
        *ngIf="
          reportAccessibleBy?.invalid &&
          (reportAccessibleBy?.dirty || reportAccessibleBy?.touched)
        "
        class="form-error"
      >
        <div *ngIf="reportAccessibleBy?.errors?.['required']">
          * Report AccessibleBy is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isActiveValue">Is Active: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="isActive"
            (change)="onUserStatusSwitchIsActiveToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showPerformaCheckBox">Show Performa CheckBox: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="showPerformaCheckBox"
            (change)="onUserStatusSwitchShowPerformaCheckBoxToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showBeginDate">Show Begin Date: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="showBeginDate"
            (change)="onUserStatusSwitchShowBeginDateToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showEndDate">Show End Date: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="showEndDate"
            (change)="onUserStatusSwitchShowEndDateToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="branchPlantRequired">Branch Plant Required: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="branchPlantRequired"
            (change)="onUserStatusSwitchBranchPlantRequiredToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showYearDropDown">Show Year DropDown: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="showYearDropDown"
            (change)="onUserStatusSwitchShowYearDropDownToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showProductLineDropDown"
            >Show ProductLine DropDown:
          </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="showProductLineDropDown"
            (change)="onUserStatusSwitchShowProductLineDropDownToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isAccountNumberRequired"
            >Is Account Number Required:
          </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="isAccountNumberRequired"
            (change)="onUserStatusSwitchIsAccountNumberRequiredToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isCommissionCodeRequired"
            >Is Commission Code Required:
          </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="isCommissionCodeRequired"
            (change)="onUserStatusSwitchIsCommissionCodeRequiredToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        ></div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        ></div>
      </div>
    </div>
    <button
      id="cdi-add-report-configuration-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      [disabled]="!addReportConfigurationForm.valid"
      (click)="onSaveAddReportConfigurationButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-add-report-configuration-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddReportConfigurationDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>
<!-- Edit Report Configuration Dialog -->
<ejs-dialog
  id="cdi-edit-report-configuration-ejDialog"
  #ejDialogEditReportConfiguration
  [showCloseIcon]="true"
  header="Edit Report Configuration"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onEditReportConfigurationDialogCloselButtonClick()"
>
  <form [formGroup]="addReportConfigurationForm">
    <div>
      <label for="name">Name: </label>
      <input
        id="name"
        class="e-input"
        type="text"
        placeholder="Enter Name"
        formControlName="name"
        [value]="editReportConfigurationData?.name"
      />
      <div
        *ngIf="name?.invalid && (name?.dirty || name?.touched)"
        class="form-error"
      >
        <div *ngIf="name?.errors?.['required']">* Name is required.</div>
        <div *ngIf="name?.errors?.['minlength']">
          * Name must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="description">Description: </label>
      <div
        style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
      >
        <div class="e-input-group" style="order: 1">
          <input
            id="description"
            class="e-input"
            type="text"
            placeholder="Enter Description"
            formControlName="description"
            [value]="editReportConfigurationData?.description"
          />
        </div>
      </div>

      <div
        *ngIf="
          description?.invalid && (description?.dirty || description?.touched)
        "
        class="form-error"
      >
        <div *ngIf="description?.errors?.['required']">
          * description is required.
        </div>
        <div *ngIf="description?.errors?.['minlength']">
          * description must be at least 5 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="api-end-point">Api End Point: </label>
      <input
        id="api-end-point"
        class="e-input"
        type="text"
        placeholder="Enter Api End Point"
        formControlName="apiEndPoint"
        [value]="editReportConfigurationData?.apiEndPoint"
      />
      <div
        *ngIf="
          apiEndPoint?.invalid && (apiEndPoint?.dirty || apiEndPoint?.touched)
        "
        class="form-error"
      >
        <div *ngIf="apiEndPoint?.errors?.['required']">
          * Api End Point is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="report-accessible-by-id">Report AccessibleBy: </label>
      <ejs-dropdownlist
        id="report-accessible-by-id"
        #dropdownlistReportAccessibleBy
        [dataSource]="reportAccessibleByList"
        [fields]="dropdownlistreportAccessibleByListFields"
        placeholder="Select Report AccessibleBy"
        (select)="onReportAccessibleByDropDownSelect($event)"
        formControlName="reportAccessibleBy"
        [value]="editReportConfigurationData?.reportAccessibleByDescription"
      ></ejs-dropdownlist>
      <div
        *ngIf="
          reportAccessibleBy?.invalid &&
          (reportAccessibleBy?.dirty || reportAccessibleBy?.touched)
        "
        class="form-error"
      >
        <div *ngIf="reportAccessibleBy?.errors?.['required']">
          * Report AccessibleBy is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isActiveValue">Is Active: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportConfigurationData?.isActive"
            formControlName="isActive"
            (change)="onUserStatusSwitchIsActiveToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showPerformaCheckBox">Show Performa CheckBox: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportConfigurationData?.showPerformaCheckBox"
            formControlName="showPerformaCheckBox"
            (change)="onUserStatusSwitchShowPerformaCheckBoxToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showBeginDate">Show Begin Date: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportConfigurationData?.showBeginDate"
            formControlName="showBeginDate"
            (change)="onUserStatusSwitchShowBeginDateToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showEndDate">Show End Date: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportConfigurationData?.showEndDate"
            formControlName="showEndDate"
            (change)="onUserStatusSwitchShowEndDateToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="branchPlantRequired">Branch Plant Required: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            [checked]="editReportConfigurationData?.branchPlantRequired"
            (change)="onUserStatusSwitchBranchPlantRequiredToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showYearDropDown">Show Year DropDown: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportConfigurationData?.showYearDropDown"
            formControlName="showYearDropDown"
            (change)="onUserStatusSwitchShowYearDropDownToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="showProductLineDropDown"
            >Show ProductLine DropDown:
          </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportConfigurationData?.showProductLineDropDown"
            formControlName="showProductLineDropDown"
            (change)="onUserStatusSwitchShowProductLineDropDownToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isAccountNumberRequired"
            >Is Account Number Required:
          </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportConfigurationData?.isAccountNumberRequired"
            formControlName="isAccountNumberRequired"
            (change)="onUserStatusSwitchIsAccountNumberRequiredToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isCommissionCodeRequired"
            >Is Commission Code Required:
          </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportConfigurationData?.isCommissionCodeRequired"
            formControlName="isCommissionCodeRequired"
            (change)="onUserStatusSwitchIsCommissionCodeRequiredToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        ></div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        ></div>
      </div>
    </div>

    <button
      id="cdi-edit-report-configuration-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveEditReportConfigurationButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-edit-report-configuration-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onEditReportConfigurationDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>
<!-- Add Report Column Configuration Dialog -->
<ejs-dialog
  id="cdi-add-report-column-configuration-ejDialog"
  #ejDialogAddReportColumnConfiguration
  [showCloseIcon]="true"
  header="Add Report Column Configuration"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddReportColumnConfigurationDialogCloselButtonClick()"
>
  <form [formGroup]="addReportColumnConfigurationForm">
    <div>
      <label for="field">Field: </label>
      <input
        id="field"
        class="e-input"
        type="text"
        placeholder="Enter Field"
        formControlName="field"
      />
      <div
        *ngIf="field?.invalid && (field?.dirty || field?.touched)"
        class="form-error"
      >
        <div *ngIf="field?.errors?.['required']">* Field is required.</div>
        <div *ngIf="field?.errors?.['minlength']">
          * field must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="headerText">HeaderText: </label>
      <div
        style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
      >
        <div class="e-input-group" style="order: 1">
          <input
            id="headerText"
            class="e-input"
            type="text"
            placeholder="Enter Header Text"
            formControlName="headerText"
          />
        </div>
      </div>

      <div
        *ngIf="
          headerText?.invalid && (headerText?.dirty || headerText?.touched)
        "
        class="form-error"
      >
        <div *ngIf="headerText?.errors?.['required']">
          * headerText is required.
        </div>
        <div *ngIf="headerText?.errors?.['minlength']">
          * headerText must be at least 5 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <label for="width">Width: </label>
    <div
      style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
    >
      <div class="e-input-group" style="order: 1">
        <input
          id="width"
          class="e-input"
          type="text"
          placeholder="Enter Width"
          formControlName="width"
        />
      </div>
    </div>

    <div
      *ngIf="widthValue?.invalid && (widthValue?.dirty || widthValue?.touched)"
      class="form-error"
    >
      <div *ngIf="widthValue?.errors?.['required']">* Width is required.</div>
    </div>
    <br /><br />
    <div>
      <label for="text-align">Text Align: </label>
      <ejs-dropdownlist
        id="text-align"
        #dropdownlistTextAlign
        [dataSource]="reportConfigTextAlign"
        [fields]="dropdownlistTextAlignFields"
        [value]="reportConfigTextAlign"
        placeholder="Select Text Align"
        (select)="onTextAlignDropDownSelect($event)"
        placeholder="textAlign"
      ></ejs-dropdownlist>
      <div
        *ngIf="textAlign?.invalid && (textAlign?.dirty || textAlign?.touched)"
        class="form-error"
      >
        <div *ngIf="textAlign?.errors?.['required']">
          * Text Align is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="format">Format: </label>
      <ejs-dropdownlist
        id="format"
        #dropdownlistFormat
        [dataSource]="reportConfigFormat"
        [fields]="dropdownlistFormatFields"
        [value]="reportConfigFormat"
        placeholder="Select Format"
        (select)="onFormatDropDownSelect($event)"
        formControlName="format"
      ></ejs-dropdownlist>
      <div
        *ngIf="format?.invalid && (format?.dirty || format?.touched)"
        class="form-error"
      >
        <div *ngIf="format?.errors?.['required']">* Format is required.</div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="dataType">DataType: </label>
      <ejs-dropdownlist
        id="data-type"
        #dropdownlistDataType
        [dataSource]="reportConfigDataType"
        [fields]="dropdownlistDataTypeFields"
        [value]="reportConfigDataType"
        placeholder="Select DataType"
        (select)="onDataTypeDropDownSelect($event)"
        formControlName="dataType"
      ></ejs-dropdownlist>
      <div
        *ngIf="dataType?.invalid && (dataType?.dirty || dataType?.touched)"
        class="form-error"
      >
        <div *ngIf="dataType?.errors?.['required']">
          * Data Type is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isActiveValue">Is Active: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="isActive"
            (change)="onUserStatusSwitchIsActiveToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="allowSorting">Allow Sorting: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="allowSorting"
            (change)="onUserStatusSwitchAllowSortingToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="allowFiltering">Allow Filtering: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="allowFiltering"
            (change)="onUserStatusSwitchAllowFilteringToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="allowGrouping">Allow Grouping: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="allowGrouping"
            (change)="onUserStatusSwitchAllowGroupingToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isPrimaryKey">Is PrimaryKey: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="isPrimaryKey"
            (change)="onUserStatusSwitchIsPrimaryKeyToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="autofit">Autofit: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="userStatusSwitchValue"
            formControlName="autofit"
            (change)="onUserStatusSwitchAutofitToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br />
    <button
      id="cdi-add-report-column-configuration-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveAddReportColumnConfigurationButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-add-report-column-configuration-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddReportColumnConfigurationDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>
<!-- Edit Report Column Configuration Dialog -->
<ejs-dialog
  id="cdi-edit-report-column-configuration-ejDialog"
  #ejDialogEditReportColumnConfiguration
  [showCloseIcon]="true"
  header="Edit Report Column Configuration"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onEditReportColumnConfigurationDialogCloselButtonClick()"
>
  <form [formGroup]="addReportColumnConfigurationForm">
    <div>
      <label for="field">Field: </label>
      <input
        id="field"
        class="e-input"
        type="text"
        [value]="editReportColumnConfigurationData?.field"
        placeholder="Enter Field"
        formControlName="field"
      />
      <div
        *ngIf="field?.invalid && (field?.dirty || field?.touched)"
        class="form-error"
      >
        <div *ngIf="field?.errors?.['required']">* Field is required.</div>
        <div *ngIf="field?.errors?.['minlength']">
          * field must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="headerText">HeaderText: </label>
      <div
        style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
      >
        <div class="e-input-group" style="order: 1">
          <input
            id="headerText"
            class="e-input"
            type="text"
            placeholder="Enter Header Text"
            formControlName="headerText"
            [value]="editReportColumnConfigurationData?.headerText"
          />
        </div>
      </div>

      <div
        *ngIf="
          headerText?.invalid && (headerText?.dirty || headerText?.touched)
        "
        class="form-error"
      >
        <div *ngIf="headerText?.errors?.['required']">
          * headerText is required.
        </div>
        <div *ngIf="headerText?.errors?.['minlength']">
          * headerText must be at least 5 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <label for="width">Width: </label>
    <div
      style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
    >
      <div class="e-input-group" style="order: 1">
        <input
          id="width"
          class="e-input"
          type="text"
          placeholder="Enter Width"
          formControlName="width"
          [value]="editReportColumnConfigurationData?.width"
        />
      </div>
    </div>

    <div
      *ngIf="widthValue?.invalid && (widthValue?.dirty || widthValue?.touched)"
      class="form-error"
    >
      <div *ngIf="widthValue?.errors?.['required']">* Width is required.</div>
    </div>
    <br /><br />
    <div>
      <label for="text-align">Text Align: </label>
      <ejs-dropdownlist
        id="text-align"
        #dropdownlistTextAlign
        [dataSource]="reportConfigTextAlign"
        [fields]="dropdownlistTextAlignFields"
        [value]="editReportColumnConfigurationData?.textAlign"
        placeholder="Select Text Align"
        (select)="onTextAlignDropDownSelect($event)"
        placeholder="textAlign"
      ></ejs-dropdownlist>
      <div
        *ngIf="textAlign?.invalid && (textAlign?.dirty || textAlign?.touched)"
        class="form-error"
      >
        <div *ngIf="textAlign?.errors?.['required']">
          * Text Align is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="format">Format: </label>
      <ejs-dropdownlist
        id="format"
        #dropdownlistFormat
        [dataSource]="reportConfigFormat"
        [fields]="dropdownlistFormatFields"
        [value]="editReportColumnConfigurationData?.format"
        placeholder="Select Format"
        (select)="onFormatDropDownSelect($event)"
        formControlName="format"
      ></ejs-dropdownlist>
      <div
        *ngIf="format?.invalid && (format?.dirty || format?.touched)"
        class="form-error"
      >
        <div *ngIf="format?.errors?.['required']">* Format is required.</div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="dataType">DataType: </label>
      <ejs-dropdownlist
        id="data-type"
        #dropdownlistDataType
        [dataSource]="reportConfigDataType"
        [fields]="dropdownlistDataTypeFields"
        [value]="editReportColumnConfigurationData?.dataType"
        placeholder="Select DataType"
        (select)="onDataTypeDropDownSelect($event)"
        formControlName="dataType"
      ></ejs-dropdownlist>
      <div
        *ngIf="dataType?.invalid && (dataType?.dirty || dataType?.touched)"
        class="form-error"
      >
        <div *ngIf="dataType?.errors?.['required']">
          * Data Type is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isActiveValue">Is Active: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportColumnConfigurationData?.isActive"
            formControlName="isActive"
            (change)="onUserStatusSwitchIsActiveToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="allowSorting">Allow Sorting: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportColumnConfigurationData?.allowSorting"
            formControlName="allowSorting"
            (change)="onUserStatusSwitchAllowSortingToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="allowFiltering">Allow Filtering: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportColumnConfigurationData?.allowFiltering"
            formControlName="allowFiltering"
            (change)="onUserStatusSwitchAllowFilteringToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="allowGrouping">Allow Grouping: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportColumnConfigurationData?.allowGrouping"
            formControlName="allowGrouping"
            (change)="onUserStatusSwitchAllowGroupingToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br /><br />
    <div style="display: flex; flex-direction: row; align-items: flex-start">
      <div style="order: 1; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="isPrimaryKey">Is PrimaryKey: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportColumnConfigurationData?.isPrimaryKey"
            formControlName="isPrimaryKey"
            (change)="onUserStatusSwitchIsPrimaryKeyToggle($event)"
          ></ejs-switch>
        </div>
      </div>
      <div style="order: 2; flex: 50; display: flex">
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 60;
          "
        >
          <label for="autofit">Autofit: </label>
        </div>
        <div
          style="
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 40;
          "
        >
          <ejs-switch
            id="change-userstatus-switch"
            #userStatusSwitch
            [checked]="editReportColumnConfigurationData?.autofit"
            formControlName="autofit"
            (change)="onUserStatusSwitchAutofitToggle($event)"
          ></ejs-switch>
        </div>
      </div>
    </div>
    <br />
    <button
      id="cdi-edit-report-column-configuration-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveEditReportColumnConfigurationButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-edit-report-column-configuration-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onEditReportColumnConfigurationDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>
<!-- Report Configuration Details Dialog -->
<ejs-dialog
  id="cdi-report-configuration-details-ejDialog"
  #dialogReportConfigurationDetails
  header="Report Configuration Details"
  [showCloseIcon]="true"
  [visible]="false"
  [isModal]="true"
  [enableResize]="true"
  (close)="hide()"
  zIndex="1000"
>
  <ng-template #content>
    <ejs-grid
      #gridReportConfigurationDetails
      id="report-configuration-details"
      [allowSorting]="true"
      allowTextWrap="true"
      [allowResizing]="true"
      [dataSource]="reportConfigurationDetailsList"
      [toolbar]="toolbarReportConfigurationGrid"
      [showColumnChooser]="true"
      [enableHover]="enableRowHover"
      (rowDeselected)="onReportConfigurationGridRowSelected($event.data)"
      ><ng-template #toolbarTemplate let-data>
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
          "
        >
          <div
            style="
              order: 1;
              align-items: flex-start;
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              flex-direction: row;
            "
          >
            <div style="order: 1; padding-bottom: 5px">
              <button
                id="cdi-add-report-configuration-button"
                ejs-button
                style="padding-left: 10px; padding-right: 10px"
                cssClass="e-success"
                (click)="onClickAddReportColumnConfigButton()"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </ng-template>
      <e-columns>
        <e-column
          field="id"
          headerText="Id"
          width="100"
          clipMode="EllipsisWithTooltip"
          type="string"
        ></e-column>
        <e-column
          field="dateCreated"
          headerText="Date Created"
          width="130"
          clipMode="EllipsisWithTooltip"
          [format]="dateFormatOption"
          type="date"
        ></e-column>
        <e-column
          field="dateModified"
          headerText="Date Modified"
          width="130"
          clipMode="EllipsisWithTooltip"
          [format]="dateFormatOption"
          type="date"
          [visible]="false"
        ></e-column>
        <e-column
          field="reportConfigurationId"
          headerText="Report Configuration Id"
          width="130"
          clipMode="EllipsisWithTooltip"
          type="string"
          [visible]="false"
        ></e-column>
        <e-column
          field="field"
          headerText="Field"
          width="130"
          clipMode="EllipsisWithTooltip"
          type="string"
        ></e-column>
        <e-column
          field="headerText"
          headerText="Header text"
          width="130"
          clipMode="EllipsisWithTooltip"
          type="string"
        ></e-column
        ><e-column
          field="textAlign"
          headerText="Text Align"
          width="140"
          [allowFiltering]="false"
        >
        </e-column>
        <e-column
          field="format"
          headerText="Format"
          width="140"
          [allowFiltering]="false"
        >
        </e-column>
        <e-column
          field="dataType"
          headerText="Data Type"
          width="140"
          [allowFiltering]="false"
        >
        </e-column>
        <e-column
          field="width"
          headerText="Width"
          width="130"
          clipMode="EllipsisWithTooltip"
          type="string"
          [visible]="false"
        ></e-column>
        <e-column headerText="Action" maxWidth="130">
          <ng-template #template let-data
            ><ejs-menu
              target="#target"
              [items]="menuItemsColumnConfig"
              showItemOnClick="true"
              (select)="onReportColumnConfigurationContextMenuSelect($event)"
            ></ejs-menu> </ng-template
        ></e-column>
      </e-columns>
    </ejs-grid>
  </ng-template>
</ejs-dialog>
<div class="e-card">
  <div
    class="e-card-title"
    style="
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <div style="display: flex; align-items: center">
      <div style="margin-right: 20px">Report Configuration</div>
    </div>
    <div style="display: flex; align-items: center">
      <ejs-dropdownlist
        id="cdi-settings-dropdownlist"
        [dataSource]="settings"
        [placeholder]="'Select Settings'"
        (select)="onSelectSettings($event)"
      ></ejs-dropdownlist
      ><button
        ejs-button
        style="margin-left: 10px; background-color: #00308f"
        (click)="onClickAddButton()"
      >
        Add
      </button>
    </div>
  </div>
  <div class="e-card-separator"></div>
  <div class="e-card-content">
    <div style="height: 400px; width: 100%">
      <ejs-grid
        id="cdi-report-configuration-grid"
        #reportConfigurationGrid
        [dataSource]="reportConfigurationList"
        [allowPaging]="false"
        [allowSorting]="true"
        allowTextWrap="true"
        [allowFiltering]="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        width="100%"
        height="100%"
        [toolbar]="toolbarReportConfigurationGrid"
        [allowPdfExport]="true"
        [allowExcelExport]="true"
        [allowResizing]="true"
        [showColumnChooser]="true"
        [enableHover]="enableRowHover"
        (rowSelected)="onReportConfigurationGridRowSelected($event.data)"
      >
        <e-columns>
          <e-column
            field="id"
            headerText="Id"
            width="80"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="dateCreated"
            headerText="Date Created"
            width="130"
            clipMode="EllipsisWithTooltip"
            [format]="dateFormatOption"
            type="date"
          ></e-column>
          <e-column
            field="dateUpdated"
            headerText="Date Updated"
            width="130"
            clipMode="EllipsisWithTooltip"
            [format]="dateFormatOption"
            type="date"
            [visible]="false"
          ></e-column>
          <e-column
            field="name"
            headerText="Name"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="description"
            headerText="Description"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="apiEndPoint"
            headerText="Api End Point"
            width="150"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="reportAccessibleByDescription"
            headerText="Report AccessibleBy"
            width="130"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column headerText="Action" maxWidth="120">
            <ng-template #template let-data
              ><ejs-menu
                target="#target"
                [items]="menuItems"
                showItemOnClick="true"
                (select)="onReportConfigurationContextMenuSelect($event)"
              ></ejs-menu> </ng-template
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>
<ejs-sidebar
  id="cdi-right-sidebar"
  #rightSidebar
  [width]="width"
  [position]="position"
  [type]="type"
  [closeOnDocumentClick]="closeOnDocumentClick"
>
  <button
    ejs-button
    id="cdi-close-sidebar-button"
    (click)="closeClick()"
    class="e-btn close-btn"
  >
    &times;
  </button>
  <table>
    <tr>
      <td>
        <label for="isActive">Is Active: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="isActive"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="showPerformaCheckBox">Show Performa CheckBox: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="showPerformaCheckBox"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="showBeginDate">Show Begin Date: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="showBeginDate"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="showEndDate">Show End Date: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="showEndDate"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="branchPlantRequired">Branch Plant Required: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="branchPlantRequired"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="showYearDropDown">Show Year DropDown: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="showYearDropDown"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="showProductLineDropDown">Show ProductLine DropDown: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="showProductLineDropDown"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="isAccountNumberRequired"
          >Is Account Number Required:
        </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="isAccountNumberRequired"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="isCommissionCodeRequired"
          >Is Commission Code Required:
        </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="isCommissionCodeRequired"
        ></ejs-switch>
      </td>
    </tr>
  </table>
</ejs-sidebar>
<ejs-sidebar
  id="cdi-right-sidebar-details"
  #rightSidebarDetails
  [width]="width"
  [position]="position"
  [type]="type"
  zIndex="1001"
  [closeOnDocumentClick]="closeOnDocumentClick"
>
  <button
    ejs-button
    id="cdi-close-button"
    (click)="closeClick()"
    class="e-btn close-btn"
  >
    &times;
  </button>
  <table>
    <tr>
      <td>
        <label for="isActive">Is Active: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="isActive"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="allowSorting">Allow Sorting: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="allowSorting"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="allowFiltering">Allow Filtering: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="allowFiltering"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="allowGrouping">Allow Grouping: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="allowGrouping"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="isPrimaryKey">Is Primary Key: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="isPrimaryKey"
        ></ejs-switch>
      </td>
    </tr>
    <tr>
      <td>
        <label for="autofit">Autofit: </label>
      </td>
      <td>
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="autofit"
        ></ejs-switch>
      </td>
    </tr>
  </table>
</ejs-sidebar>
