import { Component, ElementRef, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ActionEventArgs,
  EditSettingsModel,
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
  ContextMenuComponent,
  ClickEventArgs,
  MenuEventArgs,
  MenuItemModel,
} from '@syncfusion/ej2-angular-navigations';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { Router } from '@angular/router';
import { OrderMinimum } from './model/order-minimum.model';
import { User } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/services/user.service';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { CompanyCode } from 'src/app/shared/model/company-code.model';

@Component({
  selector: 'app-order-minimum',
  templateUrl: './order-minimum.component.html',
  styleUrls: ['./order-minimum.component.scss'],
})
export class OrderMinimumComponent {
  public orderMinimumList: OrderMinimum[] = [];
  public orderYearList: OrderMinimum[] = [];
  public selectedRowData: OrderMinimum[] = [];
  public editOrderMinimumData: any;
  @ViewChild('orderMinimumGrid') public orderMinimumGrid!: GridComponent;
  @ViewChild('ejDialogAddOrderMinimum')
  ejDialogAddOrderMinimum!: DialogComponent;
  @ViewChild('ejDialogCloneOrder') ejDialogCloneOrder!: DialogComponent;
  @ViewChild('ejDialogEditOrderMinimum')
  ejDialogEditOrderMinimum!: DialogComponent;
  @ViewChild('contextMenu') public contextMenu!: ContextMenuComponent;

  public userSwitch: any;
  public isActive: boolean = true;
  public companycode: string[] = ['00015', '00030'];
  public dropdownFieldsCompanycode: Object = { text: 'id', value: 'name' };
  public selectedReportOrderYear: number = 0;
  // Declared  Variables
  public dialogTitle: string;
  public user: User;
  public dateCreatedValue = new Date();
  public dialogUserStatusWarning: string = '';
  public isUserStatusDialogNoButtonClicked: boolean = false;
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };
  public toolbarOrderMinimumGrid?: ToolbarItems[] | object;
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public enableRowHover: boolean = false;
  public dropdownlistOrderYearSearchFields: Object = {
    text: 'orderYear',
    value: 'orderYear',
  };

  get companyCode() {
    return this.addOrderMinimumForm?.get('companyCode');
  }
  get currencyCode() {
    return this.addOrderMinimumForm.get('currencyCode');
  }
  get countryCode() {
    return this.addOrderMinimumForm.get('countryCode');
  }
  get orderMinimumAmount() {
    return this.addOrderMinimumForm.get('orderMinimumAmount');
  }
  get handlingCharge() {
    return this.addOrderMinimumForm.get('handlingCharge');
  }
  get orderYear() {
    return this.addOrderMinimumForm.get('orderYear');
  }
  public addOrderMinimumForm = new FormGroup({
    currencyCode: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),

    countryCode: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    orderMinimumAmount: new FormControl('', [Validators.required]),
    handlingCharge: new FormControl('', [Validators.required]),
  });
  public cloneOrderForm = new FormGroup({
    orderYear: new FormControl('', [Validators.required]),
  });
  public showClearSearchButton: boolean;

  constructor(
    private dataService: DataserviceService,
    private _cache: CacheService,
    private userService: UserService
  ) {
    this.showClearSearchButton = false;
    this.dialogTitle = '';
    this.user = new User();
  }

  ngOnInit(): void {
    this.toolbarOrderMinimumGrid = ['ColumnChooser', 'Search'];
    this.getOrderMinimum();
    this.getOrderYear();
  }
  public menuItems: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'Edit',
          id: 'edit',
          iconCss: 'e-icons e-edit',
        },
      ],
    },
  ];
  public onOrderMinimumContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'edit':
          this.editOrderMinimum(this.selectedRowData);
          break;
      }
    }
  }
  public onSaveAddOrderMinimumButtonClick() {
    let currencyCode = this.addOrderMinimumForm
      .get('currencyCode')
      ?.value?.toString();
    let countryCode = this.addOrderMinimumForm
      .get('countryCode')
      ?.value?.toString();
    let orderMinimumAmount = parseInt(
      this.addOrderMinimumForm.get('orderMinimumAmount')?.value?.toString() ??
        '0'
    );
    let handlingCharge = parseInt(
      this.addOrderMinimumForm.get('handlingCharge')?.value?.toString() ?? '0'
    );
    const addOrderMinimum = new OrderMinimum(
      0,
      new Date(),
      this.userService.userProfile.companyCode,
      currencyCode,
      countryCode,
      orderMinimumAmount,
      handlingCharge,
      this.selectedReportOrderYear,
      this.isActive,
      new Date(),
      this.userService.userProfile.name
    );
    this.dataService
      .add<any>(
        `OrderMinimum/AddOrderMinimum/${this.userService.userProfile.companyCode}/${currencyCode}/${countryCode}/${orderMinimumAmount}/${handlingCharge}/${this.selectedReportOrderYear}/${this.isActive}`,
        addOrderMinimum
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Create Order Minimum',
            `Order minimum created successfully`
          )
        );
        this.ejDialogAddOrderMinimum.hide();
      });
  }
  public editOrderMinimum(data: any) {
    this.editOrderMinimumData = data;
    this.ejDialogEditOrderMinimum.show();
  }
  public onSaveEditOrderMinimumButtonClick() {
    let currencyCode = this.addOrderMinimumForm
      .get('currencyCode')
      ?.value?.toString();
    if (currencyCode == '') {
      currencyCode = this.editOrderMinimumData.currencyCode;
    }
    let countryCode = this.addOrderMinimumForm
      .get('countryCode')
      ?.value?.toString();
    if (countryCode == '') {
      countryCode = this.editOrderMinimumData.countryCode;
    }
    let orderMinimumAmount = parseInt(
      this.addOrderMinimumForm.get('orderMinimumAmount')?.value?.toString() ??
        '0'
    );
    if (orderMinimumAmount == 0 || isNaN(orderMinimumAmount)) {
      orderMinimumAmount = this.editOrderMinimumData.orderMinimumAmount;
    }
    let handlingCharge = parseInt(
      this.addOrderMinimumForm.get('handlingCharge')?.value?.toString() ?? '0'
    );
    if (handlingCharge == 0 || isNaN(handlingCharge)) {
      handlingCharge = this.editOrderMinimumData.handlingCharge;
    }

    let isActive = Boolean(this.editOrderMinimumData.isActive);
    if (isActive !== this.isActive) {
      isActive = this.isActive;
    }
    const updateOrderMinimum = new OrderMinimum(
      0,
      new Date(),
      this.userService.userProfile.companyCode,
      currencyCode,
      countryCode,
      orderMinimumAmount,
      handlingCharge,
      this.editOrderMinimumData.orderYear,
      isActive,
      new Date(),
      this.userService.userProfile.name
    );
    this.dataService
      .update<any>(
        `OrderMinimum/UpdateOrderMinimum/${this.editOrderMinimumData.id}/${this.userService.userProfile.companyCode}/${currencyCode}/${countryCode}/${orderMinimumAmount}/${handlingCharge}/${this.editOrderMinimumData.orderYear}/${isActive}`,
        updateOrderMinimum
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Update Order Minimum',
            `Order Minimum updated successfully`
          )
        );
      });
    this.getOrderMinimum();
    this.ejDialogEditOrderMinimum.hide();
  }
  public onClickCloneOrderMinimumButton(): void {
    this.ejDialogCloneOrder.show();
  }
  public onOrderMinimumGridActionBegin(args: any): void {
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'dateCreated'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'dateCreated') {
          col.operator = 'contains';
        }
      });
    }
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'companyCode'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'companyCode') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'currencyCode'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'currencyCode') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'countryCode'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'countryCode') {
          col.operator = 'contains';
        }
      });
    }

    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'orderMinimumAmount'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'orderMinimumAmount') {
          col.operator = 'contains';
        }
      });
    }
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'handlingCharge'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'handlingCharge') {
          col.operator = 'contains';
        }
      });
    }
    if (
      args.requestType == 'filtering' &&
      args.currentFilteringColumn == 'orderYear'
    ) {
      args.columns.forEach((col: { field: string; operator: string }) => {
        if (col.field == 'orderYear') {
          col.operator = 'contains';
        }
      });
    }
  }
  public onUserStatusSwitchIsActiveToggle(event: any) {
    this.isActive = event.checked;
  }
  public getOrderMinimum() {
    this.dataService
      .getAll<OrderMinimum>(
        `OrderMinimum/GetOrderMinimum/${
          this.userService.userProfile.companyCode
        }/${new Date().getFullYear()}`
      )
      .subscribe((data) => {
        this.orderMinimumList = data;
      });
  }

  public onClickAddOrderMinimumButton() {
    this.ejDialogAddOrderMinimum.show();
  }
  public onAddOrderMinimumDialogCloselButtonClick() {
    this.ejDialogAddOrderMinimum.hide();
    this.ejDialogEditOrderMinimum.hide();
  }
  public onCloneOrderDialogCancelButtonClick() {
    this.ejDialogCloneOrder.hide();
  }
  public getOrderYear(): void {
    this.dataService
      .getAll<OrderMinimum>('OrderMinimum/GetOrderYearClone')
      .subscribe((data) => {
        this.orderYearList = data;
      });
  }
  public onReportOrderYearDropDownSelect(args: any): void {
    this.selectedReportOrderYear = args.itemData.orderYear;
  }
  public onCompanyCodeDropDownSelect(args: any): void {
    this.companycode = args.itemData.text;
  }
  public onSaveCloneOrderDataButtonClick() {
    this.dataService
      .getAll<OrderMinimum>(
        `OrderMinimum/GetOrderMinimumClone/${this.companycode}/${this.selectedReportOrderYear}`
      )
      .subscribe((data) => {
        this.orderMinimumList = data;
      });
    this.orderMinimumGrid.refresh();
    this.getOrderMinimum();
    this.ejDialogCloneOrder.hide();
  }
  public onOrderMinimumGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }

  // *********************Switch Dialog Stuff *******************
  public onChangeUserStatusSwitchClick(
    event: any,
    userSwitch: any,
    data: User
  ) {
    const checked: boolean = event.checked;
    this.user = data;

    this.userSwitch = userSwitch;
    if (checked) {
      this.dialogUserStatusWarning = 'Enable';
      this.isUserStatusDialogNoButtonClicked = false;
    } else {
      this.dialogUserStatusWarning = 'Disable';
      this.isUserStatusDialogNoButtonClicked = true;
    }
  }
}
