import { Component, Input, ViewChild } from '@angular/core';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { UserService } from 'src/app/services/user.service';
import { ReportColumnConfigurationDataType } from '../report-configuration/model/report-column-configuration-data-type.model';
import { ReportColumnConfigurationFormat } from '../report-configuration/model/report-column-configuration-format.model';
import { ReportColumnConfigurationTextAlign } from '../report-configuration/model/report-column-configuration-text-align.model';
import {
  ActionEventArgs,
  CommandModel,
  EditSettingsModel,
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
  ClickEventArgs,
  MenuEventArgs,
  MenuItemModel,
} from '@syncfusion/ej2-angular-navigations';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  @Input() showFormatGrid: boolean = true;
  @Input() showTextAlignGrid: boolean = true;
  @Input() showDataTypeGrid: boolean = true;
  @ViewChild('dataTypeSettingsGrid')
  public dataTypeSettingsGrid?: GridComponent;
  @ViewChild('formatSettingsGrid')
  public formatSettingsGrid?: GridComponent;
  @ViewChild('textAlignSettingsGrid')
  public textAlignSettingsGrid?: GridComponent;
  @ViewChild('ejDialogAddDataType')
  ejDialogAddDataType!: DialogComponent;
  @ViewChild('ejDialogEditDataType')
  ejDialogEditDataType!: DialogComponent;
  @ViewChild('ejDialogAddFormat')
  ejDialogAddFormat!: DialogComponent;
  @ViewChild('ejDialogEditFormat')
  ejDialogEditFormat!: DialogComponent;
  @ViewChild('ejDialogAddTextAlign')
  ejDialogAddTextAlign!: DialogComponent;
  @ViewChild('ejDialogEditTextAlign')
  ejDialogEditTextAlign!: DialogComponent;
  public dataTypeSettingsList?: ReportColumnConfigurationDataType[];
  public isActive: boolean = true;
  public formatSettingsList: ReportColumnConfigurationFormat[] = [];
  public textAlignSettingsList: ReportColumnConfigurationTextAlign[] = [];
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd HH:mm:ss' };
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public editSettings?: EditSettingsModel;
  public toolbarOptions?: ToolbarItems[];

  public enableRowHover: boolean = false;
  selectedRowData: any;
  editDataTypeData: any;
  editFormatData: any;
  editTextAlignData: any;

  constructor(
    private dataService: DataserviceService,
    private _cache: CacheService
  ) {}

  ngOnInit(): void {
    this.toolbarOptions = ['ColumnChooser', 'Search'];

    this.getReportConfigurationGetReportColumnConfigurationDataType();
    this.getReportConfigurationGetReportColumnConfigurationFormat();
    this.getReportConfigurationGetReportColumnConfigurationTextAlign();
  }
  public menuItems: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'Edit',
          id: 'edit',
          iconCss: 'e-icons e-edit',
        },
      ],
    },
  ];
  public onDataTypeContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'edit':
          this.editDataType(this.selectedRowData);
          break;
      }
    }
  }
  public onDataTypeGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }
  public editDataType(data: any) {
    this.editDataTypeData = data;
    this.ejDialogEditDataType.show();
  }
  public menuItemsFormat: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'Edit',
          id: 'edit',
          iconCss: 'e-icons e-edit',
        },
      ],
    },
  ];
  public onFormatContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'edit':
          this.editFormat(this.selectedRowData);
          break;
      }
    }
  }
  public onFormatGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }
  public editFormat(data: any) {
    this.editFormatData = data;
    this.ejDialogEditFormat.show();
  }

  public menuItemsTextAlign: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'Edit',
          id: 'edit',
          iconCss: 'e-icons e-edit',
        },
      ],
    },
  ];
  public onTextAlignContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'edit':
          this.editTextAlign(this.selectedRowData);
          break;
      }
    }
  }
  public onTextAlignGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }
  public editTextAlign(data: any) {
    this.editTextAlignData = data;
    this.ejDialogEditTextAlign.show();
  }
  public getReportConfigurationGetReportColumnConfigurationDataType() {
    this.dataService
      .getAll<ReportColumnConfigurationDataType>(
        `ReportConfiguration/GetReportColumnConfigurationDataType`
      )
      .subscribe((data) => {
        this.dataTypeSettingsList = data;
      });
  }
  public getReportConfigurationGetReportColumnConfigurationFormat() {
    this.dataService
      .getAll<ReportColumnConfigurationFormat>(
        `ReportConfiguration/GetReportColumnConfigurationFormat`
      )
      .subscribe((data) => {
        this.formatSettingsList = data;
      });
  }
  public getReportConfigurationGetReportColumnConfigurationTextAlign() {
    this.dataService
      .getAll<ReportColumnConfigurationTextAlign>(
        `ReportConfiguration/GetReportColumnConfigurationTextAlign`
      )
      .subscribe((data) => {
        this.textAlignSettingsList = data;
      });
  }

  public onUserStatusSwitchIsActiveToggle(event: any) {
    this.isActive = event.checked;
  }
  get typeData() {
    return this.addDataTypeForm.get('typeData');
  }
  public addDataTypeForm = new FormGroup({
    typeData: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
  });
  public onClickAddDataTypeButton() {
    this.ejDialogAddDataType.show();
  }
  public onAddDataTypeDialogCloselButtonClick() {
    this.ejDialogAddDataType.hide();
  }
  public onSaveAddDataTypeButtonClick() {
    let typeData = this.addDataTypeForm.get('typeData')?.value?.toString();
    const dataType = new ReportColumnConfigurationDataType(
      new Date(),
      typeData,
      this.isActive,
      new Date(),
      '',
      new Date()
    );

    this.dataService
      .add<any>(
        `ReportConfiguration/AddReportColumnConfigurationDataType/${typeData}`,
        dataType
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Create Data Type',
            `Data Type created successfully`
          )
        );
      });
    this.ejDialogAddDataType.hide();
  }
  public onSaveEditDataTypeButtonClick() {
    let typeData = this.addDataTypeForm.get('typeData')?.value?.toString();
    if (typeData == '') {
      typeData = this.editDataTypeData.typeData;
    }
    let isActive = Boolean(this.editDataTypeData.isActive);
    if (isActive !== this.isActive) {
      isActive = this.isActive;
    }
    const dataType = new ReportColumnConfigurationDataType(
      new Date(),
      typeData,
      isActive,
      new Date(),
      '',
      new Date()
    );
    this.dataService
      .update<any>(
        `ReportConfiguration/UpdateReportColumnConfigurationDataType/${this.editDataTypeData.id}/${typeData}/${isActive}`,
        dataType
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Update Data Type',
            `Data Type Updated successfully`
          )
        );
      });
    this.dataTypeSettingsGrid?.refresh();
    this.ejDialogEditDataType.hide();
  }

  get format() {
    return this.addFormatForm.get('format');
  }
  public addFormatForm = new FormGroup({
    format: new FormControl('', [Validators.required, Validators.minLength(2)]),
  });
  public onClickAddFormatButton() {
    this.ejDialogAddFormat.show();
  }
  public onAddFormatDialogCloselButtonClick() {
    this.ejDialogAddFormat.hide();
  }
  public onSaveAddFormatButtonClick() {
    let format = this.addFormatForm.get('format')?.value?.toString();
    const Format = new ReportColumnConfigurationFormat(
      0,
      new Date(),
      format,
      this.isActive,
      new Date(),
      '',
      new Date()
    );

    this.dataService
      .add<any>(
        `ReportConfiguration/AddReportColumnConfigurationFormat/${format}`,
        Format
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Create Data Type',
            `Data Type created successfully`
          )
        );
      });
    this.ejDialogAddFormat.hide();
  }
  public onSaveEditFormatButtonClick() {
    let format = this.addFormatForm.get('format')?.value?.toString();
    if (format == '') {
      format = this.editFormatData.format;
    }
    let isActive = Boolean(this.editFormatData.isActive);
    if (isActive !== this.isActive) {
      isActive = this.isActive;
    }
    const Format = new ReportColumnConfigurationFormat(
      0,
      new Date(),
      format,
      isActive,
      new Date(),
      '',
      new Date()
    );
    this.dataService
      .update<any>(
        `ReportConfiguration/UpdateReportColumnConfigurationFormat/${this.editFormatData.id}/${format}/${isActive}`,
        Format
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Update Format',
            `Format Updated successfully`
          )
        );
      });
    this.formatSettingsGrid?.refresh();
    this.ejDialogEditFormat.hide();
  }

  get textAlign() {
    return this.addTextAlignForm.get('textAlign');
  }
  public addTextAlignForm = new FormGroup({
    textAlign: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
  });
  public onClickAddTextAlignButton() {
    this.ejDialogAddTextAlign.show();
  }
  public onAddTextAlignDialogCloselButtonClick() {
    this.ejDialogAddTextAlign.hide();
  }
  public onSaveAddTextAlignButtonClick() {
    let textAlign = this.addTextAlignForm.get('textAlign')?.value?.toString();
    const TextAlign = new ReportColumnConfigurationTextAlign(
      0,
      new Date(),
      textAlign,
      this.isActive,
      new Date(),
      '',
      new Date()
    );

    this.dataService
      .add<any>(
        `ReportConfiguration/AddReportColumnConfigurationTextAlign/${textAlign}`,
        TextAlign
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Create Data Type',
            `Data Type created successfully`
          )
        );
      });
    this.ejDialogAddTextAlign.hide();
  }
  public onSaveEditTextAlignButtonClick() {
    let textAlign = this.addTextAlignForm.get('textAlign')?.value?.toString();
    if (textAlign == '') {
      textAlign = this.editTextAlignData.textAlign;
    }
    let isActive = Boolean(this.editTextAlignData.isActive);
    if (isActive !== this.isActive) {
      isActive = this.isActive;
    }
    const TextAlign = new ReportColumnConfigurationTextAlign(
      0,
      new Date(),
      textAlign,
      isActive,
      new Date(),
      '',
      new Date()
    );

    this.dataService
      .update<any>(
        `ReportConfiguration/UpdateReportColumnConfigurationTextAlign/${this.editTextAlignData.id}/${textAlign}/${isActive}`,
        TextAlign
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Update TextAlign',
            `TextAlign Updated successfully`
          )
        );
      });
    this.textAlignSettingsGrid?.refresh();
    this.ejDialogEditTextAlign.hide();
  }

  // *********************Switch Dialog Stuff *******************
  public onChangeUserStatusSwitchClick(event: any, userSwitch: any) {
    const checked: boolean = event.checked;
    if (checked) {
      return true;
    } else {
      return false;
    }
  }
}
