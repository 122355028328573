<div style="display: flex; justify-content: space-between; align-items: center">
  <h2 style="order: 1">Portal Management</h2>
  <div style="order: 2">
    <ejs-dropdownlist
      id="cdi-portal-activity-dropdownlist"
      [dataSource]="activity"
      [placeholder]="'Select Activity'"
      (select)="onSelectActivity($event)"
      allowFiltering="true"
      showClearButton="true"
      [ignoreAccent]="true"
      [ignoreCase]="true"
      filterType="Contains"
    ></ejs-dropdownlist>
  </div>
</div>
<div
  class="e-card"
  *ngIf="
    !showEmailDelivery &&
    !showEventLog &&
    !showOrderMinimum &&
    !showOrderModificationSettings &&
    !showReportConfiguration &&
    !showServerMetrics
  "
>
  <div
    class="e-card-title"
    style="
      height: 30px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    "
  >
    GeoLocation
  </div>
  <div class="e-card-separator"></div>
  <div class="e-card-content">
    <ejs-maps id="rn-container" theme="FabricDark">
      <e-layers>
        <e-layer
          [shapeData]="shapeData"
          [markerSettings]="markerSettings"
          [shapeSettings]="shapeSettings"
          [tooltipSettings]="tooltipSettings"
        ></e-layer>
      </e-layers>
    </ejs-maps>
  </div>
</div>
<div
  *ngIf="showServerMetrics"
  style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 20px"
>
  <div class="e-card" style="order: 1; flex: 33">
    <div
      class="e-card-title"
      style="
        height: 30px;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      "
    >
      <div
        style="
          order: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        "
      >
        <div style="order: 1">{{ title1 }}</div>
      </div>

      <div
        style="
          order: 2;
          display: inline-block;
          display: flex;
          flex-direction: row;
        "
      >
        <div class="dropdown">
          <ejs-dropdownlist
            id="cdi-failed-request-time-range-dropdownlist"
            [dataSource]="timeRanges"
            [placeholder]="'Select Time Range'"
            (select)="onSelectFailedRequestTimeRange($event)"
          ></ejs-dropdownlist>
        </div>
      </div>
    </div>
    <div class="e-card-separator"></div>
    <div class="e-card-content">
      <ejs-chart id="chart-container1">
        <e-series-collection>
          <e-series
            [dataSource]="failedRequestChart"
            type="Line"
            xName="x"
            yName="y"
          ></e-series>
        </e-series-collection>
      </ejs-chart>
    </div>
  </div>
  <div class="e-card" style="order: 2; flex: 33">
    <div
      class="e-card-title"
      style="
        height: 30px;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      "
    >
      <div
        style="
          order: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        "
      >
        <div style="order: 1">{{ title2 }}</div>
      </div>

      <div
        style="
          order: 2;
          display: inline-block;
          display: flex;
          flex-direction: row;
        "
      >
        <div class="dropdown">
          <ejs-dropdownlist
            id="cdi-server-response-time-range-dropdownlist"
            [dataSource]="timeRanges"
            [placeholder]="'Select Time Range'"
            (select)="onSelectServerResponseTimeRange($event)"
          ></ejs-dropdownlist>
        </div>
      </div>
    </div>
    <div class="e-card-separator"></div>
    <div class="e-card-content">
      <ejs-chart id="chart-container2">
        <e-series-collection>
          <e-series
            [dataSource]="serverResponseChart"
            type="Line"
            xName="x"
            yName="y"
          ></e-series>
        </e-series-collection>
      </ejs-chart>
    </div>
  </div>
  <div class="e-card" style="order: 3; flex: 33">
    <div
      class="e-card-title"
      style="
        height: 30px;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      "
    >
      <div
        style="
          order: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        "
      >
        <div style="order: 1">{{ title3 }}</div>
      </div>

      <div
        style="
          order: 2;
          display: inline-block;
          display: flex;
          flex-direction: row;
        "
      >
        <div class="dropdown">
          <ejs-dropdownlist
            id="cdi-availabilty-time-range-dropdownlist"
            [dataSource]="timeRanges"
            [placeholder]="'Select Time Range'"
            (select)="onSelectAvailabilityTimeRange($event)"
          ></ejs-dropdownlist>
        </div>
      </div>
    </div>

    <div class="e-card-separator"></div>
    <div class="e-card-content">
      <ejs-chart id="chart-container3">
        <e-series-collection>
          <e-series
            [dataSource]="availabilityChart"
            type="Line"
            xName="x"
            yName="y"
          ></e-series>
        </e-series-collection>
      </ejs-chart>
    </div>
  </div>
</div>
<app-api-log *ngIf="showEventLog"></app-api-log>
<app-order-minimum *ngIf="showOrderMinimum"></app-order-minimum>
<app-order-modification-settings
  *ngIf="showOrderModificationSettings"
></app-order-modification-settings>
<app-report-configuration
  *ngIf="showReportConfiguration"
></app-report-configuration>
<app-email-delivery-log *ngIf="showEmailDelivery"></app-email-delivery-log>
