export class ReportColumnConfigurationTextAlign {
  public id?: number;
  public dateCreated?: Date;
  public textAlign?: string;
  public isActive?: boolean;
  public dateModified?: Date;
  public createdOrModifiedBy?: string;
  public rowver?: Date;

  constructor(
    id?: number,
    dateCreated?: Date,
    textAlign?: string,
    isActive?: boolean,
    dateModified?: Date,
    createdOrModifiedBy?: string,
    rowver?: Date
  ) {
    this.id = id;
    this.dateCreated = dateCreated;
    this.textAlign = textAlign;
    this.isActive = isActive;
    this.dateModified = dateModified;
    this.createdOrModifiedBy = createdOrModifiedBy;
    this.rowver = rowver;
  }
}
