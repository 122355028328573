<!-- Edit Order Modification Dialog -->
<ejs-dialog
  id="cdi-edit-order-modification-ejDialog"
  #ejDialogEditOrderModification
  [showCloseIcon]="true"
  header="Edit Order Modification"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onEditOrderModificationDialogCloselButtonClick()"
>
  <form [formGroup]="editOrderModificationForm">
    <div>
      <label for="numberOfDaysPriorToPickDate"
        >No. of days prior to pickup date:
      </label>
      <input
        id="numberOfDaysPriorToPickDate"
        class="e-input"
        type="text"
        placeholder="Enter numberOfDaysPriorToPickDate"
        formControlName="numberOfDaysPriorToPickDate"
        [value]="editOrderModificationData?.numberOfDaysPriorToPickDate"
      />
      <div
        *ngIf="
          numberOfDaysPriorToPickDate?.invalid &&
          (numberOfDaysPriorToPickDate?.dirty ||
            numberOfDaysPriorToPickDate?.touched)
        "
        class="form-error"
      >
        <div *ngIf="numberOfDaysPriorToPickDate?.errors?.['required']">
          * No. of days prior to pickup date is required.
        </div>
      </div>
    </div>
    <br /><br />
    <button
      id="cdi-edit-order-modification-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveEditOrderMinimumButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-edit-order-modification-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onEditOrderModificationDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>

<div
  class="e-card-title"
  style="
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    line-height: 12px;
    padding: 8px;
  "
>
  Order Modification Settings
</div>
<div class="e-card-separator"></div>
<div class="e-card-content">
  <div style="height: 400px; width: 100%">
    <ejs-grid
      id="cdi-order-modification-settings-grid"
      #orderModificationSettingsGrid
      [dataSource]="orderModificationSettingsList"
      height="100%"
      width="100%"
      enableStickyHeader="true"
      [allowResizing]="true"
      gridLines="Default"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowPaging]="false"
      allowTextWrap="true"
      [filterSettings]="filterOption"
      [loadingIndicator]="loadingIndicator"
      [allowPdfExport]="true"
      [allowExcelExport]="true"
      [allowResizing]="true"
      [toolbar]="toolbarOptions"
      [showColumnChooser]="true"
      [enableHover]="enableRowHover"
      (rowSelected)="onOrderModificationGridRowSelected($event.data)"
    >
      <e-columns>
        <e-column
          field="id"
          headerText="Id"
          textAlign="Center"
          width="100"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
        <e-column
          field="dateCreated"
          headerText="Date Created"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
          type="date"
          [format]="dateFormatOption"
          [allowFiltering]="false"
        ></e-column>
        <e-column
          field="companyCode"
          headerText="Company Code"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="numberOfDaysPriorToPickDate"
          headerText="Number Of Days Prior To PickDate"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="dateModified"
          headerText="Date Modified"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
        <e-column
          field="createdOrModifiedBy"
          headerText="Created Or Modified By"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
        <e-column headerText="Action" maxWidth="100">
          <ng-template #template let-data
            ><ejs-menu
              target="#target"
              [items]="menuItems"
              showItemOnClick="true"
              (select)="onOrderModificationContextMenuSelect($event)"
            ></ejs-menu> </ng-template
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
