import { Component, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { RepairOrderRequest } from './model/repair-order-request.model';
import {
  SearchSettingsModel,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-salesforce-to-jde',
  templateUrl: './salesforce-to-jde.component.html',
  styleUrls: ['./salesforce-to-jde.component.scss'],
})
export class SalesforceToJdeComponent implements OnInit {
  public repairOrderRequests: RepairOrderRequest[];
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public toolbarUserActivityGrid?: ToolbarItems[];

  constructor(
    private dataService: DataserviceService,
    private cache: CacheService
  ) {
    this.repairOrderRequests = [];
  }

  ngOnInit(): void {
    this.toolbarUserActivityGrid = ['Search'];
    this.getFailedOrders();
  }

  public searchOptions: SearchSettingsModel = {
    operator: 'contains',
    ignoreCase: true,
    ignoreAccent: true,
  };

  public getFailedOrders(): void {
    this.dataService
      .getAll<RepairOrderRequest>('Salesforce/GetFailedRepairOrderRequests')
      .subscribe((data) => {
        this.repairOrderRequests = data;
      });
  }

  public onClickRetryButton(event: any): void {
    this.dataService
      .update<string>(`Salesforce/SetRetryRepairOrder/${event.sfLineId}`)
      .subscribe((data) => {
        if (data == '1') {
          this.cache.showDialog.next(
            new NotificationMessage(
              DialogType.info,
              'Successfully Retried',
              `Order ${event.sfLineId} was successfully retried.`
            )
          );

          this.getFailedOrders();
        } else {
          this.cache.showDialog.next(
            new NotificationMessage(
              DialogType.error,
              'Error',
              `Order ${event.sfLineId} cannot be retried.`
            )
          );
        }
      });
  }
}
