import { DialogType } from './dialog-type.enum';

export class NotificationMessage {
  public dialogType?: DialogType;
  public title?: string;
  public message?: string;

  constructor(dialogType?: DialogType, title?: string, message?: string) {
    this.dialogType = dialogType;
    this.title = title;
    this.message = message;
  }
}
