import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { UserService } from 'src/app/services/user.service';
import { enableRipple } from '@syncfusion/ej2-base';
import { Router } from '@angular/router';
import appVersion from '../../../package.json';
import { MenuItemModel } from '@syncfusion/ej2-angular-navigations';
enableRipple(true);

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public version: string = appVersion.version;
  showPortalDropdown: boolean = false;
  public profileMenu: ItemModel[] = [
    {
      text: 'Logout',
    },
    {
      text: `Version: ${this.version}`,
    },
  ];

  constructor(public _router: Router, public userService: UserService) {}

  ngOnInit(): void {}
  public menuItems: MenuItemModel[] = [
    {
      text: 'User Management',
      url: 'user-management',
    },
    {
      text: 'Portal Management',
      url: 'portal-management',
    },
    {
      text: 'Order Form Layout',
      url: 'order-form-layout',
    },
    {
      text: 'Shopify',
      url: 'shopify',
    },
    {
      text: 'IPlanner',
      url: 'iplanner',
    },
    {
      text: 'Eagle Creek',
      url: 'eagle-creek',
    },
  ];
  togglePortalDropdown() {
    this.showPortalDropdown = !this.showPortalDropdown;
  }
  public onLogoutButtonClick(args: MenuEventArgs) {
    const selectedOption = args.item.text?.toString()?.toLowerCase();
    if (selectedOption == 'logout') {
      this.userService.logout();
    }
  }
}
