<h4 style="text-align: center">Main Menu</h4>
<ejs-listview
  id="cdi-main-menu-list-view"
  [dataSource]="data"
  (select)="onItemSelected($event)"
>
  <ng-template #template let-data="" style="line-height: 30px">
    <div
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <div
        style="order: 1"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div style="order: 1"></div>
        <div style="order: 2">
          <p>{{ data.text }}</p>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-listview>
