import { Component, OnInit } from '@angular/core';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss'],
})
export class AppSpinnerComponent implements OnInit {
  constructor(public cache: CacheService) {
    this.cache.showSpinner.subscribe();
  }

  ngOnInit(): void {}
}
