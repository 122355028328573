<div id="toast_target">
  <ejs-toast
    #toastElement
    [position]="position"
    newestOnTop="true"
    enablePersistence="false"
    showCloseButton="true"
    target="#toast_target"
    [buttons]="buttons"
    [timeOut]="timeOut"
    (beforeOpen)="onBeforeOpen($event)"
  >
    <ng-template #title>
      <div>
        <span
          *ngIf="icon == 'e-icons circle-remove'"
          class="e-icons circle-remove"
        ></span>
        <span
          *ngIf="icon == 'e-icons cirle-info'"
          class="e-icons cirle-info"
        ></span
        ><span *ngIf="icon == 'e-icons check'" class="e-icons check"></span
        ><span *ngIf="icon == 'e-icons warning'" class="e-icons warning"></span>
        {{ dialogTitle }}
      </div>
    </ng-template>
    <ng-template #content>
      <div>{{ message }}</div>
    </ng-template>
  </ejs-toast>
</div>
