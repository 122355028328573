import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { Observable, finalize, tap } from 'rxjs';
import { CacheService } from '../services/cache.service';
@Injectable()
export class ShowSpinnerInterceptor implements HttpInterceptor {
  constructor(private _cache: CacheService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._cache.showSpinner.next(true);
    return next.handle(req).pipe(
      tap({
        next: () => {},
        error: (_error) => {},
      }),
      finalize(() => {
        this._cache.showSpinner.next(false);
      })
    );
  }
}
