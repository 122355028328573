import { Component, ViewChild } from '@angular/core';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { distinctUntilChanged } from 'rxjs';
import { CacheService } from 'src/app/services/cache.service';
import { DialogType } from './model/dialog-type.enum';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @ViewChild('toastElement') toastElement!: ToastComponent;

  public dialogTitle?: string;
  public message?: string;
  public position = { X: 'Center', Y: 'Top' };
  public buttons = [
    {
      model: { content: 'Close' },
      click: this.toastClose.bind(this),
    },
  ];
  public timeOut: number = 5000;
  public icon: string = '';

  constructor(private _cache: CacheService) {
    this._cache.showDialog.pipe(distinctUntilChanged()).subscribe((dialog) => {
      this.dialogTitle = dialog.title;
      this.message = dialog.message;
      this.showMessage(dialog.dialogType!);
    });
  }

  public onBeforeOpen(e: any) {
    if (this._cache.maxCount == this.toastElement.element.childElementCount) {
      e.cancel = true;
    } else {
      e.cancel = false;
    }
  }

  private toastClose(): void {
    this.toastElement?.hide('All');
  }

  private showMessage(dialogType: DialogType) {
    switch (dialogType) {
      case DialogType.error:
        this.toastElement.timeOut = 10000;
        this.icon = 'e-icons circle-remove';
        break;
      case DialogType.info:
        this.icon = 'e-icons cirle-info';
        break;
      case DialogType.success:
        this.icon = 'e-icons check';
        break;
      case DialogType.warning:
        this.icon = 'e-icons warning';
        break;

      default:
        break;
    }
    this.toastElement?.show();
  }
}
