// sidebar.component.ts
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Output() selectedItem = new EventEmitter<number>();

  public data: Object = [
    { text: 'Salesforce to JDE', id: 1 },
    { text: 'Salesforce option 2', id: 2 },
    { text: 'Salesforce option 3', id: 3 },
    { text: 'Salesforce option 4', id: 4 },
    { text: 'Salesforce option 5', id: 5 },
    { text: 'Salesforce option 6', id: 6 },
  ];

  public onItemSelected(event: any): void {
    const selectedItem = event.data.id;
    this.selectedItem.emit(selectedItem);
  }
}
