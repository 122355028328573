import { Component } from '@angular/core';

@Component({
  selector: 'app-iplanner',
  templateUrl: './iplanner.component.html',
  styleUrls: ['./iplanner.component.scss']
})
export class IPlannerComponent {

}
