<div class="cdi-flex-container" *ngIf="userService.isAuthenticated">
  <app-spinner></app-spinner>
  <app-notification></app-notification>
  <div style="order: 1">
    <header class="cdi-flex-header"><app-header></app-header></header>
  </div>

  <div style="order: 2; flex: 1; min-height: calc(100vh - 250px)">
    <router-outlet></router-outlet>
  </div>

  <div style="order: 3">
    <footer class="cdi-flex-footer">
      <app-footer></app-footer>
    </footer>
  </div>
</div>
