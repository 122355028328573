<div style="margin-top: 5px; margin-bottom: 5px; height: 95%">
  <main class="cdi-flex-main-content">
    <app-sidebar
      style="
        order: 1;
        flex: 15;
        border: 1px solid #616161;
        border-radius: 5px;
        overflow-x: auto;
      "
      (selectedItem)="onSalesforceItemSelected($event)"
    ></app-sidebar>
    <div
      style="order: 2; flex: 85; overflow: auto; margin-left: 5px"
      id="sales-force-content"
    >
      <app-salesforce-to-jde
        *ngIf="selectedItemValue == 1"
      ></app-salesforce-to-jde>
    </div>
  </main>
</div>
