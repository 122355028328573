import { AppMetadata } from '../../shared/model/app-metadata.model';

export class CreateUser {
  public name?: string; // CompanyName
  public username?: string;
  public given_name?: string; //First Name
  public family_name?: string; // Last Name
  public email?: string;
  public password?: string;
  public app_metadata?: AppMetadata;
  public readonly blocked: boolean = false;
  public readonly email_verified?: boolean = true;
  public readonly connection: string = 'Username-Password-Authentication';

  constructor(
    name?: string,
    username?: string,
    given_name?: string,
    family_name?: string,
    email?: string,
    password?: string,
    app_metadata?: AppMetadata
  ) {
    this.name = name;
    this.username = username;
    this.given_name = given_name;
    this.family_name = family_name;
    this.email = email;
    this.password = password;
    this.app_metadata = app_metadata;
  }
}
