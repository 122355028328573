export class AppMetadata {
  public commissionCode?: number;
  public companyCode?: string;
  public branchPlant?: string;
  public sbuCode?: number;
  public accountNumber?: number;
  constructor(
    commissionCode?: number,
    companyCode?: string,
    branchPlant?: string,
    sbuCode?: number,
    accountNumber?: number
  ) {
    this.commissionCode = commissionCode;
    this.companyCode = companyCode;
    this.branchPlant = branchPlant;
    this.sbuCode = sbuCode;
    this.accountNumber = accountNumber;
  }
}
