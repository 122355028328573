export class DtoChildObject {
  public code: string;
  public order: number;
  public description: string;
  public childType?: string;
  public parentCode?: string;
  public isBrand?: boolean;
  public unitOfMeasure?: string;
  public childObject?: DtoChildObject[];

  constructor(
    code: string,
    order: number,
    description: string,
    childType?: string,
    parentCode?: string,
    isBrand?: boolean,
    unitOfMeasure?: string,
    childObject?: DtoChildObject[]
  ) {
    this.code = code;
    this.order = order;
    this.description = description;
    this.childType = childType;
    this.isBrand = isBrand;
    this.parentCode = parentCode;
    this.unitOfMeasure = unitOfMeasure;
    this.childObject = childObject;
  }
}
