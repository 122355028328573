import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// ***********************COMPONENTS STARTS**********************
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AppSpinnerComponent } from './shared/app-spinner/app-spinner.component';
import { NotificationComponent } from './shared/notification/notification.component';

// ******************syncfusion STARTS*****************************
import {
  ButtonModule,
  SwitchModule,
  RadioButtonModule,
  CheckBoxModule,
} from '@syncfusion/ej2-angular-buttons';
import {
  GridModule,
  GroupService,
  ResizeService,
  SortService,
  FilterService,
  ColumnChooserService,
  ExcelExportService,
  PdfExportService,
  SearchService,
  EditService,
  ToolbarService,
  CommandColumnService,
  DetailRowService,
} from '@syncfusion/ej2-angular-grids';
import {
  ListBoxModule,
  DropDownListModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';
import {
  ChartModule,
  CategoryService,
  LineSeriesService,
  StepLineSeriesService,
  SplineSeriesService,
  StackingLineSeriesService,
  DateTimeService,
  SplineAreaSeriesService,
  MultiColoredLineSeriesService,
  ParetoSeriesService,
  ColumnSeriesService,
  AccumulationChartModule,
  PieSeriesService,
  AccumulationLegendService,
  AccumulationTooltipService,
  AccumulationAnnotationService,
  AccumulationDataLabelService,
} from '@syncfusion/ej2-angular-charts';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import {
  FileManagerModule,
  NavigationPaneService,
  DetailsViewService,
} from '@syncfusion/ej2-angular-filemanager';
import {
  DatePickerModule,
  DateRangePickerModule,
} from '@syncfusion/ej2-angular-calendars';
import {
  MenuModule,
  ContextMenuModule,
  TabAllModule,
  AccordionModule,
  SidebarModule,
  TreeViewModule,
} from '@syncfusion/ej2-angular-navigations';
import { TreeGridModule, RowDDService } from '@syncfusion/ej2-angular-treegrid';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';

import {
  MapsModule,
  MarkerService,
  MapsTooltipService,
} from '@syncfusion/ej2-angular-maps';
import {
  DashboardLayoutModule,
  SplitterModule,
} from '@syncfusion/ej2-angular-layouts';
import { DiagramModule } from '@syncfusion/ej2-angular-diagrams';
// ******************Other Packages STARTS*****************************
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { GlobalErrorHandler } from './services/global-error-handler';
import { DashboardComponent } from './content/dashboard/dashboard.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { environment } from 'src/environments/environment';
import { HelpCenterComponent } from './content/help-center/help-center.component';
import { ShowSpinnerInterceptor } from './http-interceptors/show-spinner-interceptor';
import { AddUserComponent } from './content/add-user/add-user.component';
import { UserManagementComponent } from './content/user-management/user-management.component';
import { OrderMinimumComponent } from './content/order-minimum/order-minimum.component';
import { EmailDeliveryLogComponent } from './content/email-delivery-log/email-delivery-log.component';
import { ReportConfigurationComponent } from './content/report-configuration/report-configuration.component';
import { ApiLogComponent } from './content/api-log/api-log.component';
import { SalesForceComponent } from './content/sales-force/sales-force.component';
import { SidebarComponent } from './content/sidebar/sidebar.component';
import { OrderModificationSettingsComponent } from './content/order-modification-settings/order-modification-settings.component';
import { SettingsComponent } from './content/settings/settings.component';
import { IPlannerComponent } from './content/iplanner/iplanner.component';
import { PortalManagementComponent } from './content/portal-management/portal-management.component';
import { OrderFormLayoutComponent } from './content/order-form-layout/order-form-layout.component';
import { SalesforceToJdeComponent } from './content/sales-force/salesforce-to-jde/salesforce-to-jde.component';
import { EagleCreekComponent } from './content/eagle-creek/eagle-creek.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    AppSpinnerComponent,
    DashboardComponent,
    PageNotFoundComponent,
    HelpCenterComponent,
    NotificationComponent,
    AddUserComponent,
    UserManagementComponent,
    OrderMinimumComponent,
    EmailDeliveryLogComponent,
    ApiLogComponent,
    SalesForceComponent,
    SidebarComponent,
    OrderModificationSettingsComponent,
    ReportConfigurationComponent,
    SettingsComponent,
    IPlannerComponent,
    PortalManagementComponent,
    OrderFormLayoutComponent,
    SalesforceToJdeComponent,
    EagleCreekComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ButtonModule,
    SwitchModule,
    ListBoxModule,
    TreeGridModule,
    DatePickerModule,
    TabAllModule,
    RadioButtonModule,
    ListViewModule,
    GridModule,
    ChartModule,
    TextBoxModule,
    DropDownListModule,
    MultiSelectModule,
    DialogModule,
    CheckBoxModule,
    UploaderModule,
    FileManagerModule,
    DropDownButtonModule,
    ReactiveFormsModule,
    DateRangePickerModule,
    AccumulationChartModule,
    DashboardLayoutModule,
    MenuModule,
    TreeViewModule,
    ContextMenuModule,
    SidebarModule,
    AccordionModule,
    TooltipModule,
    ToastModule,
    MapsModule,
    SplitterModule,
    DiagramModule,
    AuthModule.forRoot({
      domain: environment.authDomain,
      clientId: environment.authClientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.authAudience,
        scope: environment.authScope,
      },
      httpInterceptor: {
        allowedList: [{ uri: `${environment.apiUrl}/*` }],
      },
    }),
  ],
  providers: [
    MarkerService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ShowSpinnerInterceptor,
      multi: true,
    },
    ResizeService,
    NavigationPaneService,
    DetailsViewService,
    GroupService,
    EditService,
    ColumnChooserService,
    SortService,
    FilterService,
    ToolbarService,
    ExcelExportService,
    PdfExportService,
    CategoryService,
    LineSeriesService,
    StepLineSeriesService,
    SplineSeriesService,
    StackingLineSeriesService,
    DateTimeService,
    SplineAreaSeriesService,
    MultiColoredLineSeriesService,
    ParetoSeriesService,
    ColumnSeriesService,
    SearchService,
    PieSeriesService,
    AccumulationLegendService,
    AccumulationTooltipService,
    AccumulationDataLabelService,
    AccumulationAnnotationService,
    RowDDService,
    CommandColumnService,
    DetailRowService,
    MapsTooltipService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
