import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
  ContextMenuComponent,
  MenuEventArgs,
  MenuItemModel,
} from '@syncfusion/ej2-angular-navigations';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { Router } from '@angular/router';
import { EmailDeliveryLog } from './model/email-delivery-log.model';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmailDeliveryLogDetails } from './model/email-delivery-log-details.model';
import { UserService } from 'src/app/services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ConnectorConstraints,
  ConnectorModel,
  DiagramComponent,
  NodeModel,
  ShapeAnnotationModel,
} from '@syncfusion/ej2-angular-diagrams';
import { SalesRep } from './model/sales-rep.model';
import { CdiDealers } from './model/cdi-dealers.model';

@Component({
  selector: 'app-email-delivery-log',
  templateUrl: './email-delivery-log.component.html',
  styleUrls: ['./email-delivery-log.component.scss'],
})
export class EmailDeliveryLogComponent {
  @ViewChild('dialogEmailDeliveryLogDetails')
  dialogEmailDeliveryLogDetails!: DialogComponent;
  @ViewChild('dialogEmailAddress')
  dialogEmailAddress!: DialogComponent;
  @ViewChild('ejDialogAddAdditionalRecipient')
  ejDialogAddAdditionalRecipient!: DialogComponent;
  public emailDeliveryLogList: EmailDeliveryLog[] = [];
  public selectedRowData: EmailDeliveryLog[] = [];
  public emailDeliveryLogDetails: EmailDeliveryLogDetails[] = [];
  public emailAddressList: { text: string }[] = [];
  @ViewChild('emailDeliveryLogGrid')
  public emailDeliveryLogGrid?: GridComponent;
  @ViewChild('contextMenu') public contextMenu!: ContextMenuComponent;
  @ViewChild('diagram')
  public diagram?: DiagramComponent;

  public userSwitch: any;
  public salesRepList: SalesRep[] = [];
  public dealerList: CdiDealers[] = [];
  public dealerSelect: string = '';
  public salesRepSelect: string = '';
  public dropdownlistDealerSearchFields: Object = {
    text: 'companyName',
    value: 'companyName',
  };
  public dropdownlistSalesRepSearchFields: Object = {
    text: 'salesAgency',
    value: 'salesAgency',
  };
  // Declared  Variables
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };
  public toolbarEmailDeliveryLogGrid?: ToolbarItems[];
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd HH:mm:ss' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public enableRowHover: boolean = false;
  public enableConnectorSplit: boolean = true;
  public constraints: ConnectorConstraints =
    ConnectorConstraints.Default | ConnectorConstraints.AllowDrop;
  dealerAnnotation: ShapeAnnotationModel = {
    content: this.dealerSelect,
  };

  public nodes: NodeModel[] = [
    {
      id: this.dealerSelect,
      offsetX: 150,
      offsetY: 150,
      width: 100,
      height: 100,
      annotations: [{ content: this.dealerSelect }],
    },
    {
      id: 'node2',
      offsetX: 650,
      offsetY: 150,
      width: 100,
      height: 100,
      annotations: [{ content: this.salesRepSelect }],
    },
  ];
  public connectors: ConnectorModel[] = [
    {
      id: 'connector1',
      sourceID: 'node1',
      targetID: 'node2',
      constraints: this.constraints,
    },
  ];
  constructor(
    private dataService: DataserviceService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.getEmailDeliveryLog();
    this.toolbarEmailDeliveryLogGrid = ['ColumnChooser', 'Search'];
    this.getCascadeUsers();
  }
  get dealer() {
    return this.addAdditionalRecipientForm.get('dealer');
  }
  get salesRep() {
    return this.addAdditionalRecipientForm.get('salesRep');
  }
  public addAdditionalRecipientForm = new FormGroup({
    dealer: new FormControl('', [Validators.required, Validators.minLength(3)]),
    salesRep: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
  });
  public onDealerDropDownSelect(event: any): void {
    this.dealerSelect = event.itemData.companyName;
    if (
      this.nodes[0] &&
      this.nodes[0].annotations &&
      this.nodes[0].annotations[0]
    ) {
      this.nodes[0].annotations[0].content = this.dealerSelect;
    }
    // After updating the node's content
    if (this.diagram) {
      this.diagram.refresh();
    }
  }

  public onSalesRepDropDownSelect(event: any): void {
    this.salesRepSelect = event.itemData.salesAgency;
    this.nodes[1].annotations = [{ content: this.salesRepSelect }];
  }
  public menuItems: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'View Details',
          id: 'viewDetails',
          iconCss: 'e-icons e-eye',
        },
        {
          text: 'Show Email',
          id: 'showEmail',
          iconCss: 'e-icons e-send-1',
        },
        {
          text: 'Download PDF',
          id: 'downloadPdf',
          iconCss: 'e-icons e-export-pdf',
        },
      ],
    },
  ];
  public onEmailDeliveryContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'viewDetails':
          this.showDetails(this.selectedRowData);
          break;
        case 'showEmail':
          this.showEmailDetails(this.selectedRowData);
          break;
        case 'downloadPdf':
          break;
        default:
      }
    }
  }

  public getEmailDeliveryLog() {
    this.dataService
      .getAll<EmailDeliveryLog>('EmailDeliveryLog/GetEmailDeliveryLog')
      .subscribe((data) => {
        this.emailDeliveryLogList = data;
      });
  }

  public onEmailDeliveryLogGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }
  public onClickClearButton(): void {}

  public showDetails(data: any): void {
    let id = data.id;
    this.dataService
      .getAll<EmailDeliveryLogDetails>(
        `EmailDeliveryLog/GetEmailDeliveryLogDetails/${id}`
      )
      .subscribe((data) => {
        this.emailDeliveryLogDetails = data;
      });
    this.dialogEmailDeliveryLogDetails.show();
  }
  public showEmailDetails(data: any): void {
    this.dialogEmailAddress.show();
    let orderNumber = data.orderNumber;
    this.dataService
      .getAll<any>(`EmailDeliveryLog/GetEmailAddress/${orderNumber}`)
      .subscribe((data) => {
        this.emailAddressList = data
          .filter((item) => item.emailAddress)
          .map((item) => ({
            text: item.emailAddress,
          }));
        console.log('showEmailDetails', this.emailAddressList);
      });
  }
  public hide(): void {
    this.dialogEmailDeliveryLogDetails.hide();
    this.dialogEmailAddress.hide();
  }
  public onClickAddAdditionalRecipientButton(): void {
    this.ejDialogAddAdditionalRecipient.show();
  }
  public onAddAdditionalRecipientDialogCloselButtonClick(): void {
    this.ejDialogAddAdditionalRecipient.hide();
  }
  public getCascadeUsers(): void {
    const companyCode = this.userService.userProfile.companyCode;
    const sbu = '100';
    //for salesrep
    this.dataService
      .getAll<SalesRep>(`EmailDeliveryLog/GetAllSalesReps/${companyCode}`)
      .subscribe((data) => {
        this.salesRepList = data;
      });
    //for cdi dealers
    this.dataService
      .getAll<CdiDealers>(
        `EmailDeliveryLog/GetAllDealers/${companyCode}/${sbu}`
      )
      .subscribe((data) => {
        this.dealerList = data;
      });
  }

  public onSaveAddAdditionalRecipientButtonClick(): void {}
}
