import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { OrderForDeletion } from './model/order-for-deletion.model';
import { OuterCreateOrder } from './model/outer-create-order.model';
import { OuterCreateProduct } from './model/outer-create-product.model';
import { CreateProduct } from './model/create-product';
import { ProductForDeletion } from './model/product-for-deletion.model';
import { CreateOrder } from './model/create-order.model';
import { Transaction } from './model/transaction.model';
import { QueuedOrder } from './model/queued-order.model';
import {
  FilterSettingsModel,
  GridComponent,
} from '@syncfusion/ej2-angular-grids';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';

@Component({
  selector: 'app-eagle-creek',
  templateUrl: './eagle-creek.component.html',
  styleUrls: ['./eagle-creek.component.scss'],
})
export class EagleCreekComponent implements OnInit {
  @ViewChild('orderGrid') public orderGrid?: GridComponent;
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };

  public status: string;
  public count: number;
  public nextOperationFlag: boolean;
  public selectedRowData: QueuedOrder;
  public ordersForDeletion: OrderForDeletion[];
  public ordersForCreation: OuterCreateOrder[];
  public productsForDeletion: ProductForDeletion[];
  public productsForCreation: OuterCreateProduct[];
  public queuedOrders: QueuedOrder[];
  public dateRange: Date[] = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    ),
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
  ];

  constructor(
    private dataService: DataserviceService,
    private cache: CacheService
  ) {
    this.status = 'None';
    this.selectedRowData = new QueuedOrder();
    this.ordersForDeletion = [];
    this.ordersForCreation = [];
    this.productsForDeletion = [];
    this.productsForCreation = [];
    this.queuedOrders = [];
    this.nextOperationFlag = true;
    this.count = 0;
  }

  ngOnInit(): void {
    this.getOrders();
  }

  public populateDevForm = new FormGroup({
    dateRangeProp: new FormControl(this.dateRange, [Validators.required]),
  });

  public getOrders(): void {
    this.dataService
      .getAll<QueuedOrder>('eaglecreek/GetOrders')
      .subscribe((data) => {
        this.queuedOrders = data;
      });
  }

  public onOrderMinimumGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }

  public onClickRetryButton(event: any): void {
    console.log(event);
    this.dataService
      .update<string>(`eaglecreek/SetRetryOrder/${event.id}`)
      .subscribe((data) => {
        if (data == '1') {
          this.cache.showDialog.next(
            new NotificationMessage(
              DialogType.info,
              'Successfully Retried',
              `Order ${event.id} was successfully retried.`
            )
          );

          this.getOrders();
        } else {
          this.cache.showDialog.next(
            new NotificationMessage(
              DialogType.error,
              'Error',
              `Order ${event.id} cannot be retried.`
            )
          );
        }
      });
  }

  public async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async onClickPopulateDev() {
    this.status = 'Working - Getting all orders from dev';
    try {
      // Get All orders from Dev
      this.dataService
        .getAll<OrderForDeletion>(
          `eaglecreek/getAllOrdersFromDev/${this.dateRange[0].toISOString()}/${this.dateRange[1].toISOString()}`
        )
        .subscribe(async (data) => {
          this.ordersForDeletion = data;
          console.log('Gets dev orders');

          // Deletes all orders in Dev
          this.status = 'Working - Removing all orders from dev';
          this.count = this.ordersForDeletion.length;
          try {
            for (const order1String in this.ordersForDeletion) {
              await this.sleep(20000);
              var index = parseInt(order1String);

              this.status = `Working - Removing all orders from dev (${
                index + 1
              } out of ${this.count}).`;
              this.dataService
                .deleteById(
                  'eaglecreek/DeleteOrderInDev',
                  this.ordersForDeletion[index].id?.toString() || ''
                )
                .subscribe((data) => {
                  console.log('Deleted dev orders');

                  // Gets all orders from production
                  if (index + 1 == this.count) {
                    this.status =
                      'Working - Getting all orders from production';
                    try {
                      this.dataService
                        .getAll<CreateOrder>(
                          `eaglecreek/GetAllOrdersFromProd/${this.dateRange[0].toISOString()}/${this.dateRange[1].toISOString()}`
                        )
                        .subscribe(async (data) => {
                          console.log('Gets prod orders');
                          // Formats orders to be created
                          data.forEach((order2: CreateOrder) => {
                            let transaction = new Transaction();
                            transaction.kind = 'sale';
                            transaction.status = 'success';
                            transaction.amount = order2.total_price;
                            order2.transactions = [];
                            order2.transactions.push(transaction);
                            this.ordersForCreation.push(
                              new OuterCreateOrder(order2)
                            );
                            console.log('order 2', order2);
                          });
                          console.log(this.ordersForCreation);

                          // Adds all orders from production to dev
                          this.status = 'Working - Creating new orders in dev';
                          this.count = this.ordersForCreation.length;
                          try {
                            index = 0;
                            for (const order2String in this.ordersForCreation) {
                              await this.sleep(20000);
                              index = parseInt(order2String);
                              this.status = `Working - Creating new orders in dev (${
                                index + 1
                              } out of ${this.count})`;
                              this.dataService
                                .add<OuterCreateOrder>(
                                  'eaglecreek/CreateOrderInDev',
                                  this.ordersForCreation[index]
                                )
                                .subscribe((data) => {
                                  console.log('creates dev orders');

                                  if (index + 1 == this.count) {
                                    this.status =
                                      'Working - Getting all products from dev';
                                    try {
                                      // Get All products from Dev
                                      this.dataService
                                        .getAll<ProductForDeletion>(
                                          `eaglecreek/getAllProductsFromDev/${this.dateRange[0].toISOString()}/${this.dateRange[1].toISOString()}`
                                        )
                                        .subscribe(async (data) => {
                                          this.productsForDeletion = data;
                                          console.log('Gets dev products');

                                          // Deletes all products in Dev
                                          this.status =
                                            'Working - Removing all products from dev';
                                          this.count =
                                            this.productsForDeletion.length;
                                          try {
                                            for (const product1String in this
                                              .productsForDeletion) {
                                              await this.sleep(20000);
                                              var index =
                                                parseInt(product1String);

                                              this.status = `Working - Removing all products from dev (${
                                                index + 1
                                              } out of ${this.count}).`;
                                              this.dataService
                                                .deleteById(
                                                  'eaglecreek/DeleteproductInDev',
                                                  this.productsForDeletion[
                                                    index
                                                  ].id?.toString() || ''
                                                )
                                                .subscribe((data) => {
                                                  console.log(
                                                    'Deleted dev products'
                                                  );

                                                  // Gets all product from production
                                                  if (index + 1 == this.count) {
                                                    this.status =
                                                      'Working - Getting all products from production';
                                                    try {
                                                      this.dataService
                                                        .getAll<CreateProduct>(
                                                          `eaglecreek/GetAllproductsFromProd/${this.dateRange[0].toISOString()}/${this.dateRange[1].toISOString()}`
                                                        )
                                                        .subscribe(
                                                          async (data) => {
                                                            console.log(
                                                              'Gets prod products'
                                                            );
                                                            // Formats products to be created
                                                            data.forEach(
                                                              (product) => {
                                                                this.productsForCreation.push(
                                                                  new OuterCreateProduct(
                                                                    product
                                                                  )
                                                                );
                                                              }
                                                            );

                                                            // Adds all products from production to dev
                                                            this.status =
                                                              'Working - Creating new products in dev';
                                                            this.count =
                                                              this.productsForCreation.length;
                                                            try {
                                                              index = 0;
                                                              for (const product2String in this
                                                                .productsForCreation) {
                                                                await this.sleep(
                                                                  20000
                                                                );
                                                                index =
                                                                  parseInt(
                                                                    product2String
                                                                  );
                                                                this.status = `Working - Creating new products in dev (${
                                                                  index + 1
                                                                } out of ${
                                                                  this.count
                                                                })`;
                                                                this.dataService
                                                                  .add<OuterCreateProduct>(
                                                                    'eaglecreek/CreateProductInDev',
                                                                    this
                                                                      .productsForCreation[
                                                                      index
                                                                    ]
                                                                  )
                                                                  .subscribe(
                                                                    (data) => {
                                                                      console.log(
                                                                        'creates dev products'
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                            } catch (ex) {
                                                              this.status =
                                                                'Error - Cannot add products in dev';
                                                              console.log(
                                                                `Error description: ${ex}`
                                                              );
                                                            }
                                                          }
                                                        );
                                                    } catch (ex) {
                                                      this.status =
                                                        'Error - Cannot get products from production';
                                                      this.nextOperationFlag =
                                                        false;
                                                      console.log(
                                                        `Error description: ${ex}`
                                                      );
                                                    }
                                                  }
                                                });
                                            }
                                          } catch (ex) {
                                            this.status =
                                              'Error - Cannot delete all products from dev';
                                            this.nextOperationFlag = false;
                                            console.log(
                                              `Error description: ${ex}`
                                            );
                                          }
                                        });
                                    } catch (ex) {
                                      this.status =
                                        'Error - Cannot get products from dev';
                                      console.log(`Error description: ${ex}`);
                                    }
                                  }
                                });
                            }
                          } catch (ex) {
                            this.status = 'Error - Cannot add orders in dev';
                            console.log(`Error description: ${ex}`);
                          }
                        });
                    } catch (ex) {
                      this.status = 'Error - Cannot get orders from production';
                      this.nextOperationFlag = false;
                      console.log(`Error description: ${ex}`);
                    }
                  }
                });
            }
          } catch (ex) {
            this.status = 'Error - Cannot delete order all orders from dev';
            this.nextOperationFlag = false;
            console.log(`Error description: ${ex}`);
          }
        });
    } catch (ex) {
      this.status = 'Error - Cannot get orders from dev';
      console.log(`Error description: ${ex}`);
    } finally {
      this.status = 'Completed!';
    }
  }
}
